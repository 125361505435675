<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useMenuStore } from '@/store'
import { storeToRefs } from 'pinia'
import ChatView from '@/components/ChatView.vue'

const menuStore = useMenuStore()
const { zoomButtonsShown } = storeToRefs(menuStore)
const { chatOpen, isMobile } = storeToRefs(menuStore)
const router = useRouter()

function openChat() {
  if (isMobile.value) {

    router.push('/chat')
  } else {
    chatOpen.value = true
  }
}

</script>
<template>
  <div v-if="zoomButtonsShown && !chatOpen">
    <img class="m-1" @click="$emit('zoomreset')" src="../assets/zoomreset.svg" />
    <img class="m-1" @click="$emit('zoomin')" src="../assets/zoomin.svg" />
    <img class="m-1" @click="$emit('zoomout')" src="../assets/zoomout.svg" />
    <!-- Chatbot temporarily disabled -->
    <img class="m-1" v-if="true" @click="openChat()" src="../assets/chat.svg" />
  </div>
  <transition name="fade">
    <div v-if="chatOpen" id="chatwindow">
      <div class="flex justify-end absolute right-4 top-4">
        <img class="m-1 h-12" @click="chatOpen = false" src="../assets/menu-close.svg" />
      </div>
      <ChatView :isInContainer='true' />
    </div>
  </transition>
</template>
<style scoped>
img {
  @apply cursor-pointer;
}

img:hover,
img:active {
  @apply opacity-50;
}

#chatwindow {
  @apply fixed bottom-4 right-4 rounded-lg p-0;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgba(235, 235, 235, 0.5);
  width: 520px;
  z-index: 100;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-to,
.fade-leave-from {
  margin-right: 0;
}

.fade-enter-from,
.fade-leave-to {
  margin-right: -100%;
}
</style>
