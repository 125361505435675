import { createApp } from 'vue'
import Form from './components/contact-form/App.vue'
import PrimeVue from 'primevue/config'
import i18n from '@/i18n'
import { createPinia } from 'pinia'

const cache: { app?: any } = {
  app: null
}

export const appendForm = (htmlId: string) => {
  const app = createApp(Form)
  app.use(PrimeVue)
  app.use(i18n)
  app.use(createPinia())
  app.mount(`#${htmlId}`)
  cache.app = app
}
export const removeForm = () => {
  cache?.app?.unmount()
}
