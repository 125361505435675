import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '@/firebase/init'

/**
 * FirestoreCache is a simple cache that stores data in firestore and in memory
 */
class FirestoreCache {
  static memoryCache: Record<string, any> = {}

  static async get(key: string): Promise<any> {
    // check if in memory
    if (FirestoreCache.memoryCache[key] !== undefined) {
      console.log('cache hit (memory)', key)
      return FirestoreCache.memoryCache[key]
    }

    // check if in firestore
    const d = await getDoc(doc(db, 'cache', key))
    if (d.exists()) {
      console.log('cache hit (firestore)', key)
      const v = JSON.parse(d!.data()!.value)
      // store in memory
      FirestoreCache.memoryCache[key] = v
      return v
    } else {
      console.log('cache miss', key)
      return undefined
    }
  }

  static async set(key: string, value: any): Promise<void> {
    // store in memory
    FirestoreCache.memoryCache[key] = value

    await setDoc(doc(db, 'cache', key), {
      value: JSON.stringify(value)
    })
  }

  /**
   * This is the "MurmurHash3" hash function
   * @param args
   */
  static generateKey(...args: any[]): string {
    console.log("team member", args)
    const str = JSON.stringify(args)
    let h = 1
    for (let i = 0; i < str.length; i++) {
      h = Math.imul((h + str.charCodeAt(i)) | 0, 2654435761)
    }
    return ((h ^ (h >>> 17)) >>> 0).toString()
  }
}

export default FirestoreCache
