<template>
  <svg height="20" width="40" class="loader">
    <circle class="dot" cx="10" cy="10" r="3" style="fill:grey;"/>
    <circle class="dot" cx="20" cy="10" r="3" style="fill:grey;"/>
    <circle class="dot" cx="30" cy="10" r="3" style="fill:grey;"/>
  </svg>
</template>
<style scoped>
@keyframes blink {
  50% {
    fill: transparent
  }
}

.dot {
  animation: 1s blink infinite;
  fill: grey;
}

.dot:nth-child(2) {
  animation-delay: 250ms
}

.dot:nth-child(3) {
  animation-delay: 500ms
}

.loader {
}
</style>
