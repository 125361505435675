import { DeviceAbstract } from '@/lib/DeviceHandler/DeviceAbstract'
import * as d3 from 'd3'
import data from '@/assets/prerendered-mobile.json'
import type { DeviceConfig, SerializableModel } from '../../../types'

export class DeviceMobile extends DeviceAbstract {
  public getConfig(): DeviceConfig {
    return {
      timelineYearsEasingFactor: 1.5,
      minZoom: 1.1,
      initialCenter: "100,200",
      offsetVerticalRelative: 0.07,
      offsetHorizontalRelative: 0.2,
      filteredCircleScaleDownFactor: 0.4,
      maxZoomLevel: 50,
      maxZoomLevelForFocusingByTimelineEventClass: {
        'person-onboarding': 25,
        project: 25,
        offering: 50,
        freefloat: 50,
        'freefloat-parent': 50,
        'team-event': 25,
        'blogarticle': 50,
        podcast: 25
      },
      maxZoomLevelFiltering: 20,
      zoomTweenRangeAdjustment: 0.5,
      peopleRenderer: {
        pictureMoveToBottom: 1.5,
        pictureMoveToRight: 1.5
      },
      safariForeignObjectFixFactor: 0.4,
      buttonScaleFactor: 2,
      zoomedOutStateZoomLevel: {
        all: 20,
        projects: 5,
        people: 6,
        offerings: 5,
        blog: 5,
        podcast: 10
      }
    }
  }

  public getPrerenderedData(): SerializableModel {
    return window.structuredClone(data) as SerializableModel
  }

  getInitialZoom(): number {
    return 1.1
  }

  getOverflowRatio(): number {
    return 4
  }

  getAspectRatioForVertexGeneration(width: number, height: number): number {
    // 4 times longer than high
    return width / height
  }

  getZoomHandlerTranslateExtent(
    width: number,
    height: number
  ): [[number, number], [number, number]] {
    return [
      [((this.getInitialZoom() - 1) * width) / 2, ((this.getInitialZoom() - 1) * height) / 2],
      [width, height * this.getOverflowRatio()]
    ]
  }

  getTransformForZoomAll(width: number, height: number): d3.ZoomTransform {
    const scale = this.getInitialZoom()

    // this positions the pan in the center of the SVG
    return d3.zoomIdentity
      .scale(scale)
      .translate(
        (width / 2) * (1 - this.getInitialZoom()),
        (height / 2) * (1 - this.getInitialZoom())
      )
  }

  getTransformForZoomOnOpen(width: number, height: number): d3.ZoomTransform {
    return this.getTransformForZoomAll(width, height)
  }
}
