import * as d3 from 'd3'
import type { Id, PersonOnboarding, Point, TimelineEventClasses, Vertex } from '../../../../types'
import {
  AbstractSvgRenderer,
  type GatheredBubble
} from '@/lib/Rendering/BubbleRenderer/AbstractSvgRenderer'
import type { Selection, BaseType } from 'd3'
import i18n from '@/i18n'
import RenderUtilities from '@/lib/Rendering/RenderUtilities'
import linkedInLogo from '@/assets/linkedin.svg'
import githubLogo from '@/assets/github.svg'

/**
 * Render the people as part of the onboarding events
 */
export class PeopleRenderer extends AbstractSvgRenderer {
  public config = {
    hasHtml: true,
    hasSubtitle: false,
    hasClassTitle: true,
    filterCircleRadius: 50,
    noArrowsOnSafari: false,
  }

  public getClassName(): TimelineEventClasses {
    return 'person-onboarding'
  }

  protected getClassTitleLabel(d: Vertex): string {
    return 'classTitleLabel.teamMembers'
  }

  public getSubTitle(d: Vertex) {
    const p = d.timelineEvent as PersonOnboarding
    return i18n.global.t('position.' + this.mycelModel.data.people[p.person as Id]?.position ?? '')
  }

  public getHTML(d: Vertex) {
    const p = d.timelineEvent as PersonOnboarding
    let html = this.mycelModel.data.people[p.person as Id]?.description ?? ''

    html += '<div class="social-media">'
    // Add social media links
    if (this.mycelModel.data.people[p.person as Id]?.urlLinkedin) {
      html += `<a href="${this.mycelModel.data.people[p.person as Id]?.urlLinkedin}" target="_blank" style='background-image: url(${linkedInLogo})' ></a>`
    }
    if (this.mycelModel.data.people[p.person as Id]?.urlGithub) {
      html += `<a href="${this.mycelModel.data.people[p.person as Id]?.urlGithub}" target="_blank" style='background-image: url(${githubLogo})' ></a>`
    }
    html += '</div>'
    return html
  }

  public getHtmlWidthFactor(d: PersonOnboarding) {
    return 0.5
  }

  /**
   * Creates the SVG elements for the people
   * @param g
   * @param isZooming is true, when this function is called during zooming. In this case we don't want to create
   *                  the SVG elements again, but just update the positions
   * @param isFiltering
   * @param useTransition
   */
  public render(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    isZooming: boolean = false,
    isFiltering: boolean = false,
    useTransition = false
  ) {
    const vertices = this.mycelModel.getVerticesByClass('person-onboarding')
    if (!isZooming) {
      this.createGroupSvg(g, vertices)
      this.createClassTitleSvg()
      this.createLineSvg()
      this.createTitleSvg()
      this.createSubtitleSvg()
      this.createHtmlSvg()
      this.createButtons()
    }

    const zoomlevel =
      (this.currentCurrentZoomLevel ?? 1) / this.deviceHandler.getConfig().zoomTweenRangeAdjustment

    let deltaX = 0
    let deltaY = 0

    // Logic to make the person image smaller than the event circle
    // const pictureMoveToRight = 0.32 * scalingDueToFiltering
    // const pictureMoveToBottom = 0.28 * scalingDueToFiltering
    const pictureMoveToRight = this.deviceHandler.getConfig().peopleRenderer.pictureMoveToRight
    const freezeLevel = isFiltering ? 3 : 10 // the lower the value, the earlier the person image will start shrinking
    const f = d3.interpolate(0, pictureMoveToRight * 400)
    const zoomCompleteAfterFactor = 3

    let alpha
    let foreignObjectVisible
    let scale = 1
    if (zoomlevel < freezeLevel) {
      // below freeze level
      alpha = 0
      foreignObjectVisible = false
    } else if (zoomlevel > freezeLevel * zoomCompleteAfterFactor) {
      // above freeze level
      alpha = 1
      foreignObjectVisible = true
      scale = (1 / (freezeLevel * zoomCompleteAfterFactor ?? 1)) * freezeLevel
    } else {
      // between
      alpha = (zoomlevel - freezeLevel) / (freezeLevel * (zoomCompleteAfterFactor - 1))
      foreignObjectVisible = alpha > 0.5 // make it visible if progress is fore than 50%
      scale = (1 / (zoomlevel ?? 1)) * freezeLevel
    }

    deltaX = f(alpha) * scale
    deltaY = f(alpha) * scale

    // <g> for the person image. Will also be updated during zooming
    if (!isZooming) {
      g.selectAll<SVGGElement | BaseType, Vertex>('svg.person-onboarding')
        .append('g')
        .attr(
          'class',
          (d, ix) => `person person-photo person-${(d.timelineEvent as PersonOnboarding)?.person}`
        )
        .on('click', (evnet: any, d) => {
          this.dispatchEvent(new CustomEvent('circleClick', { detail: { destination: d } }))
        })
    }
    g.selectAll(`g.person-photo`)
      .transition()
      .duration(useTransition ? 500 : 0)
      .attr('transform', (d) => {
        const x = deltaX
        const y = deltaY
        return `translate(${x},${y}) scale(${scale})`
      })

    // Necessary for Safari on Web which always show the foreignObject in the front
    g.selectAll('svg.person-onboarding foreignObject').classed('faded-in', foreignObjectVisible)

    // person image. Will be created only once. So we don't run this during zooming.
    if (!isZooming) {
      g.selectAll<SVGGElement | BaseType, Vertex>(`g.person-photo`)
        .append('image')
        .attr('xlink:href', (d) => {
          const te = d.timelineEvent as PersonOnboarding
          return this.mycelModel.data.people[te.person as Id].photo + '/m/filters:grayscale()'
        })
        .attr('width', 400)
        .attr('height', 400)
        .attr('clip-path', 'url(#personClip)')
    }
  }

  zoomHandler(
    g: Selection<SVGGElement, unknown, HTMLElement, undefined>,
    zoomTweenRangeAdjustment: number,
    logicZoomLevel: number,
    isFiltering: boolean
  ) {
    RenderUtilities.zoomTween(
      g.selectAll('g.event.person-onboarding'),
      'opacity',
      '0',
      '1',
      30 * zoomTweenRangeAdjustment,
      45 * zoomTweenRangeAdjustment,
      logicZoomLevel
    )
    this.render(g, true, isFiltering)
  }

  protected includeInGathering(vertex: Vertex): boolean {
    if (!(vertex.timelineEvent as PersonOnboarding).person) return true
    return (
      this.mycelModel.data.people[(vertex.timelineEvent! as PersonOnboarding).person as Id]
        ?.isActive ?? true
    )
  }

  protected updateElementOnFiltering(
    g: Selection<SVGGElement, unknown, HTMLElement, undefined>,
    targetPosition: {
      id: string
      x: number
      y: number
    },
    optimizationScale: number
  ) {
    g?.selectAll(`.person-${targetPosition.id}.person-photo`).raise()
  }

  protected updateElementsOnFiltering(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    nodeData: GatheredBubble[],
    optimizationScale: number
  ) {
    this.legendShow(g, nodeData, optimizationScale)
    //g?.selectAll(`.event-group.person-onboarding`).attr('opacity', 0)
  }

  public resetFilter(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    super.resetFilter(g)
    this.render(g, true, false, true)
    this.legendHide(g)
  }

  protected filteredCircleRadiusScale(d: Vertex): number {
    const isManagement =
      this.mycelModel.data.people[(d.timelineEvent as PersonOnboarding).person as Id]?.isManagement
    return isManagement ? 1.1 : 1
  }

  private legendShow(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    nodeData: GatheredBubble[],
    optimizationScale: number
  ) {
    const f =
      this.config.filterCircleRadius *
      2 *
      this.deviceHandler.getConfig().filteredCircleScaleDownFactor
    // calculate ideal position
    const x = nodeData.reduce((acc, cur) => Math.max(acc, cur.x), 0) * optimizationScale + f
    const y = nodeData.reduce((acc, cur) => Math.max(acc, cur.y), 0) * optimizationScale + f + 10

    const legend = g.append('g').attr('class', 'team-legend').style('opacity', 0)

    legend
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 20 * this.deviceHandler.getConfig().filteredCircleScaleDownFactor)
      .attr('stroke-width', 1)
    legend
      .append('text')
      .attr('x', 30 * this.deviceHandler.getConfig().filteredCircleScaleDownFactor)
      .attr('y', 5)
      .text(i18n.global.t('team.legend'))
    legend
      .transition()

      .delay(1000)
      .duration(500)
      .style('opacity', 1)

    // get bounding box
    const bbox = legend.node()?.getBBox()
    if (bbox) {
      legend.attr('transform', `translate(${x - bbox.width},${y - bbox.height})`)
    }
  }

  private legendHide(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    g.selectAll('.team-legend').transition().duration(500).style('opacity', 0).remove()
  }
}
