import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from '@firebase/firestore'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_KEY,
  authDomain: 'mpom-corporate-homepage.firebaseapp.com',
  projectId: 'mpom-corporate-homepage',
  storageBucket: 'mpom-corporate-homepage.appspot.com',
  messagingSenderId: '695902472321',
  appId: '1:695902472321:web:ba4d1b2763d4f8d5136580',
  measurementId: 'G-ENTWC0VVJD'
}

const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getFirestore(app)
