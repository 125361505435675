<script setup lang="ts">
import type { Ref } from 'vue'
import { ref, onMounted, watch } from 'vue'
import type { FilterState } from '@/lib/Rendering/RenderController'
import * as d3 from 'd3'

import EdgeHighlighter from '@/lib/EdgeHighlighter'
import { MycelModel } from '@/lib/MycelModel'
import { RenderController } from '@/lib/Rendering/RenderController'
import VertexGenerator from '@/lib/VertexGenerator'
import { SineCurve } from '@/lib/EdgeHighlighterCurves'
import EdgeWeightAdjuster from '@/lib/EdgeWeightAdjuster'
import DataFetcher from '@/lib/DataFetcher'
import MapButtons from '@/components/MapButtons.vue'

import { useRoute, useRouter } from 'vue-router'
import { useMenuStore } from '@/store'
import { DeviceMobile } from '@/lib/DeviceHandler/DeviceMobile'
import { DeviceDesktop } from '@/lib/DeviceHandler/DeviceDesktop'
import { DeviceCinematic } from '@/lib/DeviceHandler/DeviceCinematic'
import type { VertexPickerMode } from '@/lib/Editing/VertexPicker'
import i18n from '@/i18n'
import { storeToRefs } from 'pinia'

let mycelModel: MycelModel

let width: number
let height: number

const strategies = ['random', 'regular', 'semiregular']

const strategy: Ref<string> = ref('random')
const minDistanceFromOtherVertices: Ref<number> = ref(50)
const nodeCount: Ref<number> = ref(800)
const decimateRatio: Ref<number> = ref(0)
const edgeRetentionRate: Ref<number> = ref(1)
const variability: Ref<number> = ref(200)

const isDebug: Ref<boolean> = ref(false)
const isEdit: Ref<boolean> = ref(false)
const isDelete: Ref<boolean> = ref(false)
const isEdgeMarking: Ref<boolean> = ref(false)
const vertexPickerMode: Ref<VertexPickerMode> = ref('freefloat')
const vertexContentId: Ref<string> = ref('')
const svgIsReady: Ref<boolean> = ref(false)

const refNames = [
  'strategy',
  'minDistanceFromOtherVertices',
  'nodeCount',
  'decimateRatio',
  'edgeRetentionRate',
  'variability'
]

const vertexPickerModes = ['welcomevertex', 'timeline', 'freefloat']

const refsMap: Record<string, Ref<number | string>> = {
  strategy,
  minDistanceFromOtherVertices,
  nodeCount,
  decimateRatio,
  edgeRetentionRate,
  variability
}

defineExpose({
  resetZoom,
  reFocus
})

const props = defineProps({
  mobile: Boolean,
  cinematic: Boolean
})

const saveToLocalStorage = (refName: string) => {
  localStorage.setItem(refName, refsMap[refName].value.toString())
}

const restoreFromLocalStorage = (refName: string) => {
  if (localStorage.getItem(refName)) refsMap[refName].value = Number(localStorage.getItem(refName))
}

// Watch for changes on each ref and save to localStorage
refNames.forEach((refName) => {
  watch(
    () => refsMap[refName].value,
    () => saveToLocalStorage(refName)
  )
})

const route = useRoute()
const router = useRouter()
const menuStore = useMenuStore()
const { requestZoomLevelReset } = storeToRefs(menuStore)

function routeChange() {
  renderer.closeCircles()
  useMenuStore().setMenuOpen(false)
  switch (route.params.page) {
    case 'all':
      menuStore.setZoomButtonsShown(true)
      setDocumentTitle(i18n.global.t('navigation.all'))
      renderer.goHome()
      break
    case 'team':
      menuStore.setZoomButtonsShown(true)
      setDocumentTitle(i18n.global.t('navigation.team'))
      filter('people')
      break
    case 'projects':
      menuStore.setZoomButtonsShown(true)
      setDocumentTitle(i18n.global.t('navigation.projects'))
      filter('projects')
      break
    case 'services':
      menuStore.setZoomButtonsShown(true)
      setDocumentTitle(i18n.global.t('navigation.services'))
      filter('offerings')
      break
    case 'blog':
      menuStore.setZoomButtonsShown(true)
      setDocumentTitle(i18n.global.t('navigation.blog'))
      filter('blog')
      break
    case 'podcast':
      menuStore.setZoomButtonsShown(true)
      setDocumentTitle(i18n.global.t('navigation.podcast'))
      filter('podcast')
      break
    case 'contact':
      menuStore.setZoomButtonsShown(false)
      setDocumentTitle(i18n.global.t('navigation.get_in_touch'))
      resetZoom()
      showContactForm()
      break
    default:
      if (route.path === '/') {
        menuStore.setZoomButtonsShown(true)
        setDocumentTitle(i18n.global.t('navigation.all'))
        renderer.closeCircles()
        renderer.filterReset()
        renderer.zoomToZoomOnOpen(true)
      } else {
        renderer.panToSlug(route.path)
      }
  }
}

watch(
  () => requestZoomLevelReset.value,
  () => {
    if (requestZoomLevelReset.value) {
      resetZoom()
      requestZoomLevelReset.value = false
    }
  }
)

if (route.query['debug']) {
  isDebug.value = true
}

watch(
  () => route.params,
  () => {
    if (svgIsReady.value) {
      routeChange()
    }
  }
)

let svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, undefined>
let renderer: RenderController

function createDeviceHandler(isMobile: boolean, isCinematic: boolean) {
  if (isMobile) {
    return new DeviceMobile()
  } else if (isCinematic) {
    return new DeviceCinematic()
  }
  return new DeviceDesktop()
}

/**
 * Generate a new model and render it. @see restoreLayout() below
 */
async function render() {
  const cnt = nodeCount.value

  // Fetch data from Storyblok
  const dataFetcher = new DataFetcher(i18n.global.locale, import.meta.env.DEV)
  const mycelData = await dataFetcher.fetchAllData()

  const deviceHandler = createDeviceHandler(props.mobile, props.cinematic)

  // Generate a set of seed points
  const vertexGenerator = new VertexGenerator(
    cnt,
    width,
    height * deviceHandler.getOverflowRatio(),
    variability.value,
    deviceHandler
  )
  const points = vertexGenerator.generate(strategy.value, decimateRatio.value)

  mycelModel = new MycelModel(mycelData)
  mycelModel.generateEdges(points, edgeRetentionRate.value)

  // Align the timeline to a curve
  const curve = new SineCurve(width, height * deviceHandler.getOverflowRatio(), deviceHandler)
  const edgeHighlighter = new EdgeHighlighter(
    mycelModel,
    width,
    height * deviceHandler.getOverflowRatio(),
    curve,
    deviceHandler
  )
  edgeHighlighter.findTimeline()

  // Highlight some edges for cosmetic purposes
  const edgeWeightAdjuster = new EdgeWeightAdjuster(
    mycelModel,
    height * deviceHandler.getOverflowRatio(),
    width,
    deviceHandler
  )
  edgeWeightAdjuster.run()

  // Assign years and events to the timeline
  try {
    mycelModel.assignYearsLabels(minDistanceFromOtherVertices.value, deviceHandler)
  } catch (e) {
    // ignore case where events don't fit in timeline
    console.error(e)
  }
  svg.selectAll('*').remove()

  // Render the model
  renderer = new RenderController(svg, mycelModel, () => { }, deviceHandler, isDebug.value)
  renderer.render()
  svgIsReady.value = true
  routeChange()
}

let debounceTimer: NodeJS.Timeout | null
let throttleTimer: NodeJS.Timeout | null

async function createSvgAndRender() {
  width = window.innerWidth
  height = window.innerHeight
  svg = d3.select('#svgNode').append('svg').attr('width', width).attr('height', height)
  await restoreLayout()

  window.addEventListener('resize', () => {
    // Clear the debounce timer if it exists
    if (debounceTimer) {
      clearTimeout(debounceTimer)
    }

    // Set a new debounce timer
    debounceTimer = setTimeout(async () => {
      // Your resize logic here
      width = window.innerWidth
      height = window.innerHeight
      d3.select('#svgNode svg').attr('width', width).attr('height', height)
      await restoreLayout(true)

      // Throttling logic
      if (!throttleTimer) {
        throttleTimer = setTimeout(() => {
          throttleTimer = null
        }, 1000)
      } else {
        console.log('Not executing because of throttling')
      }
    }, 1000) // Debounce time: 1 second
  })
}

/**
 * Restore the layout from the prerendered data. This is the default approach.
 */
async function restoreLayout(skipWelcomeAnimation = false) {
  const deviceHandler = createDeviceHandler(props.mobile, props.cinematic)
  const dataFetcher = new DataFetcher(i18n.global.locale, import.meta.env.DEV)
  const mycelData = await dataFetcher.fetchAllData()
  mycelModel = new MycelModel(mycelData)
  mycelModel.restorePrerenderingData(deviceHandler.getPrerenderedData(), width, height)
  mycelModel.assignYearsLabels(minDistanceFromOtherVertices.value, deviceHandler)
  // mycelModel.assignTimelineEvents(minDistanceFromOtherVertices.value)
  svg.selectAll('*').remove()

  svg.node()?.addEventListener('welcomeBubbleClosed', () => {
    console.log('caught event')
    menuStore.setZoomButtonsShown(true)
  })

  // Render the model
  renderer = new RenderController(svg, mycelModel, () => { }, deviceHandler, isDebug.value)
  renderer.render()
  svgIsReady.value = true

  renderer.addEventListener('panToSlug', (event: any) => {
    router.replace({ path: event.detail.slug })
    setDocumentTitle(event.detail.title)
  })

  routeChange()
  if (!route.params.page && !skipWelcomeAnimation) {
    renderer.runWelcomeAnimation()
  }
}

function setDocumentTitle(title: string) {
  document.title = 'mühlemann+popp – ' + title
}

function resetZoomAndFilter() {
  renderer.filterReset()
  renderer.zoomReset()
}

function resetZoom() {
  renderer.zoomReset()
}

function reFocus() {
  routeChange()
}

function filter(type: FilterState) {
  renderer.filter(type)
}

function showContactForm() {
  renderer.showContactForm()
}

function freezeLayout() {
  console.log(mycelModel.getPrerenderingData(width, height))
}

function startEdit() {
  renderer.startEdit()
  isEdit.value = true
  isDelete.value = false
}

function startDelete() {
  renderer.startDelete()
  isEdit.value = false
  isDelete.value = true
}

function stopEdit() {
  renderer.stopEdit()
}

function undoMark() {
  renderer.vertexPickerUndo()
}

function storeMark() {
  renderer.vertexPickerStore()
}

function assignId() {
  renderer.vertexPickerAssignId(vertexContentId.value)
}

watch(
  () => isEdgeMarking.value,
  () => {
    if (isEdgeMarking.value) {
      renderer.startEdgeMarking()
    } else {
      renderer.stopEdit()
    }
  }
)

watch(
  () => vertexPickerMode.value,
  () => {
    renderer.setVertexPickerMode(vertexPickerMode.value)
  }
)

onMounted(async () => {
  refNames.forEach((refName) => restoreFromLocalStorage(refName))
  createSvgAndRender()
})
</script>
<template>
  <div class="absolute right-6 bottom-6">
    <MapButtons @zoomreset="renderer.zoomReset()" @zoomin="renderer.zoomIn()" @zoomout="renderer.zoomOut()" />
  </div>
  <Panel v-if="isDebug" class="z-5 absolute max-w-screen bottom-0 m-5" toggleable header="Developer Tools"
    :collapsed="true">
    <div class="hidden md:flex flex-row flex-wrap">
      <div class="field m-2">
        <label class="w-full text-center">minDistanceFromOtherVertices</label>
        <Knob v-model="minDistanceFromOtherVertices" :min="0" :max="2000" />
      </div>
      <div class="field m-2">
        <label class="w-full text-center">nodeCount</label>
        <Knob v-model="nodeCount" :min="100" :max="2000" />
      </div>
      <div class="field m-2">
        <label class="w-full text-center">decimateRatio</label>
        <Knob v-model="decimateRatio" :min="0" :max="1" :step="0.1" />
      </div>
      <div class="field m-2">
        <label class="w-full text-center">edgeRetentionRate</label>
        <Knob v-model="edgeRetentionRate" :min="0" :max="1" :step="0.1" />
      </div>
      <div class="field m-2">
        <label class="w-full text-center">variability</label>
        <Knob v-model="variability" :min="0" :max="1000" :step="10" />
      </div>
    </div>
    <div class="field flex justify-content-between">
      <div>
        <label class="hidden md:inline-block">Strategy Selection</label>
        <Dropdown v-model="strategy" :options="strategies" placeholder="Select a Strategy" class="md:mx-2" />
      </div>
      <Button label="Draw" class="mx-2" @click="render()" />
    </div>
    <div>
      <Button style="margin: 5px" @click="startEdit()" :class="{ active: isEdit }">Edit</Button>
      <Button style="margin: 5px" @click="startDelete()" :class="{ active: isDelete }">Delete
      </Button>
      <Button style="margin: 5px" @click="stopEdit()">Normal</Button>
      <Button style="margin: 5px" @click="freezeLayout()">Freeze Layout</Button>
      <Button style="margin: 5px" @click="restoreLayout()">Restore Layout</Button>
    </div>
    <div v-if="isEdit">
      <Checkbox v-model="isEdgeMarking" :binary="true" />
      <label for="edgeMarking" class="ml-2"> Mark Edges </label>

      <label class="hidden md:inline-block">Tool selection</label>
      <Dropdown v-model="vertexPickerMode" :options="vertexPickerModes" placeholder="Select a Mode" class="md:mx-2" />

      <Button style="margin: 5px" @click="undoMark()">Undo</Button>

      <InputText style="margin: 5px" v-model="vertexContentId" placeholder="id" />
      <Button style="margin: 5px" @click="assignId()">Assign Content ID</Button>

      <Button style="margin: 5px" @click="storeMark()">Store</Button>
    </div>
  </Panel>
  <div id="svgNode"></div>
</template>
<style scoped>
button.active {
  background-color: red;
}
</style>
