import * as d3 from 'd3'
import { type BaseType, interpolate } from 'd3'
import { type Vertex } from '../../../types'

type CustomInterpolator<T> = (d: T, progress: number) => string

function easeInOutCubic(t: number) {
  if (t < 0.5) {
    return 4 * t * t * t // When t is in [0, 0.5), this is the "ease in" phase
  } else {
    const f = t - 1
    return 1 + 4 * f * f * f // When t is in [0.5, 1], this is the "ease out" phase
  }
}

export default class RenderUtilities {
  /**
   * Tweening based on zoom level. Used to animate the transition between the different zoom levels
   * @param selection
   * @param attribute
   * @param a
   * @param b
   * @param startLevel zoom level where to start the transition
   * @param endLevel zoom level where to end the transition
   * @param zoomLevel current zoom level
   * @param condition if evaluates to false, the tween is not executed
   */
  static zoomTween(
    selection: d3.Selection<SVGSVGElement, unknown, SVGGElement, undefined>,
    attribute: string,
    a: string,
    b: string,
    startLevel: number,
    endLevel: number,
    zoomLevel: number,
    condition: (() => boolean) | undefined = undefined
  ) {
    if (condition && !condition()) {
      return
    }
    if (zoomLevel < startLevel) {
      selection.attr(attribute, a)
    } else if (zoomLevel < endLevel) {
      const progress = (zoomLevel - startLevel) / (endLevel - startLevel)
      // use the default interpolator function of D3
      const interpolator = interpolate(a, b)
      selection.attr(attribute, interpolator(easeInOutCubic(progress)))
    } else {
      selection.attr(attribute, b)
    }
  }

  /**
   * Tween function using a custom interpolator function. Used to position the collaborators around the project circle
   * depending on the tween progress / zoom level
   * @param selection
   * @param attribute
   * @param interpolationFunction
   * @param startLevel
   * @param endLevel
   * @param zoomLevel
   */
  static zoomTweenCustom(
    selection: d3.Selection<SVGGElement | BaseType, Vertex, SVGGElement, undefined>,
    attribute: string,
    interpolationFunction: CustomInterpolator<any>,
    startLevel: number,
    endLevel: number,
    zoomLevel: number,
    transition = false
  ) {
    let progress = 0
    if (zoomLevel > startLevel && zoomLevel < endLevel) {
      progress = (zoomLevel - startLevel) / (endLevel - startLevel)
    } else if (zoomLevel >= endLevel) {
      progress = 1
    } else {
      progress = 0
    }

    if (transition) {
      selection
        .transition()
        .duration(500)
        .attr(attribute, (d) => {
          return interpolationFunction(d, progress)
        })
    } else {
      selection.attr(attribute, (d: any) => {
        return interpolationFunction(d, progress)
      })
    }
  }
}
