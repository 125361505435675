<template>
  <div class="privacy-page">
    <h1>Datenschutzerklärung</h1>

    <h2>1 Datenschutzerklärung</h2>
    <p>
      Die vorliegende Datenschutzerklärung klärt Sie über die Art, den Umfang und den Zweck der
      Erhebung und Verwendung personenbezogener Daten auf unserer Website
      <a href="https://muehlemann-popp.ch">muehlemann-popp.ch</a> (im Folgenden „Website“) durch die
      muehlemann+popp AG (im Folgenden „wir“ oder „uns“) auf und gibt über die Ihnen zustehenden
      Rechte Auskunft. Diese Rechte richten sich nach den anwendbaren Datenschutzgesetzen.
    </p>

    <h2>2 Verantwortlicher für die Datenbearbeitung</h2>
    <p>
      Verantwortlicher für die Datenbearbeitung auf dieser Website und Ansprechpartner für
      Datenschutzanliegen ist:<br />
      muehlemann+popp AG<br />
      Limmatquai 122<br />
      +41 44 508 20 41<br />
      silvan.muehlemann@muehlemann-popp.ch
    </p>

    <h2>3 Datenerfassung auf dieser Website</h2>
    <h3>3.1 Logfiles</h3>
    <p>
      Unsere Website wird von Google (1600 Amphitheatre Parkway, Mountain View, California, 94043,
      USA) gehostet.<br />
      Zur Optimierung und Aufrechterhaltung unserer Website protokollieren wir technische Fehler,
      die beim Aufrufen unserer Website allenfalls auftreten. Ferner werden bei der Nutzung dieser
      Website automatisch Informationen erhoben, die der Browser Ihres Endgeräts an unseren
      Host-Provider übermittelt. Dies sind:
    </p>
    <ul>
      <li>IP-Adresse und Betriebssystem Ihres Endgeräts,</li>
      <li>Browsertyp, Version, Sprache</li>
      <li>Datum und Uhrzeit der Serveranfrage,</li>
      <li>aufgerufene Datei,</li>
      <li>die Website, von der aus der Zugriff erfolgte (Referrer URL),</li>
      <li>den Status-Code (z.B. 404) und</li>
      <li>das verwendete Übertragungsprotokoll (z.B. HTTP/2).</li>
    </ul>
    <p>
      Diese Daten werden von unserem Host-Provider erhoben und gespeichert, um Prozesse und Abläufe
      insbesondere in Zusammenhang mit der Nutzung unserer Website und der Sicherheit und Stabilität
      des Computersystems optimieren zu können.<br />
      Weitere Informationen finden Sie in der Datenschutzerklärung unter
      <a href="https://cloud.google.com/trust-center">https://cloud.google.com/trust-center</a
      >.<br />
      Sofern die DSGVO anwendbar ist, sind Grundlage für diese Datenbearbeitung Art. 6 Abs. 1 lit. f
      DSGVO.
    </p>

    <h3>3.2 Cookies</h3>
    <p>
      Wir setzen auf unserer Website Cookies ein. Cookies sind kleine Dateien, die auf Ihrem
      Endgerät abgelegt werden und die Ihr Browser speichert. Einige der von uns verwendeten Cookies
      werden automatisch gelöscht, wenn Sie unsere Website verlassen. Andere Cookies bleiben auf
      Ihrem End gerät gespeichert, bis Sie diese löschen oder bis sie ablaufen. Diese Cookies
      ermöglichen es, Ihren Browser beim nächsten Besuch unserer Website wiederzuerkennen.<br />
      In Ihrem Browser können Sie einstellen, dass Sie über das Setzen von Cookies vorab informiert
      werden und im Einzelfall entscheiden können, ob Sie die Annahme von Cookies für bestimmte
      Fälle oder generell ausschliessen, oder dass Cookies komplett verhindert werden. Dadurch kann
      die Funktionalität der Website eingeschränkt werden.<br />
      Cookies, die für den elektronischen Kommunikationsvorgang oder von Ihnen gewünschte Funktionen
      erforderlich sind oder Ihr Benutzererlebnis optimieren, werden – sofern die DSGVO anwendbar
      ist – auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert.
    </p>

    <h3>3.3 Chatverlauf</h3>
    <p>
      Der Chatverlauf im Chatbot wird aufgezeichnet und in den Systemen von <a href='https://openai.com/'>OpenAI</a>
      und <a href='https://slack.com/about'>Slack</a> verarbeitet.
      Teilen sie keine persönlichen Daten im Chatbot.
    </p>

    <h2>4 Externe Dienste</h2>
    <p>
      Auf unserer Website setzen wir verschiedene Dienste von Drittanbietern ein. Nachfolgend legen
      wir im Einzelnen dar, um welche Dienste es sich handelt, wofür wir sie einsetzen und welche
      Daten gesammelt werden.<br />
      Die Einbindung externer Dienste kann gemäss der aktuellen europäischen Rechtsprechung heikel
      sein. Die Weitergabe von Personendaten an externe Anbieter (Beispiel: Analytics, Werbung, CDN,
      Fonts, Videos usw.) sollte im Einzelfall analysiert werden.
    </p>

    <h3>4.1 Sentry</h3>
    <p>
      Wir nutzen Sentry von Functional Software Inc für die Fehlerprotokollierung<br />
      Sofern die DSGVO anwendbar ist, ist Grundlage für die Datenbearbeitung Art. 6 Abs. 1 lit. a
      DSGVO oder Art. 6 Abs. 1 lit. b DSGVO oder Art. 6 Abs. 1 lit. f DSGVO.<br />
      Die Datenschutzerklärung von Sentry finden Sie unter
      <a href="https://sentry.io/privacy/">https://sentry.io/privacy/</a>.
    </p>

    <h2>5 Links</h2>
    <p>
      Auf unserer Website finden Sie Links auf Seiten von Drittanbietern. Wir sind nicht
      verantwortlich für die Inhalte und Datenschutzvorkehrungen auf externen Websites, welche Sie
      über die Links erreichen können. Bitte informieren Sie sich über den Datenschutz direkt auf
      den entsprechenden Websites.
    </p>

    <h2>6 Ihre Rechte</h2>
    <p>
      Soweit von dem auf Sie anwendbaren Datenschutzrecht vorgesehen, können Sie unentgeltlich
      Auskunft über die auf Sie bezogenen gespeicherten Daten, deren Herkunft und Empfänger und den
      Zweck der Datenbearbeitung verlangen. Ebenso steht Ihnen unter den gesetzlichen
      Voraussetzungen ein Recht auf Berichtigung, Löschung, Einschränkung der oder Widerspruch gegen
      die Bearbeitung sowie, sofern die DSGVO zur Anwendung gelangt, auf Herausgabe dieser Daten zur
      Übertragung an eine andere Stelle zu.
    </p>
    <p>
      Eine von Ihnen erteilte Einwilligung können Sie mit Wirkung für die Zukunft jederzeit
      widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
    </p>
    <p>
      Bitte beachten Sie, dass die vorstehenden Rechte gesetzlichen Einschränkungen unterstehen und
      ggf. die Erbringung unserer Dienstleistungen beeinträchtigen oder verunmöglichen können.
    </p>
    <p>
      Sie sind berechtigt, Ihre Ansprüche gerichtlich durchzusetzen oder eine Beschwerde bei der
      zuständigen Datenschutzbehörde einzureichen. Für die Schweiz ist der Eidgenössische
      Datenschutz- und Öffentlichkeitsbeauftragte zuständig (http://www.edoeb.admin.ch).
    </p>

    <h2>8 Aktualität und Änderung dieser Datenschutzerklärung</h2>
    <p>
      Wir können diese Datenschutzerklärung jederzeit ändern oder anpassen. Die aktuelle
      Datenschutzerklärung kann auf muehlemann-popp.ch abgerufen werden.
    </p>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
.privacy-page {
  @apply py-24 px-4;
}

@media screen(sm) {
  .privacy-page {
    @apply px-10;
  }
}

p {
  @apply my-3;
}

h1 {
  @apply text-3xl my-5;
}

h2 {
  @apply text-2xl my-5;
}

h3 {
  @apply text-xl my-5;
}

ul {
  @apply list-disc list-inside;
}

li {
  @apply my-2;
}
</style>
