<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{ type?: string; value: string; placeholder: string}>(),
  { type: 'text', placeholder: ''}
)
const emit = defineEmits<{ (e: 'update:value', value: string): void }>()

const inputValue = computed({
  get() {
    return props.value
  },
  set(value: string) {
    emit('update:value', value)
  },
})
</script>

<template>
  <input
    v-model="inputValue"
    :type="type"
    v-bind="$attrs"
    :placeholder="placeholder"
    :class="[
      'flex',
      'border',
      'border-solid',
      'border-gray-200',
      'rounded-lg',
      'gap-3',
      'py-4',
      'px-3',
      'focus:outline-none',
      'focus:ring-2',
      'focus:ring-gray-500',
      'focus:border-gray-500',
      'w-full',
      'placeholder-gray-700',
    ]"
  />
</template>
