<script setup lang="ts">
import type { PropType } from 'vue'
import type { ChatMessageType } from '../../../types'
import avatar from '@/assets/avatar-gpt.png'
import { marked } from 'marked'
import IconTyping from '@/components/chat/IconTyping.vue'

const props = defineProps({
  message: {
    type: Object as PropType<ChatMessageType>,
    required: true
  },
  isTyping: {
    type: Boolean,
    default: false
  }
})

function html() {
  return marked.parse(props.message.text)
}

</script>
<template>
  <div class="chat-message mt-2">
    <div class="flex items-end" :class="{ 'justify-end': message.isMine }">
      <div
        class="flex flex-col space-y-1 text-xs max-w-sm ml-2 order-2"
        :class="{ 'items-end': message.isMine, 'items-start': !message.isMine }"
      >
        <div>
          <span
            v-if="!isTyping"
            :class="{ mine: message.isMine, 'not-mine': !message.isMine }"
            v-html="html()"
          ></span>
          <span v-else class="not-mine"><IconTyping /></span>
        </div>
        <div v-if="!message.isMine" class="flex items-center">
          <span class="text-gray-400 mr-2">{{ message.user?.name ?? 'GPTBot'}}</span>
        </div>
      </div>
      <img
        v-if="!message.isMine"
        :src="message.user?.avatar ? message.user.avatar : avatar"
        style='margin-bottom: 3px'
        class="w-8 h-8 rounded-full order-1"
      />
    </div>
  </div>
</template>

<style scoped>
.mine {
  @apply px-4 py-2 rounded-lg inline-block rounded-br-none text-black;
  background-color: var(--gold);
}

.not-mine {
  @apply px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600;
}
</style>
