import * as d3 from 'd3'
import type { MycelModel } from '@/lib/MycelModel'
import GraphTools from '@/lib/GraphTools'
import type { Edge, Vertex } from '../../../types'
import type { DeviceAbstract } from '@/lib/DeviceHandler/DeviceAbstract'

export type VertexPickerMode = 'welcomevertex' | 'timeline' | 'freefloat'

/**
 * Mode "Welcome vertex picker":
 * Developer tool to pick a vertex by clicking on it. The vertex is then set as the welcome vertex.
 * Once the vertex has been set, use the "Freeze layout" tool to export the layout.
 *
 * Select a dropdown which determines the target the where the path will be stored:
 *  - "Welcome vertex": The path will be stored in the welcome vertex.
 *  - "Timeline": The path will be stored in the timeline.
 *  - "freefloat": This is a list of vertices which are not connected to the timeline. They
 *                 have the same signature as timeline events, but are not connected to the timeline.
 *

 * Mode "Edge picker"
 * Points can be selected one by one. (Only adacent points can be selected will be selected).
 * On click, the edge is added to the graph.
 * On right-click, the edge is removed from the graph.
 *
 *
 *
 */
export default class VertexPicker {
  private readonly svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, undefined>
  private readonly g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
  private readonly staticG: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
  private readonly width: number
  private readonly height: number
  private readonly mycelModel: MycelModel
  private unsubscribe = [() => {}, () => {}, () => {}]
  private mode: VertexPickerMode = 'freefloat'

  private currentVertex: Vertex | undefined
  private lastVertex: Vertex | undefined
  private path: Vertex[] = []
  private pathEdges: Edge[] = []

  constructor(
    svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, undefined>,
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    staticG: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    mycelModel: MycelModel
  ) {
    this.svg = svg
    this.g = g
    this.staticG = staticG
    this.width = parseInt(svg.attr('width'))
    this.height = parseInt(svg.attr('height'))
    this.mycelModel = mycelModel
  }

  setMode(mode: VertexPickerMode) {
    this.mode = mode
  }

  /**
   * Assigns a contend ID to the last set vertex.
   * This will be used later to link it with the Storyblok content ID.
   * @param id
   */
  assignId(id: string) {
    if (this.lastVertex) {
      this.lastVertex.contentId = id
    }
    this.renderLabels()
  }

  run(isDelete = false) {
    this.renderLabels()

    // Debug-Text for mouse coordinates
    const coordsText = this.staticG!.append('text').attr('x', 100).attr('y', 220)

    this.g.on('mousemove', (event) => {
      const [x, y] = d3.pointer(event)
      if (this.mycelModel.vertices) {
        const closestVertex = GraphTools.findClosestVertex(
          Object.values(this.mycelModel.vertices),
          { x, y }
        )
        if (closestVertex) {
          this.currentVertex = closestVertex
          this.g.selectAll('.marker').remove()
          this.highlightVertex(closestVertex, 'marker')
        }
        // Update text element with mouse coordinates
        let txt = `x: ${x.toFixed(2)}, y: ${y.toFixed(2)}`

        if (closestVertex) {
          txt += ` | closest vertex: ${closestVertex.id}`
        }

        coordsText.text(txt)
      }
    })

    this.g.on('click', (event) => {
      if (this.mode === 'welcomevertex' && this.currentVertex) {
        this.mycelModel.setWelcomeVertex(this.currentVertex)
      } else {
        this.clickHandlerFreefloat(event, isDelete)
      }
      if (this.currentVertex) console.log(this.currentVertex.id)
    })
  }

  public stop() {
    this.g.on('mousemove', null)
    this.g.on('click', null)
    this.g.selectAll('.marker').remove()
    this.g.selectAll('.selected').remove()
    this.g.selectAll('.vertex-picker-label').remove()
  }

  public undo() {
    if (this.path.length > 0) {
      const lastVertex = this.path.pop()
      if (lastVertex) {
        this.g.selectAll(`.selected-${this.path.length + 1}`).remove()
        this.lastVertex = this.path[this.path.length - 1]
        if (this.lastVertex) {
          this.highlightVertex(this.lastVertex, this.getSelectedClass())
        }
      }
    }
    this.renderLabels()
  }

  /**
   * Store the path in the model
   */
  public store(deviceHandler: DeviceAbstract) {
    if (this.mode === 'welcomevertex') {
      throw new Error('Cannot store path in welcome vertex in this mode')
    } else if (this.mode === 'timeline') {
      // remove all existing timeline edges and vertices
      this.mycelModel
        .getVerticesByType(['timeline-connector', 'timeline-year'])
        .forEach((vertex) => {
          vertex.type = 'node'
        })
      this.mycelModel.getEdgesByType('timeline').forEach((edge) => {
        edge.type = 'neutral'
      })
      // add new timeline edges and vertices
      this.pathEdges.forEach((edge) => {
        edge.type = 'timeline'
      })
      this.path.forEach((vertex) => {
        vertex.type = 'timeline-connector'
      })
      this.mycelModel.timelineVertices = this.path
      this.mycelModel.assignYearsLabels(0, deviceHandler)
    } else {
      this.pathEdges.forEach((edge) => {
        edge.type = 'freefloat'
      })
      this.path.forEach((vertex) => {
        vertex.type = 'freefloat'
      })
    }
    console.log('Path marked in Model in mode', this.mode)
  }

  private getSelectedClass() {
    return `selected selected-${this.path.length}`
  }

  private deleteFreefloatVertex() {
    // if there is already a path, then remove that edge
    if (this.path.length > 0) {
      const lastVertex = this.path.pop()
      const lastEdge = this.pathEdges.pop()
      if (lastVertex && lastEdge) {
        this.lastVertex = this.path[this.path.length - 1]
        if (this.lastVertex) {
          this.highlightEdge(lastEdge, '', 'white')
        }
        lastEdge.type = 'neutral'
        lastVertex.type = 'node'
      }
    }

    // make the current vertex normal again
    if (this.currentVertex) {
      this.g.selectAll(`.freefloat-${this.currentVertex.contentId}`).remove()
      this.currentVertex.timelineEvent = undefined
      this.currentVertex.type = 'node'
      this.currentVertex.contentId = undefined
      this.g.selectAll(`.selected`).remove()
      // Remove the freefloat marker
    }
  }

  private clickHandlerFreefloat(event: MouseEvent, isDelete = false) {
    if (!this.currentVertex) {
      console.info('No vertex selected')
      return
    }

    if (this.path.indexOf(this.currentVertex) >= 0) {
      // change start point if clicking the same point again
      console.log('click on the same point')
      this.lastVertex = this.currentVertex
      if (isDelete) {
        return this.deleteFreefloatVertex()
      }
    } else if (!this.lastVertex) {
      console.log('first point')
      if (isDelete) {
        this.deleteFreefloatVertex()
      } else {
        this.highlightVertex(this.currentVertex, this.getSelectedClass())
      }
      this.path.push(this.currentVertex)
      this.lastVertex = this.currentVertex
    } else {
      const edge = GraphTools.getConnectedEdge(
        this.mycelModel.edges,
        this.currentVertex,
        this.lastVertex
      )
      if (edge) {
        console.log('add connected edge')
        this.path.push(this.currentVertex)
        this.pathEdges.push(edge)
        if (isDelete) {
          this.deleteFreefloatVertex()
        } else {
          this.highlightVertex(this.currentVertex, this.getSelectedClass())
          this.highlightEdge(edge, this.getSelectedClass())
        }
        this.lastVertex = this.currentVertex
      } else {
        // start a new connector
        this.lastVertex = this.currentVertex

        if (isDelete) {
          this.deleteFreefloatVertex()
        } else {
          this.highlightVertex(this.currentVertex, this.getSelectedClass())
        }
        this.path.push(this.currentVertex)
      }
    }
  }

  private renderLabels() {
    this.g
      .selectAll('.vertex-picker-label')
      .data(Object.values(this.mycelModel.vertices))
      .join('text')
      .attr('class', 'vertex-picker-label')
      .attr('x', (d) => d.x + 10)
      .attr('y', (d) => d.y + 10)
      .text((d) => d.contentId || null)
  }

  private highlightVertex(vertex: Vertex, cls: string) {
    this.g
      .append('circle')
      .attr('class', cls)
      .attr('cx', vertex.x)
      .attr('cy', vertex.y)
      .attr('r', 5)
      .attr('fill', '#f00')
  }

  private highlightEdge(edge: Edge, cls: string, color = '#f00') {
    this.g
      .append('line')
      .attr('class', cls)
      .attr('x1', edge.source.x)
      .attr('y1', edge.source.y)
      .attr('x2', edge.target.x)
      .attr('y2', edge.target.y)
      .attr('stroke', color)
      .attr('stroke-width', 3)
  }
}
