import Mycelium from '@/components/Mycelium.vue'
import PrivacyView from '@/components/PrivacyView.vue'
import { createRouter, createWebHistory } from 'vue-router'
import type { Router } from 'vue-router'
import { analytics } from '@/firebase/init'
import { logEvent } from 'firebase/analytics'
import D3TextView from '@/components/D3TextView.vue'
import ChatView from '@/components/ChatView.vue'

let _router: Router
export const getRouter = () => {
  if (!_router) {
    const routes = [
      { path: '/', component: Mycelium },
      { path: '/chat', component: ChatView },
      { path: '/privacy', component: PrivacyView },
      { path: '/test', component: D3TextView },
      { path: '/:page', component: Mycelium },
      { path: '/:folder/:page', component: Mycelium },
      { path: '/:folder/:subfolder/:page', component: Mycelium },
      { path: '/de/:page', component: Mycelium },
      { path: '/de/:folder/:page', component: Mycelium },
      { path: '/de/:folder/:subfolder/:page', component: Mycelium }
    ]
    _router = createRouter({
      history: createWebHistory(),
      routes
    })
    _router.afterEach((to) => {
      logEvent(analytics, 'page_view', {
        page_path: to.fullPath
      })
    })
  }
  return _router
}
