import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useMenuStore = defineStore('navigation', () => {
  const menuOpen = ref(false)
  const zoomButtonsShown = ref(false)
  const zoomLevelChanged = ref(false)
  const requestZoomLevelReset = ref(false)
  const chatOpen = ref(false)
  const isMobile = ref(false)

  function setMenuOpen(open: boolean) {
    menuOpen.value = open
  }

  function setZoomButtonsShown(shown: boolean) {
    zoomButtonsShown.value = shown
  }

  return {
    setMenuOpen,
    menuOpen,
    setZoomButtonsShown,
    zoomButtonsShown,
    zoomLevelChanged,
    requestZoomLevelReset,
    chatOpen,
    isMobile
  }
})

export const useContactFormStore = defineStore('contact-form', () => {
  function closeForm() {
    // placeholder
  }

  return { closeForm }
})
