<script setup lang="ts">
import { useContactFormStore } from '@/store'

const { closeForm } = useContactFormStore()
</script>

<template>
  <div class="border-b border-b-black pb-2 flex justify-between items-center">
    <div class='header-title'>{{$t('contactBubble.title')}}</div>
    <img src="@/assets/menu-close.svg" class="circle-icon" alt="close" @click="closeForm" />
  </div>
</template>

<style scoped>
.circle-icon {
  @apply cursor-pointer;
  height: 60px;
}
.header-title {
  @apply font-bold text-4xl;
}
</style>
