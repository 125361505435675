<script setup lang="ts">
import PageHeader from '@/components/contact-form/PageHeader.vue'
import { Loader } from '@googlemaps/js-api-loader'
import { onMounted, ref } from 'vue'
import officePhoto from '@/assets/office.jpg'

const isSafari = ref(false)

onMounted(() => {
  //isSafari.value = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  isSafari.value = true

  if (isSafari.value) {
    return
  }

  const loader = new Loader({
    apiKey: 'AIzaSyAFc8QWX-DRCPEGCRI9cUij8-ysMYS6UiI',
    version: 'weekly'
  })

  loader.load().then(async () => {
    const center = { lat: 47.37558816364298, lng: 8.543644482209414 }
    const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary
    const map = new Map(document.getElementById('map') as HTMLElement, {
      center,
      mapId: '2b121715cf812a69',
      zoom: 16,
      streetViewControl: false,
      mapTypeControl: false
    })

    const { AdvancedMarkerElement, PinElement } = (await google.maps.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary

    const pinBackground = new PinElement({
      background: '#e6b652',
      glyphColor: '#fff',
      borderColor: '#fff'
    })

    const marker = new AdvancedMarkerElement({
      map: map,
      position: center,
      content: pinBackground.element,
      title: 'm+p Office'
    })
  })
})
</script>

<template>
  <div class="contactPage h-full">
    <page-header class="mb-4"></page-header>
    <div class="flex flex-row mt-4" style="height: 450px">
      <div v-if="isSafari" class="mr-2" style="width: 50%">
        <a href="https://maps.app.goo.gl/MXCP6koohhs42cz66" target="_blank">
          <img
            src="https://maps.googleapis.com/maps/api/staticmap?center=47.37558816364298,8.543644482209414&zoom=16&size=500x1000&maptype=roadmap&markers=color:0xe6b652%7C47.37558816364298,8.543644482209414&key=AIzaSyAFc8QWX-DRCPEGCRI9cUij8-ysMYS6UiI&map_id=2b121715cf812a69" />
        </a>
      </div>
      <div v-else id="map" class="mr-2" style="width: 50%"></div>
      <div class="ml-2 w-6" style="width: 50%">
        <img :src="officePhoto" alt="m+p Office" />
      </div>
    </div>

    <div class="mt-4">
      <b>mühlemann+popp AG</b> &middot; Limmatquai 122 &middot; 8001 Zürich<br /><a href="tel:+41435082040">+41 44 508
        20 40</a>
      &middot;
      <a href="mailto:info@muehlemann-popp.ch">info@muehlemann-popp.ch</a>
    </div>
  </div>
</template>

<style scoped>
.contactPage {
  width: 100%;
  height: 100%;
}

a:hover {
  text-decoration: underline;
}

#map {
  width: 50%;
  height: 500px;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Covers the div without stretching the image */
  object-position: center;
  /* Centers the image within the div */
}
</style>
