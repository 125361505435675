import type { FreefloatContent, TimelineEventClasses, Vertex } from '../../../../types'
import type { Selection } from 'd3'
import RenderUtilities from '@/lib/Rendering/RenderUtilities'
import { AbstractSvgRenderer } from '@/lib/Rendering/BubbleRenderer/AbstractSvgRenderer'

export default class FreefloatRenderer extends AbstractSvgRenderer {
  getClassName(): TimelineEventClasses {
    return 'freefloat'
  }

  getCssClassName(d: Vertex) {
    const freefloatContent = d.timelineEvent as FreefloatContent
    return `freefloat freefloat-component-${freefloatContent.component}`
  }

  protected getClassTitleLabel(d: Vertex): string {
    return 'classTitleLabel.' + ((d.timelineEvent as FreefloatContent).component ?? '')
  }

  public getHTML(d: Vertex) {
    return d.timelineEvent?.description ?? ''
  }

  getHtmlWidthFactor(event: any): number {
    return 1
  }

  getSubTitle(event: any): string {
    return 'Subtitle'
  }

  render(g: Selection<SVGGElement, unknown, HTMLElement, undefined>, isZooming: boolean): void {
    const vertices = this.mycelModel.getVerticesByClass('freefloat')

    if (!isZooming) {
      this.createGroupSvg(g, vertices)
      this.createClassTitleSvg()
      this.createTitleSvg()
      this.createLineSvg()
      this.createHtmlSvg(true)
      this.createButtons()
    }
  }

  public getVerticesInTheSameStoryblokFolder(d: Vertex | null = null) {
    if (!d) {
      return Object.values(this.mycelModel.vertices).filter((v) => v.type === 'freefloat')
    }

    // we use the parentId (folder in Storyblok) to determine the type of freefloat element we want to focus on
    return Object.values(this.mycelModel.vertices).filter(
      (v) =>
        v.type === 'freefloat' &&
        (d.timelineEvent as FreefloatContent).storyblokParentId ===
          (v.timelineEvent as FreefloatContent).storyblokParentId
    )
  }

  public zoomHandler(
    g: Selection<SVGGElement, unknown, HTMLElement, undefined>,
    zoomTweenRangeAdjustment: number,
    logicZoomLevel: number
  ) {
    RenderUtilities.zoomTween(
      g.selectAll(
        '.freefloat .event-buttons, .freefloat .event-line, .freefloat .event-class'
      ),
      'opacity',
      '0',
      '1',
      10 * zoomTweenRangeAdjustment,
      40 * zoomTweenRangeAdjustment,
      logicZoomLevel
    )
    RenderUtilities.zoomTween(
      g.selectAll(
        '.freefloat foreignObject div'
      ),
      'style',
      'display:none',
      'display:block',
      30 * zoomTweenRangeAdjustment,
      40 * zoomTweenRangeAdjustment,
      logicZoomLevel
    )
    RenderUtilities.zoomTween(
      g.selectAll('.freefloat text.event-title, .freefloat text.event-subtitle'),
      'font-size',
      '80px',
      '15px',
      10 * zoomTweenRangeAdjustment,
      40 * zoomTweenRangeAdjustment,
      logicZoomLevel
    )
    RenderUtilities.zoomTween(
      g.selectAll('.freefloat text.event-title, .freefloat text.event-subtitle'),
      'transform',
      'translate(80,110)',
      'translate(0,0)',
      10 * zoomTweenRangeAdjustment,
      40 * zoomTweenRangeAdjustment,
      logicZoomLevel
    )
  }
}
