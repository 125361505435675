<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import * as d3 from 'd3'
import { HtmlToSvg } from '@/lib/Utils/HtmlToSvg'
import DataFetcher from '@/lib/DataFetcher'
import i18n from '@/i18n'
import type { MycelData, TimelineEvent } from '../../types'

const viz = ref<HTMLElement | null>(null)

let mycelData = ref<MycelData>()
let g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>

onMounted(async () => {
  const dataFetcher = new DataFetcher(i18n.global.locale)
  mycelData.value = await dataFetcher.fetchAllData()

  const svg = d3.select('#viz').append('svg').attr('width', '100vw').attr('height', '100vh')

  g = svg.append('g')

  const htmlToSvg = new HtmlToSvg(g, 1.1, 0, 0, 500)

  htmlToSvg.render(`
  <h1>The Header</h1>
  <p>hello world. This is a quite <b>long text</b> which should be wrapped.
  It is very very very long.</p>
  <p>Here's a line with a<br/>break.</p>
  <ul><li>Item 1</li><li>Item 2</li></ul>
  <p>How are you?</p>
  <h2>The Subheader</h2>
  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
  `)

  //svg.append(textbox)
})

const services = computed(() => {
  if (mycelData.value === undefined) return []
  return Object.values(mycelData.value!.timeline).filter(
    (ev: TimelineEvent) => ev.class === 'offering'
  )
})

function view(e:TimelineEvent) {
  console.log('view', e.description)

  g.text(null)
  const htmlToSvg = new HtmlToSvg(g, 1.1, 0, 0, 350)
  htmlToSvg.render(e.description)
}
</script>

<template>
  <ul>
    <li v-for="item in services" :key="item.id" @click='view(item)'>{{ item.title }}</li>
  </ul>
  <div id="viz"></div>
</template>
