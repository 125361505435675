<script setup lang="ts">
import { useField, useForm } from 'vee-validate'
import { reactive, ref } from 'vue'
import { getFunctions, httpsCallable } from '@firebase/functions'

import { useContactFormStore } from '@/store'
import i18n from '@/i18n'

const formSent = ref(false)
const { closeForm } = useContactFormStore()
const { handleSubmit, resetForm } = useForm()
const nameField = reactive(useField('name', validateName))

function validateName(value: string) {
  if (!value) {
    return i18n.global.t('contactForm.nameRequired')
  }

  return true
}

const emailField = reactive(useField('email', validateEmail))

function validateEmail(value: string) {
  if (!value) {
    return i18n.global.t('contactForm.emailRequired')
  }
  if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gim.test(value)) {
    return i18n.global.t('contactForm.emailInvalid')
  }
  return true
}

const messageField = reactive(useField('message', validateMessage))

function validateMessage(value: string) {
  if (!value) {
    return i18n.global.t('contactForm.messageRequired')
  }
  return true
}

const onSubmit = handleSubmit(async (values) => {
  const resendEmail = httpsCallable(getFunctions(), 'sendContactForm')
  await resendEmail({ name: nameField.value, email: emailField.value, message: messageField.value })
  resetForm()
  formSent.value = true
})
</script>

<template>
  <form v-if="!formSent" class="contactForm" @submit.prevent="onSubmit">
    <label for='name'>{{ $t('contactBubble.placeholder.name') }}</label>
    <input
      name="name"
      type="text"
      v-model="nameField.value"
    />
    <div class="p-error">{{ nameField.errorMessage || '&nbsp;' }}</div>
    <label for='email'>{{ $t('contactBubble.placeholder.email') }}</label>
    <input
      name="emai"
      type="email"
      v-model="emailField.value"
    />
    <div class="p-error">{{ emailField.errorMessage || '&nbsp;' }}</div>
    <label for='message'>{{ $t('contactBubble.placeholder.message') }}</label>
    <textarea
      name="message"
      v-model="messageField.value"
    ></textarea>
    <div class="p-error">{{ messageField.errorMessage || '&nbsp;' }}</div>
    <button class="black-button w-full" @click.prevent="onSubmit">
      {{ $t('contactBubble.sendButton') }}
    </button>

  </form>
  <div class="finalPanel" v-else>
    <div class="finalMessage">{{ $t('contactBubble.formSentMessage') }}</div>
    <button @click="closeForm" class="black-button mt-10 mx-auto">
      {{ $t('contactBubble.closeButton') }}
    </button>
  </div>

</template>

<style scoped>
.contactForm {
  & form {
    display: block;
  }

  & input,
  & textarea {
    display: block;
    border: 1px solid black;
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    background: transparent;

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-weight: normal;
      opacity: 0.6;
      color: black;
    }

    &:invalid {
      border-color: red;
    }
  }

  & textarea {
    resize: none;
  }
}

.finalPanel {
  @apply flex flex-col items-center justify-center h-full;
}

.finalMessage {
  @apply font-bold text-2xl;
}

.black-button {
  color: white;
  @apply font-bold bg-black px-8 py-4 rounded-full;
}

.black-button:hover {
  background-color: #333333;
}

</style>
