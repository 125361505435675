import { createI18n } from 'vue-i18n'
import en from '@/locale/en.json'
import de from '@/locale/de.json'

type LocaleStr = 'en' | 'de'

const setPreferredLang = (lang: LocaleStr) => {
  if (['en', 'de'].includes(lang)) {
    localStorage.setItem('lang', lang)
  }
}

const initBrowserLang = (): string => {
  // check if lang is set in URL
  if (/\bde\b/.test(location.pathname)) {
    return 'de'
  }

  if (localStorage && localStorage.getItem('lang')) {
    return localStorage.getItem('lang') || 'en'
  }
  if (/^de\b/.test(navigator.language)) {
    return 'de'
  }
  return 'en'
}

const i18n = createI18n({
  locale: initBrowserLang(),
  fallbackLocale: 'en',
  messages: {
    en,
    de
  }
})

const changeLang = (lang: LocaleStr) => {
  setPreferredLang(lang)
  // i18n.global.locale = lang
  window.location.reload()
}

export { changeLang }
export default i18n
