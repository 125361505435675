import type { MycelModel } from '@/lib/MycelModel'
import * as d3 from 'd3'
import { type BaseType, type ValueFn } from 'd3'
import type {
  Edge,
  EdgeType,
  EdgeWeight,
  Offering,
  RenderClassConfig,
  TimelineEvent,
  Vertex,
  VertexType
} from 'types'

import { PeopleRenderer } from './BubbleRenderer/PeopleRenderer'
import ProjectRenderer from './BubbleRenderer/ProjectRenderer'
import OfferingRenderer from './BubbleRenderer/OfferingRenderer'
import FreefloatParentRenderer from './BubbleRenderer/FreefloatParentRenderer'
import TeamEventRenderer from '@/lib/Rendering/BubbleRenderer/TeamEventRenderer'
import { WelcomeAnimation } from '@/lib/Rendering/WelcomeAnimation'
import VertexPicker, { type VertexPickerMode } from '@/lib/Editing/VertexPicker'
import type { DeviceAbstract } from '@/lib/DeviceHandler/DeviceAbstract'
import FreefloatRenderer from './BubbleRenderer/FreefloatRenderer'
import GraphTools from '@/lib/GraphTools'
import { ContactFormBubble } from '@/lib/Rendering/ContactFormBubble'
import RenderUtilities from './RenderUtilities'
import type { AbstractSvgRenderer } from '@/lib/Rendering/BubbleRenderer/AbstractSvgRenderer'
import { isSafari } from '../Utils/Utils'
import { debounce } from 'lodash'
import { BlogRenderer } from './BubbleRenderer/BlogRenderer'
import { PodcastRenderer } from './BubbleRenderer/PodcastRenderer'

export type FilterState = 'all' | 'projects' | 'people' | 'offerings' | 'blog' | 'podcast'

const TRANSITION_DURATION = 500

export class RenderController extends EventTarget {
  private svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, undefined>
  private g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined> | undefined
  private staticG: d3.Selection<SVGGElement, unknown, HTMLElement, undefined> | undefined
  private mycelModel: MycelModel

  // SVG dimensions
  private width: number
  private height: number

  // Config
  private strokeWidth: Record<EdgeWeight | EdgeType, number>
  private circleRadii: Record<VertexType, number>

  private isDebug = false // enables additional verbosity
  private isEdit = false // enables editing nodes
  private isDelete = false // enables deleting nodes

  // Zoom-related variables
  private zoom: d3.ZoomBehavior<Element, unknown> | undefined
  private onZoom: (zoomLevel: number) => void
  private currentCurrentZoomLevel: number | undefined
  private initialZoom = 1.1

  private filterstate: FilterState = 'all'
  // Necessary for the zoom handler to determine if the filtering animation is still in progress.
  // If we don't have this, the images already receive the size in the "filtered" state.
  private filteringAnimationInProgress = false

  private welcomeAnimation: WelcomeAnimation
  private contactForm: ContactFormBubble

  // RenderController for the different types of timeline events
  private renderClasses: RenderClassConfig[]
  private renderClassInstances: Record<string, AbstractSvgRenderer> = {}

  private deviceHandler: DeviceAbstract
  private vertexPicker: VertexPicker | undefined

  /**
   *
   * @param svg
   * @param mycelModel
   * @param scaleMultiplier
   * @param onZoom
   * @param deviceHandler
   * @param isDebug
   */
  constructor(
    svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, undefined>,
    mycelModel: MycelModel,
    onZoom: (zoomLevel: number) => void,
    deviceHandler: DeviceAbstract,
    isDebug = false
  ) {
    super()
    this.svg = svg
    this.mycelModel = mycelModel
    this.onZoom = onZoom
    this.deviceHandler = deviceHandler
    this.isDebug = isDebug

    this.strokeWidth = {
      light: 1,
      medium: 4,
      heavy: 10,
      neutral: 1,
      timeline: 10,
      'timeline-event': 4,
      freefloat: 4
    }

    this.circleRadii = {
      'timeline-year': 30,
      'timeline-connector': 5,
      event: 10,
      'event-connector': 2,
      'freefloat-connector': 2,
      freefloat: 10,
      'freefloat-parent': 30,
      welcomevertex: 10,
      node: 0
    }

    this.width = parseInt(svg.attr('width'))
    this.height = parseInt(svg.attr('height'))

    // this looks too verbose, but if you use `class.name` to get the class name, after minification
    // the class name will be replaced with a shorter name. So we need to use a string here.
    this.renderClasses = [
      {
        name: 'PeopleRenderer',
        class: PeopleRenderer,
        eventClass: 'person-onboarding',
        enabled: true
      },
      { name: 'ProjectRenderer', class: ProjectRenderer, eventClass: 'project', enabled: true },
      { name: 'OfferingRenderer', class: OfferingRenderer, eventClass: 'offering', enabled: true },
      { name: 'BlogRenderer', class: BlogRenderer, eventClass: 'blogarticle', enabled: true },
      { name: 'PodcastRenderer', class: PodcastRenderer, eventClass: 'podcast', enabled: true },
      {
        name: 'TeamEventRenderer',
        class: TeamEventRenderer,
        eventClass: 'team-event',
        enabled: true
      },
      {
        name: 'FreefloatRenderer',
        class: FreefloatRenderer,
        eventClass: 'freefloat',
        enabled: true
      },
      {
        name: 'FreefloatParentRenderer',
        class: FreefloatParentRenderer,
        eventClass: 'freefloat-parent',
        enabled: true
      }
    ]

    this.renderClasses
      .filter((cls) => cls.enabled)
      .forEach((cls) => {
        this.renderClassInstances[cls.name] = new cls.class(
          mycelModel,
          this.currentCurrentZoomLevel,
          this.circleRadii,
          this.circleClickHandler,
          this.deviceHandler
        )
      })

    this.welcomeAnimation = new WelcomeAnimation(svg, mycelModel, this.deviceHandler)

    this.contactForm = new ContactFormBubble(svg, this.deviceHandler)

    this.initialZoom = deviceHandler.getInitialZoom()
  }

  public render() {
    const svg = this.svg
    const mycelModel = this.mycelModel

    const g = (this.g = svg.append('g').attr('class', 'graph-container'))

    this.storeFilterstateInDOM()

    // @todo move to PeopleRenderer
    // Clip path for the person images
    const personClipRadius = 200
    const defs = svg.append('defs')

    defs
      .append('clipPath')
      .attr('id', 'personClip')
      .append('circle')
      .attr('cx', personClipRadius)
      .attr('cy', personClipRadius)
      .attr('r', personClipRadius)

    this.createDarkenFilter(defs)

    g.append('rect')
      .attr('class', 'background')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('click', () => {
        this.goHome()
      })

    // Draw lines for the edges
    g.selectAll('line.gray')
      .data(mycelModel.edges.filter((edge) => edge.type === 'neutral'))
      .join('line')
      .attr('x1', (edge: Edge) => edge.source.x)
      .attr('y1', (edge: Edge) => edge.source.y)
      .attr('x2', (edge: Edge) => edge.target.x)
      .attr('y2', (edge: Edge) => edge.target.y)
      .attr('stroke-width', (edge: Edge) => this.strokeWidth[edge.weight ?? 'light'])
      .attr('class', (edge: Edge) => edge.type + ' gray ' + (edge.weight ? ' ' + edge.weight : ''))

    // Colored edges
    g.selectAll('line.colored')
      .data(mycelModel.edges.filter((edge) => edge.type !== 'neutral'))
      .join('line')
      .attr('x1', (edge: Edge) => edge.source.x)
      .attr('y1', (edge: Edge) => edge.source.y)
      .attr('x2', (edge: Edge) => edge.target.x)
      .attr('y2', (edge: Edge) => edge.target.y)
      .attr('stroke-width', (edge: Edge) => this.strokeWidth[edge.type])
      .attr(
        'class',
        (edge: Edge) => edge.type + ' colored ' + (edge.weight ? ' ' + edge.weight : '')
      )

    // Draw circles for the Vertices
    const VerticesWithYear = Object.values(mycelModel.vertices).filter(
      (v) => v.type === 'timeline-year'
    )
    const VerticesWithTimelineEvent = Object.values(mycelModel.vertices).filter(
      (v) =>
        ['event', 'event-connector', 'freefloat', 'freefloat-connector'].indexOf(v.type || '') > -1
    )

    // all circles, regardless of type
    this.createCircle(g)

    // Labels for the years
    g.selectAll('text.timeline-year')
      .data(Object.values(VerticesWithYear))
      .join('text')
      .attr('class', 'timeline-year')
      .attr('x', (vertex: Vertex) => vertex.x)
      .attr('y', (vertex: Vertex) => vertex.y)
      .attr('text-anchor', 'middle')
      .attr('font-size', '15px')
      .attr('dominant-baseline', 'middle')
      .text((d: Vertex) => d.year || '')

    this.createTimelineEvent(VerticesWithTimelineEvent, g)

    if (this.isDebug) {
      g.selectAll('text.debug')
        .data(Object.values(mycelModel.vertices))
        .join('text')
        .attr('class', 'debug')
        .attr('fill', 'red')
        .attr('font-size', '8px')
        .attr('x', (vertice: Vertex) => vertice.x)
        .attr('y', (vertice: Vertex) => vertice.y)
        .text((vertice: Vertex) => vertice.id ?? '-')
    }

    if (this.isDebug) this.startDebug()

    this.zoom = d3
      .zoom()
      .scaleExtent([this.initialZoom, this.deviceHandler.getConfig().maxZoomLevel])
      .translateExtent(this.deviceHandler.getZoomHandlerTranslateExtent(this.width, this.height))
      .filter((event) => {
        if (this.welcomeAnimation.getIsVisible()) {
          // No zoom when welcome bubble is visible
          return false
        }

        if (isSafari()) {
          // disable any pinch-zooming
          if (event.touches && event.touches.length > 1) return false

          // No panning when filtered
          if (this.filterstate !== 'all') {
            return false
          }

          // No panning when zoomed in
          if (this.currentCurrentZoomLevel! > this.initialZoom * 2) {
            return false
          }
        }

        // ensure that scrollable content remains scrollable
        // and that double-clicks are not zooming
        return event.type !== 'dblclick' && !event.target!.closest('.scrollable')
      })
      .on('zoom', (e) => this.zoomHandler(e))
      .on('end', (e) => this.zoomEndHandler(e))

    this.resetZoom()

    this.zoomToZoomOnOpen()
  }

  public zoomToZoomOnOpen(useTransition = false) {
    if (useTransition) {
      const transitionDuration = 750 // for example, 750ms
      this.svg
        .transition()
        .duration(transitionDuration)
        .call(
          this.zoom!.transform as any,
          this.deviceHandler.getTransformForZoomOnOpen(this.width, this.height)
        )
    } else {
      // Apply the transition to the SVG
      this.svg.call(
        this.zoom!.transform as any,
        this.deviceHandler.getTransformForZoomOnOpen(this.width, this.height)
      )
    }
  }

  private createCircle(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    const VerticesNonNeutral = Object.values(this.mycelModel.vertices).filter(
      (v) => (v.weight ?? 'light' != 'light') || v.type !== 'node'
    )

    g.selectAll<SVGGElement, Vertex>('circle')
      .data<Vertex>(Object.values(VerticesNonNeutral), (d: Vertex) => d.id ?? '')
      .join('circle')
      .attr('class', (d: Vertex) => {
        const type = d.type!.toString()
        return (
          type +
          ' ' +
          ((d.timelineEvent as Offering)?.component ?? '') +
          ' ' +
          ((d.timelineEvent as Offering)?.isTitle ? 'offering-title' : '') +
          ' ' +
          (d.timelineEvent ? `${type}-${d.timelineEvent?.id} ${d.timelineEvent.class}` : '')
        )
      })
      .attr('cx', (vertex: Vertex) => vertex.x)
      .attr('cy', (vertex: Vertex) => vertex.y)
      .attr('r', (d: Vertex) => {
        if (d.type == 'node') {
          return this.strokeWidth[d.weight ?? 'light'] / 2
        } else {
          return this.circleRadii[d.type]
          //return 1
        }
      })
      .on('click', this.circleClickHandler.bind(this))
  }

  public runWelcomeAnimation() {
    this.welcomeAnimation.run()
  }

  public hideWelcomeAnimation() {
    if (this.welcomeAnimation.getIsVisible()) {
      this.welcomeAnimation.collapseCircle()
    }
  }

  public showContactForm() {
    this.closeCircles()
    if (!this.contactForm.getIsVisible()) {
      this.contactForm.run()
    }
  }

  public hideContactForm() {
    if (this.contactForm.getIsVisible()) {
      this.contactForm.collapseCircle()
    }
  }

  public closeCircles() {
    this.hideWelcomeAnimation()
    this.hideContactForm()
  }

  public zoomIn() {
    this.svg.transition().call(this.zoom!.scaleBy as any, 1.5)
  }

  public zoomOut(allTheWay = false) {
    if (this.currentCurrentZoomLevel! > this.initialZoom * 0.66 && !allTheWay) {
      this.svg.transition().call(this.zoom!.scaleBy as any, 0.66)
    } else {
      this.svg.transition().call(this.zoom!.scaleTo as any, this.initialZoom)
    }
  }

  public zoomReset() {
    this.resetZoom(true, () => {}, true)
  }

  public startEdit() {
    this.vertexPicker = new VertexPicker(this.svg, this.g!, this.staticG!, this.mycelModel)
    this.vertexPicker.run()
    this.isEdit = true
  }

  public startDelete() {
    this.vertexPicker = new VertexPicker(this.svg, this.g!, this.staticG!, this.mycelModel)
    this.vertexPicker.run(true)
    this.isDelete = true
  }

  private startDebug() {
    this.isDebug = true
    this.staticG = this.svg.append('g').attr('class', 'static-container')

    // Debug-Text for zoom level
    this.staticG!.raise()
      .append('text')
      .attr('id', 'zoom-level')
      .attr('x', 100)
      .attr('y', 200)
      .text()
  }

  public stopDebug() {
    this.isDebug = false
    this.staticG!.selectAll('text').remove()
  }

  public startEdgeMarking() {
    if (!this.vertexPicker) return
    this.vertexPicker.run()
  }

  public stopEdit() {
    if (!this.vertexPicker) return
    this.vertexPicker.stop()
    this.isEdit = false
  }

  public setVertexPickerMode(mode: VertexPickerMode) {
    if (!this.vertexPicker) return
    this.vertexPicker.setMode(mode)
  }

  public vertexPickerUndo() {
    if (!this.vertexPicker) return
    this.vertexPicker.undo()
  }

  public vertexPickerStore() {
    if (!this.vertexPicker) return
    this.vertexPicker.store(this.deviceHandler)
  }

  public vertexPickerAssignId(id: string) {
    if (!this.vertexPicker) return
    this.vertexPicker.assignId(id)
  }

  public panToSlug(slug: string) {
    const vertex = this.mycelModel.getVertexBySlug(slug)
    if (vertex) {
      this.panToVertex(vertex)
    }
  }

  private triggerZoomHandler() {
    if (this.zoom) {
      window.setTimeout(() => {
        // trigger an artificial zoom event
        this.zoomHandler({ transform: d3.zoomTransform(this.svg.node() as Element) })
      }, 1000)
    }
  }

  /**
   * Repositions the map to the initial state. This means, completely zoomed-out and centered, with a slight
   * offset because the edges are not pretty.
   *
   * @param doTransition
   * @param listener
   * @param skipIfAlreadyZoomedOut
   * @private
   */
  private resetZoom(
    doTransition = false,
    listener: ValueFn<SVGGElement, Vertex, void> = () => {},
    skipIfAlreadyZoomedOut = false
  ) {
    if (this.zoom === undefined) {
      throw new Error('Zoom is undefined. Call render() first.')
    }

    // Unsure if "skipIfAlreadyZoomedOut" this is necessary. :-)
    // initialZoom is in Logic coordinates, currentCurrentZoomLevel is in SVG coordinates
    // changing to 0.9 to prevent "invisible" zoom transitions
    if (
      skipIfAlreadyZoomedOut &&
      (!this.currentCurrentZoomLevel || this.currentCurrentZoomLevel < this.initialZoom / 0.9)
    ) {
      doTransition = false
    }

    this.svg.on('dblclick.zoom', null).call(this.zoom as any)
    const newTransform = this.deviceHandler.getTransformForZoomAll(this.width, this.height)

    if (doTransition) {
      // Note: This will call the "render()" function of the timeline-events continuously.
      this.svg
        .transition()
        .duration(2000)
        .call(this.zoom.transform as any, newTransform)
        // @ts-ignore
        .on('end', listener)
    } else {
      this.svg.call(this.zoom.transform as any, newTransform)
      if (listener) {
        // @ts-ignore
        listener()
      }
    }
  }

  /**
   * @param VerticesWithTimelineEvent
   * @param g
   * @private
   */
  private createTimelineEvent(
    VerticesWithTimelineEvent: Vertex[],
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
  ) {
    // Object.values(this.renderClassInstances).forEach((renderClassInstance) => {
    //   renderClassInstance.render(g, false)
    // })
    Object.values(this.renderClassInstances).forEach((renderer) => {
      renderer.render(g, false)

      renderer.addEventListener('buttonNextClick', (event: any) => {
        const d = event.detail.destination
        if (d.timelineEvent.class === 'offering') {
          this.panToVertex(this.mycelModel.getAdjacentFreefloatEvent(d, 'forward'))
        } else {
          this.panToVertex(this.mycelModel.getAdjacentTimelineEvent(d, 'forward'))
        }
      })

      renderer.addEventListener('buttonPrevClick', (event: any) => {
        const d = event.detail.destination
        if (d.timelineEvent.class === 'offering') {
          this.panToVertex(this.mycelModel.getAdjacentFreefloatEvent(d, 'back'))
        } else {
          this.panToVertex(this.mycelModel.getAdjacentTimelineEvent(d, 'back'))
        }
      })

      renderer.addEventListener('buttonAllClick', (event: any) => {
        const d = event.detail.destination
        if (this.filterstate === 'all') {
          let freefloatRenderer
          if (this.renderClassInstances['FreefloatRenderer']) {
            freefloatRenderer = this.renderClassInstances['FreefloatRenderer'] as FreefloatRenderer
          }
          switch (d.timelineEvent?.class) {
            case 'project':
              this.filter('projects')
              break
            case 'person-onboarding':
              this.filter('people')
              break
            case 'offering':
              this.filter('offerings')
              break
            case 'blogarticle':
              this.filter('blog')
              break
            case 'podcast':
              this.filter('podcast')
              break
            case 'freefloat':
              if (freefloatRenderer !== undefined) {
                this.panToVertices(freefloatRenderer.getVerticesInTheSameStoryblokFolder(d), 0.3)
              }
              break
          }
        } else {
          this.zoomReset()
        }
      })

      renderer.addEventListener('buttonCloseClick', (event: any) => {
        this.zoomOutAllTheWay()
      })

      renderer.addEventListener('circleClick', (event: any) => {
        this.circleClickHandler(event, event.detail.destination)
      })
    })
  }

  private createDarkenFilter(defs: d3.Selection<SVGDefsElement, unknown, HTMLElement, undefined>) {
    const filter = defs.append('filter').attr('id', 'darkenFilter')

    // Create the feComponentTransfer element and set its type
    const feComponentTransfer = filter.append('feComponentTransfer').attr('type', 'linear')

    // Create feFuncR, feFuncG, feFuncB and set their types and slopes
    feComponentTransfer.append('feFuncR').attr('type', 'linear').attr('slope', '0.5')
    feComponentTransfer.append('feFuncG').attr('type', 'linear').attr('slope', '0.5')
    feComponentTransfer.append('feFuncB').attr('type', 'linear').attr('slope', '0.5')
  }

  /**
   * Triggers a transition from the current vertex to the destination vertex. The vertex is centered on the screen.
   * The size is defined by the minimum of the following two values:
   * - the scale factor that is necessary to fill the screen with the vertex.
   * - the maximum allowable zoom level for the current state of the graph.
   *
   * @param destination
   * @param duration in ms
   * @private
   */
  private panToVertex(destination: Vertex, duration: number = 0) {
    let x, y

    // In Edit-Mode we don't pan to the vertex
    if (this.isEdit) {
      return true
    }

    const HEADER_HEIGHT = 88

    if (destination.filteredPosition === undefined) {
      x = destination.x
      y = destination.y
    } else {
      // filtered state
      x = destination.filteredPosition.x
      y = destination.filteredPosition.y
    }

    /**
     * Takes the scale factor that is necessary to fill the screen with the vertex.
     */
    const getScaleFactorForFillingScreen = () => {
      let radius
      const VISIBLE_RATIO = 0.8 // the higher, the smaller the bubble

      const renderClass = this.renderClasses.filter(
        (cls) => cls.eventClass === destination.timelineEvent!.class && cls.enabled
      )[0]
      const renderClassInstance = this.renderClassInstances[renderClass.name]

      if (destination.filteredPosition === undefined) {
        // unfiltered state
        radius = this.circleRadii[destination.type]
      } else {
        // filtered state
        radius =
          renderClassInstance.config.filterCircleRadius *
          this.deviceHandler.getConfig().filteredCircleScaleDownFactor
      }

      const bb = {
        width: radius * 2 * VISIBLE_RATIO,
        height: radius * 2 * VISIBLE_RATIO
      }
      const scaleFactor = Math.min(this.width / bb.width, (this.height - HEADER_HEIGHT) / bb.height)
      return scaleFactor
    }

    /**
     * Takes the maximum allowable zoom level for the current state of the graph.
     */
    const getScaleFactorForMaxZoom = () => {
      let scaleFactor
      const cfg = this.deviceHandler.getConfig()
      const filterToUnfiltered = cfg.maxZoomLevelFiltering / cfg.maxZoomLevel
      if (destination.filteredPosition === undefined) {
        scaleFactor =
          destination.type === 'timeline-year'
            ? 10
            : cfg.maxZoomLevelForFocusingByTimelineEventClass[destination.timelineEvent!.class]
      } else {
        const zoomLevel =
          cfg.maxZoomLevelForFocusingByTimelineEventClass[destination.timelineEvent!.class] *
          filterToUnfiltered
        scaleFactor = zoomLevel
      }
      return scaleFactor
    }

    // We take the minimum of the two scale factors, preventing an overflow.
    const scaleFactor = Math.min(getScaleFactorForMaxZoom(), getScaleFactorForFillingScreen())

    const newTransform = d3.zoomIdentity
      .translate(this.width / 2 - scaleFactor * x, this.height / 2 - scaleFactor * y)
      .scale(scaleFactor)
    if (duration) {
      this.svg
        .transition()
        .duration(duration)
        .call(this.zoom!.transform as any, newTransform)
        .on('end', () => {
          this.sendSlugToHistory(destination)
        })
    } else {
      this.svg.call(this.zoom!.transform as any, newTransform)
      this.sendSlugToHistory(destination)
    }
  }

  private sendSlugToHistory(destination: Vertex) {
    if (destination.timelineEvent?.slug) {
      const evt = new CustomEvent('panToSlug', {
        detail: { slug: destination.timelineEvent?.slug, title: destination.timelineEvent.title }
      })
      this.dispatchEvent(evt)
    }
  }

  /**
   * Pans so that all vertices are shown on the screen
   * @param destination
   * @param relativePadding 0.1 means 10% padding. 0 means no padding.
   */
  public panToVertices(destination: Vertex[], relativePadding = 0) {
    if (destination.length == 0) {
      return
    }
    if (destination.length == 1) {
      return this.panToVertex(destination[0], TRANSITION_DURATION)
    }
    const bb = GraphTools.getBoundingBox(destination, 0.1)

    const scaleFactor =
      Math.min(this.width / bb.width, this.height / bb.height) * (1 - relativePadding)
    const translateX = this.width / 2 - (bb.x + bb.width / 2) * scaleFactor
    const translateY = this.height / 2 - (bb.y + bb.height / 2) * scaleFactor

    const transform = d3.zoomIdentity.translate(translateX, translateY).scale(scaleFactor)

    this.svg
      .transition()
      .duration(TRANSITION_DURATION)
      .call(this.zoom!.transform as any, transform)
  }

  private getVerticesInYear(year: number) {
    return Object.values(this.mycelModel.vertices).filter(
      (v) => v.timelineEvent?.date && v.timelineEvent.date.getFullYear() === year
    )
  }

  public filterByType(type: FilterState, rendererKey: string) {
    if (this.filterstate !== type) {
      this.closeCircles()
      this.filterReset()
    }
    this.resetZoom(
      true,
      () => {
        this.filterstate = type
        this.storeFilterstateInDOM()
        if (this.g === undefined) return
        this.filteringAnimationInProgress = true
        this.renderClassInstances[rendererKey].filter(
          this.g,
          this.width,
          this.height,
          () => (this.filteringAnimationInProgress = false)
        )
        this.onFilteringStart()
      },
      true
    )
  }

  public filter(type: FilterState) {
    switch (type) {
      case 'projects':
        return this.filterByType('projects', 'ProjectRenderer')
      case 'people':
        return this.filterByType('people', 'PeopleRenderer')
      case 'offerings':
        return this.filterByType('offerings', 'OfferingRenderer')
      case 'blog':
        return this.filterByType('blog', 'BlogRenderer')
      case 'podcast':
        return this.filterByType('podcast', 'PodcastRenderer')
      default:
        throw new Error(`Invalid type: ${type}`)
    }
  }

  /**
   * Reset all previous filters
   */
  public filterReset() {
    switch (this.filterstate) {
      case 'people':
        this.renderClassInstances['PeopleRenderer'].resetFilter(this.g!)
        break
      case 'projects':
        this.renderClassInstances['ProjectRenderer'].resetFilter(this.g!)
        break
      case 'offerings':
        this.renderClassInstances['OfferingRenderer'].resetFilter(this.g!)
        break
      case 'blog':
        this.renderClassInstances['BlogRenderer'].resetFilter(this.g!)
        break
      case 'podcast':
        this.renderClassInstances['PodcastRenderer'].resetFilter(this.g!)
        break
    }
    this.filterstate = 'all'
    this.storeFilterstateInDOM()
    this.onFilteringEnd()
  }

  public goHome() {
    this.closeCircles()
    this.filterReset()
    this.resetZoom(true, () => {}, true)
  }

  /**
   * The difference between this and "goHome" is that this function only resets
   * the zoom level, but does not pan to the center of the graph.
   */
  public zoomOutAllTheWay() {
    this.closeCircles()
    this.filterReset()
    this.zoomOut(true)
  }

  public panToServices() {
    this.filterReset()
    const c = this.renderClassInstances['FreefloatRenderer'] as FreefloatRenderer
    this.panToVertices(c.getVerticesInTheSameStoryblokFolder(), 0.2)
  }

  private storeFilterstateInDOM() {
    this.svg!.node()!.setAttribute('data-filterstate', this.filterstate)
  }

  private onFilteringStart() {
    this.zoom?.scaleExtent([this.initialZoom, this.deviceHandler.getConfig().maxZoomLevelFiltering])

    const overlay = this.g!.append('rect')
      .attr('id', 'filter-overlay')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('fill', 'white')
      .style('opacity', 0)
      .on('click', () => {
        this.zoomOut(true)
        this.filterReset()
      })
    overlay.transition().duration(TRANSITION_DURATION).style('opacity', 0.8)
  }

  private onFilteringEnd() {
    this.zoom?.scaleExtent([this.initialZoom, this.deviceHandler.getConfig().maxZoomLevel])

    this.g
      ?.select('#filter-overlay')
      .transition()
      .duration(TRANSITION_DURATION)
      .style('opacity', 0)
      .on('end', () => {
        this.g?.select('#filter-overlay').remove()
      })

    this.triggerZoomHandler()
  }

  private circleClickHandler(event: any, d: Vertex) {
    // Pan to all vertices of the same component
    if (d.timelineEvent?.class === 'freefloat-parent') {
      const c = this.renderClassInstances['FreefloatRenderer'] as FreefloatRenderer
      this.panToVertices(c.getVerticesInTheSameStoryblokFolder(d), 0.2)
    } else if (d.type === 'timeline-year') {
      this.panToVertices(this.getVerticesInYear(d.year!), 0.2)
    } else {
      this.panToVertex(d, 2000)
    }
  }

  private zoomHandler = (e: any) => {
    let transform: d3.ZoomTransform

    const g = this.g!
    g.attr('transform', (transform = e.transform))

    if (transform.k != 0.12) {
      this.onZoom(transform.k)
    }

    // Used to disable hover effects when zoomed in. Increasing this means that the zoomed-out effects
    // will be disabled earlier.
    // Also used to show the foreignObjects in Safari.
    if (transform.k < this.deviceHandler.getConfig().zoomedOutStateZoomLevel[this.filterstate]) {
      this.g!.node()!.classList.add('zoomed-out')
    } else {
      this.g!.node()!.classList.remove('zoomed-out')
    }

    this.currentCurrentZoomLevel = transform.k
    Object.values(this.renderClassInstances).forEach((renderClassInstance) => {
      renderClassInstance.currentCurrentZoomLevel = transform.k
    })
    const logicZoomLevel = transform.k

    if (this.isDebug) {
      this.staticG!.select('#zoom-level').text(
        'Logic Zoom Level: ' +
          logicZoomLevel.toFixed(2).toString() +
          ' | SVG Zoom Level: ' +
          transform.k.toFixed(2).toString()
      )
    }

    // Update the size of the circles with the years
    const timelineYearZoomLevel = Math.min(transform.k, 2)
    g.selectAll('circle.timeline-year').attr('r', (d) => {
      return this.circleRadii['timeline-year'] / timelineYearZoomLevel
    })
    g.selectAll('text.timeline-year').attr('font-size', (d) => {
      return 15 / timelineYearZoomLevel
    })

    const adj = this.deviceHandler.getConfig().zoomTweenRangeAdjustment

    const callZoomHandlerOnRenderClassInstances = (className: string) => {
      this.renderClasses.forEach((cls) => {
        this.renderClassInstances[cls.name].zoomHandler(
          g,
          adj,
          logicZoomLevel,
          cls.name == className
        )
      })
    }

    if (this.filterstate === 'all' || this.filteringAnimationInProgress) {
      if (this.renderClassInstances['FreefloatParentRenderer']) {
        this.renderClassInstances['FreefloatParentRenderer'].zoomHandler(
          g,
          adj,
          logicZoomLevel,
          false
        )
      }

      // make the title smaller for the freefloat content
      RenderUtilities.zoomTween(
        g.selectAll(
          '.project text.event-title, .project text.event-subtitle, .event-buttons, ' +
            '.team-event text.event-title, .team-event text.event-subtitle'
        ),
         'opacity',
        '0',
        '1',
        10 * adj,
        40 * adj,
        logicZoomLevel
      )
      RenderUtilities.zoomTween(
        g.selectAll('g.event-group'),
        'transform',
        'translate(0,0)',
        'translate(-60,-40)',
        10 * adj,
        40 * adj,
        logicZoomLevel
      )
      RenderUtilities.zoomTween(
        g.selectAll('g.event.team-event'),
        'opacity',
        '0',
        '1',
        30 * adj,
        45 * adj,
        logicZoomLevel
      )
      callZoomHandlerOnRenderClassInstances('')

      this.safariForeignObjectFix(transform, 400 / 800)
    } else if (this.filterstate === 'projects') {
      RenderUtilities.zoomTween(
        g.selectAll('g.event.team-event'),
        'opacity',
        '0',
        '1',
        1,
        5,
        logicZoomLevel
      )
      callZoomHandlerOnRenderClassInstances('ProjectRenderer')
      this.safariForeignObjectFix(
        transform,
        (1600 / 400) * this.deviceHandler.getConfig().safariForeignObjectFixFactor
      )
    } else if (this.filterstate === 'offerings') {
      callZoomHandlerOnRenderClassInstances('OfferingRenderer')
      this.safariForeignObjectFix(
        transform,
        (1600 / 400) * this.deviceHandler.getConfig().safariForeignObjectFixFactor
      )
    } else if (this.filterstate === 'blog') {
      callZoomHandlerOnRenderClassInstances('BlogRenderer')
      this.safariForeignObjectFix(
        transform,
        (1600 / 400) * this.deviceHandler.getConfig().safariForeignObjectFixFactor
      )
     } else if (this.filterstate === 'podcast') {
      callZoomHandlerOnRenderClassInstances('PodcastRenderer')
      this.safariForeignObjectFix(
        transform,
        (1600 / 400) * this.deviceHandler.getConfig().safariForeignObjectFixFactor
      )
    } else if (this.filterstate === 'people') {
      RenderUtilities.zoomTween(
        g.selectAll(
          '.person-onboarding text.event-subtitle, .person-onboarding text.event-class, .person-onboarding line.event-line, g.person-onboarding.event-buttons'
        ),
        'style',
        'opacity:0',
        'opacity:1',
        4,
        5,
        logicZoomLevel
      )
      callZoomHandlerOnRenderClassInstances('PeopleRenderer')
      this.safariForeignObjectFix(
        transform,
        (2000 / 800) * this.deviceHandler.getConfig().safariForeignObjectFixFactor
      )
    }
  }

  private zoomEndHandler = (e: any) => {
    this.onZoomEnd(e)
  }

  onZoomEnd = debounce((event) => {
    // This code is currently unnecessary as it's only if we would like to replace the foreignObject with an SVG.
    // which is not necessary anymore.
    this.renderClasses.forEach((cls) => {
      this.renderClassInstances[cls.name].createSvgContent_(this.svg)
    })
    // Logic for after the zoom/pan ends
  }, 250) // 250 milliseconds delay

  /**
   * Handles the issue in Safari where the foreignObject elements are not scaled correctly if a "overflow:scroll" is applied.
   * @see https://stackoverflow.com/a/60578296/2454815
   * @param transform
   * @param svgToViewBoxRatio
   * @private
   */
  private safariForeignObjectFix(transform: d3.ZoomTransform, svgToViewBoxRatio: number) {
    if (isSafari()) {
      d3.selectAll('.event-body').style(
        'transform',
        `scale(${(transform.k * svgToViewBoxRatio) / 10})`
      )
    }
  }

  private animateEdgeGrow(timelineEvent: TimelineEvent) {
    let i = 0
    for (const item of this.mycelModel.edgeGrowHistory) {
      if (item.timelineEvent !== timelineEvent) continue
      for (const edge of item.edgePath) {
        window.setTimeout(() => {
          const l = this.g!.append('line')
            .attr('x1', edge.source.x)
            .attr('y1', edge.source.y)
            .attr('x2', edge.target.x)
            .attr('y2', edge.target.y)
            .attr('stroke', 'red')
            .attr('stroke-width', 5)

          window.setTimeout(() => {
            l.remove()
          }, 1000)
        }, 50 * i)
        i++
      }
    }
  }
}
