/*
Queryies Storyblok API for data and retuns the data
 */

import { apiPlugin, storyblokInit, type StoryblokClient, renderRichText } from '@storyblok/js'
import type { ISbRichtext } from '@storyblok/js'
import type {  
  BlogArticle,
  MycelData,
  Offering,
  Person,
  Podcast,
  Project,
  TeamEvent,
  Timeline
} from '../../types'
import _ from 'lodash'

const isRichText = (data: any): data is ISbRichtext => {
  return _.isArray(data?.content)
}
const richTextToHtmlString = (data: string | { content?: [] }) => {
  if (_.isString(data)) {
    return data
  } else if (isRichText(data)) {
    return renderRichText(data)
  }
  return ''
}

class DataFetcher {
  storyblokApi: StoryblokClient
  mycelData: MycelData = {
    timeline: {},
    people: {},
    freefloatcontent: {}
  }
  public timeline: Timeline = {}
  public people: Record<number, Person> = {}
  public isDev: boolean
  protected locale: string

  constructor(locale: string, isDev: boolean = false) {
    const { storyblokApi } = storyblokInit({
      accessToken: 'vPliFrlivmeTXrbvJ32prAtt',
      use: [apiPlugin]
    })

    if (storyblokApi === undefined) {
      throw new Error('Storyblok API not initialized')
    }
    this.storyblokApi = storyblokApi
    this.locale = locale
    this.isDev = isDev
  }

  async fetchStories(starts_with: string) {
    const { data } = await this.storyblokApi.get('cdn/stories/', {
      per_page: 100,
      starts_with,
      language: this.locale,
      version: this.isDev ? 'draft' : 'published'
    })
    return data.stories
  }

  addToTimeline(date: Date, event: any) {
    try {
      this.timeline[date.toISOString()] = event
    } catch (e) {
      console.error(e, event.title)
    }
  }

  async fetchAllData() {
    this.mycelData = {
      timeline: this.timeline,
      people: await this.fetchPeople(),
      freefloatcontent: {}
    }
    await this.fetchTeamEvents()
    await this.fetchProjects()
    await this.fetchOfferings()
    await this.fetchBlogArticles()
    await this.fetchPodcasts()

    return this.mycelData
  }

  async fetchPeople() {
    const stories = await this.fetchStories('person/')

    stories.forEach((item: any) => {
      if (!item.content.hired_on) {
        console.warn(`Person ${item.content.title} has no hired_on date.`)
        return
      }
      if (!item.content.photo) {
        console.warn(`Person ${item.content.title} has no photo.`)
        return
      }
      if (item.content.hidden || item.content.is_active === false) {
        return
      }
      const person: Person = {
        id: item.id,
        uuid: item.uuid,
        name: item.content.title,
        position: item.content.position,
        description: richTextToHtmlString(item.content.description) as string,
        avatar_url:
          typeof item.content.avatar_url === 'string'
            ? item.content.avatar_url
            : item.content.avatar_url.url,
        photo:
          typeof item.content.photo === 'string'
            ? item.content.photo
            : item.content.photo?.filename ?? '',
        isHidden: item.content.hidden,
        isActive: item.content.is_active,
        isManagement: item.content.is_management ?? false,
        urlLinkedin: item.content.url_linkedin?.url,
        urlGithub: item.content.url_github?.url
      }
      this.people[item.id] = person
      const date = new Date(item.content.hired_on)
      if (date.getFullYear() >= 2010) {
        this.addToTimeline(date, {
          class: 'person-onboarding',
          date,
          title: person.name,
          description: '',
          id: person.id,
          slug: '/' + item.full_slug,
          person: person.id
        })
      } else {
        console.warn(`Person ${person.name} hired in ${date.getFullYear()} not in range.`)
      }
    })
    return this.people
  }

  async fetchProjects() {
    const stories = await this.fetchStories('projects/')

    stories.forEach((item: any) => {
      try {
        if (!item.content.started) return
        const date = new Date(item.content.started)
        const title = item.content.title

        const event: Project = {
          class: 'project',
          id: item.id,
          slug: '/' + item.full_slug,
          date,
          title,
          description: richTextToHtmlString(item.content.description || { content: [] }) as string,
          collaborators: item.content.collaborators as string[],
          logo_url: item.content.logo?.filename,
          website_url: item.content.link,
          pictures: item.content.pictures || [],
          lastmod: item.published_at ?? item.created_at,
          translatedSlugs: getTranslatedSlugs(item)
        }
        this.addToTimeline(date, event)
      } catch (e) {
        console.error(e)
      }
    })

    return this.timeline
  }

  async fetchBlogArticles() {
    const stories = await this.fetchStories('blog/')

    stories.forEach((item: any) => {
      try {
        const date = new Date(item.content.date)
        const title = item.translated_slugs?.find((item: any) => item.lang === this.locale)?.name ?? item.name

        const blog: BlogArticle = {
          class: 'blogarticle',
          id: item.id,
          slug: '/' + item.full_slug,
          date,
          title,
          author: item.content.author,
          description: richTextToHtmlString(item.content.description || { content: [] }) as string,
          lastmod: item.published_at ?? item.created_at,
          translatedSlugs: getTranslatedSlugs(item)
        }
        console.log('added article', item.name)
        this.addToTimeline(date, blog)
      } catch (e) {
        console.error(e)
      }
    })
    return this.timeline
  }

  async fetchTeamEvents() {
    const stories = await this.fetchStories('career/team-events/')

    stories.forEach((item: any) => {
      try {
        const date = new Date(item.content.date)
        const title = item?.content?.title || item.name

        const event: TeamEvent = {
          class: 'team-event',
          id: item.id,
          slug: '/' + item.full_slug,
          date,
          title,
          description: '',
          lastmod: item.published_at ?? item.created_at,
          photos: item.content.photos.map((photo: any) => ({
            id: photo.id,
            filename: photo.filename,
            title: photo.title
          }))
        }
        this.addToTimeline(date, event)
      } catch (e) {
        console.error(e)
      }
    })

    return this.timeline
  }

  /**
   * Fetches content which is not directly linked to a year
   */
  async fetchOfferings() {
    const stories = await this.fetchStories('offerings/')

    stories.forEach((item: any) => {
      const title = item?.content?.title || item.name
      const date = new Date(item.content.date)

      const content: Offering = {
        class: 'offering',
        id: item.id,
        slug: '/' + item.full_slug,
        translatedSlugs: item.translated_slugs
          ? item.translated_slugs.map((item: any) => {
              return {
                lang: item.lang,
                slug: item.path
              }
            })
          : [],
        title,
        date,
        component: item.content.component,
        storyblokParentId: item.parent_id,
        isTitle: item.content.is_title,
        description: richTextToHtmlString(item.content.description),
        lastmod: item.published_at ?? item.created_at
      }
      try {
        this.addToTimeline(date, content)
      } catch (e) {
        console.error(e)
      }
    })

    return this.timeline
  }

  async fetchPodcasts() {
    const stories = await this.fetchStories('podcasts/')

    stories.forEach((item: any) => {
      try {
        const date = new Date(item.content.publication_date)
        
        const podcast: Podcast = {
          class: 'podcast',
          id: item.id,
          slug: '/' + item.full_slug,
          date,
          subtitle: item.content.subtitle,
          title: item.content.title,
          people: item.content.people,
          published: item.content.published,
          description: item.content.description,
          image: item.content.image,
          url: item.content.url.url,
          link_apple: item.content.link_apple.url,
          link_spotify: item.content.link_spotify.url,
          link_youtube: item.content.link_youtube.url,
          link_suissepodcasts: item.content.link_suissepodcasts.url,
          lastmod: item.published_at ?? item.created_at,
          translatedSlugs: getTranslatedSlugs(item)
        }
        console.log('added podcast', item.name)
        this.addToTimeline(date, podcast)
      } catch (e) {
        console.error(e)
      }
    })
    return this.timeline
  }
}

function getTranslatedSlugs(item: any) {
  return item.translated_slugs
    ? item.translated_slugs.map((item: any) => ({
        lang: item.lang,
        slug: item.path
      }))
    : []
}

export default DataFetcher
