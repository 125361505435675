import type { Offering, Id, PersonOnboarding, TimelineEventClasses, VertexType } from '../../../../types'
import { MycelModel } from '@/lib/MycelModel'
import type { Vertex } from '../../../../types'
import * as d3 from 'd3'
import FirestoreCache from '@/lib/Utils/FirestoreCache'
import { DeviceAbstract } from '@/lib/DeviceHandler/DeviceAbstract'
import type { BaseType } from 'd3'
import i18n from '@/i18n'
import { type Selection } from 'd3'
import { arrowRight, arrowLeft, buttonClose } from '@/assets/buttons'
import { isSafari } from '@/lib/Utils/Utils'
import { HtmlToSvg } from '@/lib/Utils/HtmlToSvg'
import RenderUtilities from '@/lib/Rendering/RenderUtilities'

export type RendererMethod = 'getHTML' | 'getClassTitle' | 'getSubTitle' | 'getHtmlWidthFactor'

export interface GatheredBubble {
  id: string
  x: number
  y: number
  radiusScale: number
}

interface ElementPosition {
  x: number
  y: number
  width: number
  height: number
}

const svgStringButtonAllDe = `
<svg width='20' height='20' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='60' height='60' rx='30' transform='matrix(-1 0 0 1 60 0)' fill='black' class='navigation-button'/>
    <path d='M22.972 37L22 34.228H16.978L15.988 37H13.234L18.094 24.076H21.082L25.906 37H22.972ZM19.516 27.064L17.752 32.05H21.244L19.516 27.064ZM29.7844 37H27.2644V24.076H29.7844V37ZM34.5481 37H32.0281V24.076H34.5481V37ZM40.9678 37.162C38.0878 37.162 36.1618 35.164 36.1618 32.158C36.1618 29.296 38.1598 27.208 40.9318 27.208C43.9738 27.208 45.9898 29.674 45.5398 32.806H38.7178C38.8798 34.372 39.6358 35.236 40.9138 35.236C42.0118 35.236 42.7498 34.696 43.0378 33.742H45.5218C44.9818 35.92 43.3078 37.162 40.9678 37.162ZM40.8778 29.044C39.7078 29.044 38.9698 29.8 38.7538 31.222H42.8938C42.8218 29.89 42.0658 29.044 40.8778 29.044Z' fill='white'/>
</svg>`
const svgStringButtonAllEn = `
<svg width='20' height='20' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='60' height='60' rx='30' transform='matrix(-1 0 0 1 60 0)' fill='black' class='navigation-button'/>
    <path d='M30.066 37L28.716 33.418H22.686L21.318 37H19.986L25.008 24.076H26.484L31.488 37H30.066ZM25.71 25.516L23.1 32.32H28.284L25.71 25.516ZM34.6085 37H33.3485V24.076H34.6085V37ZM38.5987 37H37.3387V24.076H38.5987V37Z' fill='white'/>
</svg>`

export abstract class AbstractSvgRenderer extends EventTarget {
  public currentCurrentZoomLevel: number | undefined
  public eventContentWidth: number

  protected mycelModel: MycelModel
  protected circleRadii: Record<VertexType, number>
  protected circleClickHandler: (event: any, d: Vertex) => void
  protected svgTimelineEvent: d3.Selection<SVGSVGElement, Vertex, SVGGElement, unknown> | undefined

  protected deviceHandler: DeviceAbstract

  protected readonly SVGWIDTH = 400 // width of the inner SVG element (viewbox)
  protected readonly MARGIN = 70 // offset from the edge of the SVG
  // In a bubble, we want the SVG to be larger than the circle, so that the content is not cut off.
  // The following factor defines how much larger the SVG is than the circle.
  protected readonly SVG_OVERLAP_FACTOR = 4 // Note that in some places you need to adjust the width of the SVG manually (currently 1200)

  public config = {
    hasHtml: true,
    hasSubtitle: false,
    hasClassTitle: true,
    filterCircleRadius: 1000,
    noArrowsOnSafari: true
  }

  constructor(
    mycelModel: MycelModel,
    currentCurrentZoomLevel: number | undefined,
    circleRadii: Record<VertexType, number>,
    circleClickHandler: (event: any, d: Vertex) => void,
    deviceHandler: DeviceAbstract
  ) {
    super()
    this.mycelModel = mycelModel
    this.currentCurrentZoomLevel = currentCurrentZoomLevel
    this.circleRadii = circleRadii
    this.circleClickHandler = circleClickHandler
    this.deviceHandler = deviceHandler

    this.eventContentWidth = this.circleRadii['event'] * 1.3
  }

  public abstract getClassName(): TimelineEventClasses

  public getCssClassName(d: Vertex) {
    return this.getClassName() as string
  }

  public abstract getHTML(event: any): string

  public abstract getSubTitle(event: any): string

  public abstract getHtmlWidthFactor(event: any): number

  public abstract render(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    isZooming?: boolean,
    isFiltering?: boolean
  ): void

  protected gatherElements(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    nodeData: GatheredBubble[],
    optimizationScale: number,
    targetRadius: number,
    vertices: Vertex[]
  ) {
    // possible optimization: If the circle is completely invisible, we can skip the transition
    // and move the circle immediately.
    nodeData.forEach((n) => {
      g?.selectAll<SVGCircleElement, Vertex>(`circle.event-${n.id}`)
        .raise()
        .transition()
        .duration(800)
        .attr('cx', n.x * optimizationScale)
        .attr('cy', n.y * optimizationScale)
        .attr('r', (d: Vertex) => this.filteredCircleRadiusScale(d) * targetRadius)
    })

    // splitting the two loops is necessary to avoid concurrency issues which causes the circles to be _above_ the buttons
    nodeData.forEach((n) => {
      // store the filtered position in the vertex
      const v = vertices.find((v) => v.timelineEvent?.id === n.id)
      if (v) {
        v.filteredPosition = {
          x: n.x * optimizationScale,
          y: n.y * optimizationScale
        }
        // update the position of the "svg" element within the circle containing the content
        g?.selectAll(`svg.event-${n.id}.event-group`)
          .raise()
          .transition()
          .duration(800)
          .attr('opacity', 1)
          .attr('x', n.x * optimizationScale - targetRadius)
          .attr('y', n.y * optimizationScale - targetRadius)
          .attr('width', targetRadius * 2 * this.SVG_OVERLAP_FACTOR)
          .attr('height', targetRadius * 2)

        // update any class specific elements
        this.updateElementOnFiltering(g, n, optimizationScale)
      }
    })

    this.updateElementsOnFiltering(g, nodeData, optimizationScale)
  }

  /**
   * Finds all timeline-events of this class and gather them on the center of the viewport.
   * There is a generic part and a class specific part. The generic part is implemented here, the class specific
   * part is implemented in the subclasses.
   * @param g
   * @param width
   * @param height
   */
  public async filter(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    width: number,
    height: number,
    onCompletionFn: () => void
  ) {
    const targetRadius =
      this.config.filterCircleRadius * this.deviceHandler.getConfig().filteredCircleScaleDownFactor
    //            radius for project|person      Correction for device

    const optimizationScale = 100 // necessary for the calculation to converge
    let nodeData: { id: string; x: number; y: number; radiusScale: number }[] = []

    // find all collaborators and add them to the nodeData array
    const vertices = this.mycelModel.getVerticesByClass(this.getClassName())
    vertices.forEach((p) => {
      if (!this.includeInGathering(p)) return
      if (p.timelineEvent === undefined) return
      nodeData.push({
        id: p.timelineEvent.id,
        x: p.x / optimizationScale,
        y: p.y / optimizationScale,
        radiusScale: this.filteredCircleRadiusScale(p)
      })
    })

    const cacheKey = FirestoreCache.generateKey(
      vertices.map((v) => v.timelineEvent?.id).sort(),
      targetRadius
    )
    const nodeDataCache = await this.getGatheringFromCache(
      cacheKey,
      width,
      height,
      optimizationScale
    )
    if (nodeDataCache) {
      nodeData = nodeDataCache
      onCompletionFn()
      this.gatherElements(g, nodeData, optimizationScale, targetRadius, vertices)
    } else {
      const simulation = d3
        .forceSimulation(nodeData)
        .force('center', d3.forceCenter(width / 2 / 100, height / 2 / 100))
        .force('charge', d3.forceManyBody().strength(5))
        .force(
          'collide',
          d3.forceCollide((d) => {
            return (targetRadius * d.radiusScale) / optimizationScale
          })
        )
        .alphaMin(0.00005)

      simulation.on('end', async () => {
        await this.storeGatheringToCache(cacheKey, nodeData, width, height, optimizationScale)
        onCompletionFn()
        this.gatherElements(g, nodeData, optimizationScale, targetRadius, vertices)
      })
    }
  }

  public resetFilter(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    const vertices = this.mycelModel.getVerticesByClass(this.getClassName())
    vertices.forEach((v) => {
      delete v.filteredPosition
    })
    //this.createCircle(this.g!, true)

    g?.selectAll(`circle.event.${this.getClassName()}`)
      .transition()
      .duration(500)
      // @ts-ignore
      .attr('cx', (vertex: Vertex) => vertex.x)
      // @ts-ignore
      .attr('cy', (vertex: Vertex) => vertex.y)
      // @ts-ignore
      .attr('r', (d: Vertex) => {
        return this.circleRadii[d.type]
      })
    //this.projectRenderer.resetPosition()

    // Here we rebind the data. I think this is a problem and will suddenly mess up the order of the elements.
    g.selectAll<SVGSVGElement, Vertex>(`svg.event.${this.getClassName()}`)
      .transition()
      .duration(500)
      // top left corner of bb of the circle
      .attr('x', (vertex: Vertex) => vertex.x - this.circleRadii['event'])
      .attr('y', (vertex: Vertex) => vertex.y - this.circleRadii['event'])
      .attr('width', this.circleRadii['event'] * 2 * this.SVG_OVERLAP_FACTOR)
      .attr('height', this.circleRadii['event'] * 2)
  }

  /**
   * The Zoom Handler for this class. This is called when the user zooms in or out.
   * @param g
   * @param zoomTweenRangeAdjustment
   * @param logicZoomLevel
   */
  public zoomHandler(
    g: Selection<SVGGElement, unknown, HTMLElement, undefined>,
    zoomTweenRangeAdjustment: number,
    logicZoomLevel: number,
    isFiltering: boolean
  ) {
    // not implemented
  }

  /**
   * This ZoomHandler is used in BlogRenderer and OfferingRenderer. It handles the case where we have a different
   * title image in the bubble for the zoomed-out view.
   * @param g
   * @param zoomTweenRangeAdjustment
   * @param logicZoomLevel
   * @param isFiltering
   * @protected
   */
  protected zoomedOutTitleZoomHandler(
    g: Selection<SVGGElement, unknown, HTMLElement, undefined>,
    zoomTweenRangeAdjustment: number,
    logicZoomLevel: number,
    isFiltering: boolean
  ) {
    const startLevel = isFiltering ? 4 : 10
    const endLevel = isFiltering ? 5 : 40

    const className = this.getClassName()

    RenderUtilities.zoomTween(
      g.selectAll(
        `.${className} .event-title-zoomed-out`
      ),
      'opacity',
      '1',
      '0',
      startLevel * zoomTweenRangeAdjustment,
      endLevel * zoomTweenRangeAdjustment,
      logicZoomLevel
    )
    RenderUtilities.zoomTween(
      g.selectAll(
        `.${className} .event-buttons, .${className} .event-line, .${className} .event-class, .${className} text.event-title, .${className} text.event-subtitle`
      ),
      'opacity',
      '0',
      '1',
      startLevel * zoomTweenRangeAdjustment,
      endLevel * zoomTweenRangeAdjustment,
      logicZoomLevel
    )
    RenderUtilities.zoomTween(
      g.selectAll(`.${className} foreignObject > div`),
      'style',
      'display:none',
      'display:block',
      startLevel * zoomTweenRangeAdjustment,
      40 * zoomTweenRangeAdjustment,
      logicZoomLevel
    )
  }

  protected createButtons(noAll: boolean = false) {
    this.addButtonNext(noAll ? 22 : 0)
    this.addButtonPrevious(noAll ? 22 : 0)
    if (!noAll) this.addButtonAll()
    this.addButtonClose()
  }

  private addButtonNext(offset: number = 0) {
    const buttonRight = this.svgTimelineEvent!.append('g')
      .attr('class', 'event event-buttons right')
      .attr('transform', (vertex: Vertex) => `translate(${266 + offset}, 60)`)
      .on('click', (event: any, d: Vertex) => {
        this.dispatchEvent(new CustomEvent('buttonNextClick', { detail: { destination: d } }))
      })

    if (isSafari() && this.config.noArrowsOnSafari) {
      return
    }
    this.addButtonFromString(buttonRight, arrowRight)
  }

  protected addButtonPrevious(offset: number = 0) {
    const buttonLeft = this.svgTimelineEvent!.append('g')
      .attr('class', 'event event-buttons left')
      .attr('transform', (vertex: Vertex) => `translate(${244 + offset}, 60)`)
      .on('click', (event: any, d: Vertex) => {
        this.dispatchEvent(new CustomEvent('buttonPrevClick', { detail: { destination: d } }))
      })

    if (isSafari() && this.config.noArrowsOnSafari) {
      return
    }
    this.addButtonFromString(buttonLeft, arrowLeft)
  }

  protected addButtonAll() {
    const svgString = i18n.global.locale === 'de' ? svgStringButtonAllDe : svgStringButtonAllEn
    const button = this.svgTimelineEvent!.append('g')
      .attr('class', 'event event-buttons all')
      .attr('transform', 'translate(288, 60)')
      .on('click', (event: any, d: Vertex) => {
        this.dispatchEvent(new CustomEvent('buttonAllClick', { detail: { destination: d } }))
      })

    this.addButtonFromString(button, svgString)
  }

  private addButtonClose(offset: number = 0) {
    const button = this.svgTimelineEvent!.append('g')
      .attr('class', 'event event-buttons close')
      .attr('transform', (vertex: Vertex) => `translate(310, 60)`)
      .on('click', (event: any, d: Vertex) => {
        this.dispatchEvent(new CustomEvent('buttonCloseClick'))
      })
    this.addButtonFromString(button, buttonClose)
  }

  /**
   * Adds a button to a vertex. The button is defined as a string in svg format.
   * @param selection
   * @param svgString
   * @private
   */
  protected addButtonFromString(
    selection: d3.Selection<SVGGElement, Vertex, SVGGElement, undefined>,
    svgString: string
  ) {
    selection.each((d, i, nodes) => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(svgString, 'image/svg+xml')
      ;(nodes![i] as SVGGElement).appendChild(doc.documentElement)
    })
  }

  protected abstract getClassTitleLabel(d: Vertex): string

  protected createGroupSvg(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    vertices: Vertex[]
  ) {
    this.svgTimelineEvent = g
      .selectAll<SVGSVGElement, Vertex>(`svg.event.${this.getClassName()}`)
      .data(vertices, (d: Vertex) => d.timelineEvent!.id!)
      .join('svg')
      // top left corner of bb of the circle
      .attr('x', (vertex: Vertex) => vertex.x - this.circleRadii['event'])
      .attr('y', (vertex: Vertex) => vertex.y - this.circleRadii['event'])
      .attr('width', this.circleRadii['event'] * 2 * this.SVG_OVERLAP_FACTOR)
      .attr('height', this.circleRadii['event'] * 2)
      .attr('viewBox', '0 0 1600 400')
      .attr(
        'class',
        (d) => `event event-group event-${d.timelineEvent?.id} ${this.getCssClassName(d)} `
      )
  }

  protected createClassTitleSvg() {
    this.svgTimelineEvent!.append('text')
      .attr('class', 'event event-class')
      .attr('x', (vertex: Vertex) => this.MARGIN)
      .attr('y', (vertex: Vertex) => this.MARGIN) // + 20
      .attr('text-anchor', 'left')
      .attr('dominant-baseline', 'middle')
      .text((d: Vertex) => i18n.global.t(this.getClassTitleLabel(d)))
  }

  protected createTitleSvg() {
    this.svgTimelineEvent!.append('text')
      .attr('class', 'event event-title')
      .attr('x', (vertex: Vertex) => this.MARGIN)
      .attr('y', (vertex: Vertex) => (this.config.hasClassTitle ? 99 : this.MARGIN))
      .attr('text-anchor', 'left')
      .attr('dominant-baseline', 'middle')
      .text((d: Vertex) => d.timelineEvent?.title || '')
  }

  protected createLineSvg() {
    this.svgTimelineEvent!.append('line')
      .attr('class', 'event event-line')
      .attr('x1', (vertex: Vertex) => this.MARGIN)
      .attr('y1', (vertex: Vertex) => this.MARGIN + 14)
      .attr('x2', (vertex: Vertex) => 400 - this.MARGIN)
      .attr('y2', (vertex: Vertex) => this.MARGIN + 14)
  }

  protected createSubtitleSvg() {
    // Timeline event (e.g. a Project)
    this.svgTimelineEvent!.append('text')
      .attr('class', 'event event-subtitle')
      .attr('x', (vertex: Vertex) => this.MARGIN)
      .attr('y', (vertex: Vertex) => this.MARGIN + 56)
      .attr('text-anchor', 'left')
      .attr('dominant-baseline', 'middle')
      .attr('font-size', '20px')
      .text(this.getSubTitle.bind(this))
  }

  protected textWrap(label: string, node: SVGTextElement, offsetX = 0, maxLineLength = 350){
    const removeHyphen = (text: string) => {
      //return text.replace(/\u00AD$/, '-').replace(/\u00AD/, '')
      return text.replace(/\u00AD(?!$)/g, '').replace(/\u00AD$/, '-')
    }
    // This is a text wrapping algorithm. It splits at spaces or soft hyphens which have to be
    // inserted manually in the Storyblok editor.
    // Note that there is a more sophisticated version of this in the HtmlToSvg class.
    // I did not join the two code parts because the HtmlToSvg class ist not able to center.
    // @ts-ignore
    const text = d3.select(node as any)
    const textHyphened = label
    if (!textHyphened) {
      return
    }
    let words = textHyphened.match(/[^\u00AD-\s]+([\u00AD-\s]+|$)/g)
    if (!words) {
      return
    }
    // @ts-ignore
    words = words.reverse()
    const lineHeight = 1.1 // ems
    const y = text.attr('y')
    const dy = 0
    let word
    let lineNumber = 0
    let line: string[] = []
    let tspan = text
      .text(null)
      .append('tspan')
      .attr('x', offsetX)
      .attr('y', y)
      .attr('dy', dy + 'em')

    while ((word = words!.pop())) {
      line.push(word)
      tspan.text(removeHyphen(line.join('')))
      // @ts-ignore
      if (tspan.node()?.getComputedTextLength() > maxLineLength) {
        line.pop()
        tspan.text(removeHyphen(line.join('')))
        line = [word]
        tspan = text
          .append('tspan')
          .attr('x', offsetX)
          .attr('y', y)
          .attr('dy', ++lineNumber * lineHeight + dy + 'em')
          .text(removeHyphen(word))
      }
    }
    return text
  }

  protected createZoomedOutTitle() {
    const that = this
    this.svgTimelineEvent!.append('text')
      .attr('class', 'event-title-zoomed-out')
      .attr('x', 200)
      .attr('y', 0)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .each(function (d: Vertex) {
        const text = that.textWrap((d.timelineEvent as Offering).title, this, 200, 350)
        // vertically center the text
        const bbox = text!.node()?.getBBox()
        const height = bbox?.height ?? 0
        text!.attr('transform', `translate(0, ${(400 - height) / 2 + 43})`)
      })
  }

  protected createHtmlSvg(withoutSubtitle = false, withoutTitle = false) {
    if (!this.svgTimelineEvent) return

    const TITLE_HEIGHT = 30

    const foreignObject = this.svgTimelineEvent
      .append('foreignObject')
      .attr('width', (d: Vertex) => {
        const widthFactor = this.getHtmlWidthFactor(d) as number
        return (400 - this.MARGIN * 2) * widthFactor
      })
      .attr('height', 300 + (withoutSubtitle ? 0 : 20) + (withoutTitle ? TITLE_HEIGHT : 0))
      .attr('x', (vertex: Vertex) => this.MARGIN)
      .attr(
        'y',
        (vertex: Vertex) =>
          this.MARGIN + 48 + (withoutSubtitle ? 0 : 20) - (withoutTitle ? TITLE_HEIGHT : 0)
      )

    const div = foreignObject.append('xhtml:div')

    div.attr('class', 'event-body scrollable').html(this.getHTML.bind(this))
  }

  public async createSvgContent_(
    svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, undefined>
  ) {
    //this.createSvgContent(svg, this.config.hasSubtitle)
  }

  private createSvgContent(
    svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, undefined>,
    withoutSubtitle: boolean
  ) {
    const that = this
    if (!this.svgTimelineEvent) return
    this.svgTimelineEvent.each(function (d: Vertex) {
      if (!d.timelineEvent?.description) return

      if (!that.bubbleContentShouldBeShown(svg, d)) return

      const containerTopEdge = that.MARGIN + (withoutSubtitle ? 54 : 74)
      const viewportHeight = 400 - containerTopEdge - that.MARGIN

      d3.select(this)
        .append('defs')
        .append('clipPath')
        .attr('id', `bubbleContentClip-${d.timelineEvent?.id}`)
        .append('rect')
        .attr('x', 0)
        .attr('y', containerTopEdge)
        .attr('width', 400)
        .attr('height', viewportHeight)

      const parentG = d3
        .select(this)
        .append('g')
        .attr('x', that.MARGIN)
        .attr('y', containerTopEdge)
        .classed('scrollable', true)
        .classed('bubble-content', true)
        .attr('clip-path', `url(#bubbleContentClip-${d.timelineEvent?.id})`)

      const g = parentG.append('g')
      const widthFactor = that.getHtmlWidthFactor(d) as number
      const width = (400 - that.MARGIN * 2) * widthFactor
      const htmlToSvg = new HtmlToSvg(g, 1.1, that.MARGIN, containerTopEdge, width)

      const contentHeight = htmlToSvg.render(d.timelineEvent.description)

      const btnUp = d3
        .select(this)
        .append('g')
        .attr('class', 'event-buttons')
        .attr('transform', `translate(${400 - that.MARGIN}, ${containerTopEdge + 20}) rotate(-90)`)
      that.addButtonFromString(
        btnUp as unknown as d3.Selection<SVGGElement, Vertex, SVGGElement, undefined>,
        arrowRight
      )

      btnUp.on('click', (event: any, d: unknown) => {
        that.incrementSSVGTranslateAttr(g, 0, -200)
        updateButtonState()
      })

      const btnDown = d3
        .select(this)
        .append('g')
        .attr('class', 'event-buttons disabled')
        .attr(
          'transform',
          `translate(${400 - that.MARGIN + 20}, ${containerTopEdge + 42}) rotate(90)`
        )
      that.addButtonFromString(
        btnDown as unknown as d3.Selection<SVGGElement, Vertex, SVGGElement, undefined>,
        arrowRight
      )

      btnDown.on('click', (event: any, d: unknown) => {
        that.incrementSSVGTranslateAttr(g, 0, 200)
        updateButtonState()
      })

      const updateButtonState = () => {
        const currentTranslate = that.getCurrentTranslate(g.attr('transform'))
        btnDown.classed('disabled', currentTranslate.translateY >= 0)
        btnUp.classed('disabled', currentTranslate.translateY - viewportHeight <= -contentHeight)
      }
    })
  }

  private bubbleContentShouldBeShown(
    svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, undefined>,
    d: Vertex
  ) {
    // Is the bubble-content already rendered?
    if (d3.select(`.event-${d.timelineEvent?.id} .bubble-content`).size() > 0) {
      return false
    }

    // Is the bubble visible (in the viewport)
    const pos = this.getPositionOfElement(svg, d3.select(`circle.event-${d.timelineEvent?.id}`))

    if (!this.isElementLargeEnough(pos)) {
      return false
    }

    if (!this.isElementVisible(pos)) {
      return false
    }
    return true
  }

  private getPositionOfElement(
    svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, undefined>,
    d3Element: d3.Selection<SVGCircleElement, unknown, HTMLElement, undefined>
  ): ElementPosition {
    const x = parseInt(d3Element.attr('cx')) || 0
    const y = parseInt(d3Element.attr('cy')) || 0

    const transform = d3.zoomTransform(svg.node()!)

    const transformedX = transform.applyX(x)
    const transformedY = transform.applyY(y)

    // Position of the SVG element in the document
    const elmBox = d3Element.node()!.getBoundingClientRect()

    // Calculation of the final position in the browser viewport
    return {
      x: transformedX,
      y: transformedY,
      width: elmBox.width,
      height: elmBox.height
    }
  }

  /**
   * Returns true if the element is visible in the browser viewport.
   * @param pos
   * @private
   */
  private isElementVisible(pos: ElementPosition) {
    return (
      pos.x + pos.width > 0 &&
      pos.x < window.innerWidth &&
      pos.y + pos.height > 0 &&
      pos.y < window.innerHeight
    )
  }

  /**
   * Returns true if the element is large enough so that rendering is worth it
   * @param pos
   * @private
   */
  private isElementLargeEnough(pos: ElementPosition) {
    return pos.width > 200 && pos.height > 200
  }

  private incrementSSVGTranslateAttr(
    selection: d3.Selection<SVGGElement, unknown, null, undefined>,
    xOffset: number,
    yOffset: number
  ): void {
    const that = this
    selection.each(function () {
      const currentTransform: string = d3.select(this).attr('transform')

      let { translateX, translateY } = that.getCurrentTranslate(currentTransform)

      translateX += xOffset
      translateY += yOffset

      d3.select(this).attr('transform', `translate(${translateX}, ${translateY})`)
    })
  }

  private getCurrentTranslate(currentTransform: string) {
    let translateX: number = 0
    let translateY: number = 0

    if (currentTransform) {
      const translateMatch: RegExpExecArray | null = /translate\(([^,]+),\s*([^)]+)\)/.exec(
        currentTransform
      )
      if (translateMatch) {
        translateX = parseFloat(translateMatch[1])
        translateY = parseFloat(translateMatch[2])
      }
    }
    return { translateX, translateY }
  }

  private setButtonPosition(
    gButton: d3.Selection<SVGGElement | BaseType, Vertex, SVGGElement, unknown>,
    vertex: Vertex,
    position: number
  ) {
    gButton.attr('transform', `translate(${400 - position * 11},50)`)
  }

  /**
   * Allows to provide an exclusion rule to exclude some items from the gathering. It's used for
   * the team members who are not active anymore
   * @param vertex
   * @protected
   */
  protected includeInGathering(vertex: Vertex) {
    return true
  }

  /**
   * If during filtering there are elements which need to be updated (like the person photo in the PeopleRenderer)
   * this function can be overwritten
   *
   * @param g
   * @param targetPosition
   * @param optimizationScale
   * @protected
   */
  protected updateElementOnFiltering(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    targetPosition: { id: string; x: number; y: number },
    optimizationScale: number
  ) {}

  /**
   * In contrast to the function above, this function is called only once, when the filter is applied.
   * @param g
   * @protected
   */
  protected updateElementsOnFiltering(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    nodeData: GatheredBubble[],
    optimizationScale: number
  ) {}

  /**
   * Returns the scale which should be applied to a filtered circle. This is used to make the
   * circle of the management team members larger
   * @param d
   * @protected
   */
  protected filteredCircleRadiusScale(d: Vertex) {
    return 1
  }

  /**
   * Stores the gathering to the cache but moves it first to the 0/0 point so that later we can easily center it to the screen
   * @param cacheKey
   * @param nodeData
   * @param width
   * @param height
   * @private
   */
  private async storeGatheringToCache(
    cacheKey: string,
    nodeData: GatheredBubble[],
    width: number,
    height: number,
    optimizationScale: number
  ) {
    // we move the nodes so that the center of the gathering is at 0/0
    const nodeDataTranslated = nodeData.map((n) => {
      return {
        id: n.id,
        x: n.x - width / optimizationScale / 2,
        y: n.y - height / optimizationScale / 2
      }
    })
    await FirestoreCache.set(cacheKey, nodeDataTranslated)
  }

  /**
   * Retrieves the gathering from the cache and moves it back to the center of the screen
   * @param cacheKey
   * @param width
   * @param height
   * @private
   */
  private async getGatheringFromCache(
    cacheKey: string,
    width: number,
    height: number,
    optimizationScale: number
  ) {
    const nodeDataCache = await FirestoreCache.get(cacheKey)

    if (nodeDataCache != undefined) {
      return nodeDataCache.map((n: GatheredBubble) => {
        return {
          id: n.id,
          x: n.x + width / optimizationScale / 2,
          y: n.y + height / optimizationScale / 2
        }
      })
    }
  }
}
