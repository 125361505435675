import type { Vertex, VertexType } from '../../types'

export interface ForceVector {
  x: number
  y: number
}

const chargeByType: { [K in VertexType]: number } = {
  node: 0,
  'timeline-connector': 1,
  'timeline-year': 4,
  'event-connector': 0,
  event: 1,
  'welcomevertex': 1,
  'freefloat': 1,
  'freefloat-connector': 0,
  'freefloat-parent': 1
}

export function distance(v1: Vertex, v2: Vertex): number {
  return Math.sqrt((v1.x - v2.x) ** 2 + (v1.y - v2.y) ** 2)
}

export function getForceVector(target: Vertex, attractor: Vertex): ForceVector {
  const r = distance(target, attractor)
  const forceMagnitude = (1 / (r * r)) * chargeByType[attractor.type]

  // Calculate the unit direction vector from the target to the attractor
  const unitDirection = {
    x: (attractor.x - target.x) / r,
    y: (attractor.y - target.y) / r
  }

  return {
    x: forceMagnitude * unitDirection.x,
    y: forceMagnitude * unitDirection.y
  }
}

export function getTotalForce(targetVertex: Vertex, attractors: Vertex[] = []): ForceVector {
  let totalForceX = 0
  let totalForceY = 0

  for (const attractor of attractors) {
    const force = getForceVector(targetVertex, attractor)
    totalForceX += force.x
    totalForceY += force.y
  }

  return { x: totalForceX, y: totalForceY }
}
