export const arrowRight = `<svg width='20' height='20' viewBox='0 0 60 60' class='navigation-button'
           fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='60' height='60' rx='30' transform='matrix(-1 0 0 1 60 0)' fill='black' />
            <path d='M19 30H41' stroke='white'/>
            <path d='M29.5 18.5L41 30L29.5 41.5' stroke='white'/>
          </svg>`

export const arrowLeft = `
          <svg width='20' height='20' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'  class='navigation-button'>
            <rect width='60' height='60' rx='30' transform='matrix(-1 0 0 1 60 0)' fill='black'/>
            <path d='M19 30H41' stroke='white'/>
            <path d='M30.5 18.5L19 30L30.5 41.5' stroke='white'/>
            </svg>
            `

export const buttonClose = `
        <svg width='20' height='20' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg' class='navigation-button' >
        <rect width='44' height='44' rx='22' transform='matrix(-1 0 0 1 44 0)' fill='black' />
        <path d='M12.4173 12.4166L22.0007 22L31.584 31.5833' stroke='white'/>
        <path d='M31.5827 12.4166L21.9993 22L12.416 31.5833' stroke='white'/>
        </svg>`
