import * as d3 from 'd3'

/**
 * This class is used to render HTML text into SVG. But in the project it's not used
 * because the reason that it did not render in iPad was the lack of support for
 * negative look-behind.
 */

interface StyleRule {
  fontSize: string
  lineHeight: number
  marginTop: number
  class: string[]
}

type Tags = 'p' | 'h1' | 'h2' | 'h3' | 'ul' | 'li' | 'ol'

const styles: Record<Tags, StyleRule> = {
  p: {
    fontSize: '6px',
    lineHeight: 1.5,
    marginTop: 12,
    class: []
  },
  h1: {
    fontSize: '12px',
    lineHeight: 1.5,
    marginTop: 18,
    class: ['bold']
  },
  h2: {
    fontSize: '10px',
    lineHeight: 1.5,
    marginTop: 24,
    class: ['bold']
  },
  h3: {
    fontSize: '9px',
    lineHeight: 1.2,
    marginTop: 24,
    class: ['bold']
  },
  ul: {
    fontSize: '6px',
    lineHeight: 1.2,
    marginTop: 0,
    class: []
  },
  ol: {
    fontSize: '6px',
    lineHeight: 1.5,
    marginTop: 0,
    class: []
  },
  li: {
    fontSize: '6px',
    lineHeight: 1.5,
    marginTop: 8,
    class: []
  }
}

export class HtmlToSvg {
  private readonly lineHeight: number
  private readonly maxWidth: number

  private isBold = false
  private isItalic = false
  private isUl = false
  private currentNodeName: Tags = 'p'

  private readonly x: number = 0 // left margin
  private readonly y: number = 0 // top margin
  private currentX: number = 0 // offset from left margin
  private currentY: number = 0 // offset from top margin
  private g: d3.Selection<SVGGElement, unknown, HTMLElement | null, undefined>

  constructor(
    g: d3.Selection<SVGGElement, unknown, HTMLElement | null, undefined>,
    lineHeight: number = 1.1,
    x: number,
    y: number,
    maxWidth: number = 350
  ) {
    if (!g) throw new Error('g is undefined')
    this.g = g
    this.x = x
    this.y = y
    this.lineHeight = lineHeight
    this.maxWidth = maxWidth
  }

  /**
   *
   * @param html
   * @returns the height of the rendered text
   */
  public render(html: string) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    if (doc.body.firstChild) this.walk(doc.body.firstChild)
    return this.currentY
  }

  // walk the tree and render the text elements. Inline elements are treated separately
  private walk(node: Node | null) {
    // First we go through the block element. And then we render the text elements.
    while (node) {
      if (node && this.isBlockElement(node)) {
        this.currentNodeName = node.nodeName.toLowerCase() as Tags
        this.currentY += styles[this.currentNodeName]?.marginTop ?? 0
        // Indent for ul and ol
        switch (node.nodeName.toLowerCase()) {
          case 'ul':
          case 'ol':
            this.currentX += 20 // indent
            this.isUl = true
            break
        }
        const nextNonEmptyChild = this.getFirstNonEmptyChild(node)
        if (nextNonEmptyChild && this.isBlockElement(nextNonEmptyChild)) {
          this.walk(node.firstChild)
        } else {
          this.renderInlineElements(node)
        }

        switch (node.nodeName.toLowerCase()) {
          case 'ul':
          case 'ol':
            this.currentX -= 20
            this.isUl = false
            break
        }
        // this.currentY += this.lineHeight
        this.currentY = this.g.node()?.getBBox()?.height ?? 0
      }
      node = node.nextSibling
    }
  }

  /**
   * Necessary in <ul><li> structures.
   * @param node
   * @private
   */
  private getFirstNonEmptyChild(node: Node) {
    let child = node.firstChild
    while (child && child.textContent === '') {
      child = child.nextSibling
    }
    return child
  }

  private isBlockElement(node: Node) {
    return ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'li', 'ol'].includes(
      node.nodeName.toLowerCase()
    )
  }

  private renderInlineElements(node: Node) {
    const words = this.convertNodeToSvg(node)
    const that = this

    this.g
      .append('text')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'hanging')
      .attr('font-size', styles[this.currentNodeName]?.fontSize)
      .attr('class', styles[this.currentNodeName]?.class.join(' '))
      .each(function (d: any) {
        // @ts-ignore
        const text = d3.select(this) as d3.Selection<
          SVGTextElement,
          unknown,
          HTMLElement,
          undefined
        >
        that.wrapAndHyphenateInlineText(text, words)
      })
  }

  private convertNodeToSvg(node: Node) {
    const words: string[] = []

    // Text node
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent) {
        words.push(...this.splitToWords(node.textContent))
      }
    }
    // Element node
    else if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement

      switch (element.tagName.toLowerCase()) {
        case 'br':
          words.push('<br>')
          break
        case 'b':
        case 'strong':
          words.push('<b>', ...this.convertChildrenToSvg(element), '</b>')
          break
        case 'i':
        case 'em':
          words.push('<i>', ...this.convertChildrenToSvg(element), '</i>')
          break
        default:
          // Default case for unrecognized tags
          words.push(...this.convertChildrenToSvg(element))
      }
    }
    return words
  }

  private convertChildrenToSvg(node: Node): string[] {
    const words: string[] = []
    node.childNodes.forEach((child) => {
      words.push(...this.convertNodeToSvg(child))
    })
    return words
  }

  private splitToWords(text: string): string[] {
    return text.match(/[^\u00AD-\s]+[\u00AD-\s]+/g) as string[];
  }

  protected wrapAndHyphenateInlineText(
    textElement: d3.Selection<SVGTextElement, unknown, HTMLElement, undefined>,
    words: string[]
  ): void {
    const rWords = words.reverse()
    const lineHeight = styles[this.currentNodeName]?.lineHeight ?? 1.1
    let word
    let lineNumber = 0
    let line: string[] = []

    if (this.isUl && lineNumber === 0) {
      const tspanElm = textElement.append('tspan')

      tspanElm
        .attr('x', this.x + this.currentX)
        .attr('y', this.y + this.currentY)
        .attr('dx', '-1em')
        .attr('dy', 0)
        .text('•')
    }

    // beginning of a new inline text block
    let tspan = textElement
      .append('tspan')
      .attr('x', this.x + this.currentX)
      .attr('y', this.y + this.currentY)
      .attr('dy', lineNumber * lineHeight + 'em')

    let startNewTspan = false
    let startNewLine = false
    let xOffsetWithinLine = 0
    while ((word = rWords.pop())) {
      // Do we have state change? (e.g. bold, italic, etc.)
      switch (word) {
        case '<br>':
          startNewLine = true
          continue
        case '<b>':
          this.isBold = true
          startNewTspan = true
          continue
        case '</b>':
          this.isBold = false
          startNewTspan = true
          continue
        case '</i>':
          this.isItalic = false
          startNewTspan = true
          continue
        case '<i>':
          this.isItalic = true
          startNewTspan = true
          continue
      }

      line.push(word)

      if (startNewTspan) {
        xOffsetWithinLine += tspan.node()?.getComputedTextLength() ?? 0
        tspan = textElement
          .append('tspan')
          .attr('x', this.x + this.currentX + xOffsetWithinLine)
          .attr('y', this.y + this.currentY)
          .attr('class', this.isBold ? 'bold' : '')
          .attr('dy', lineNumber * lineHeight + 'em')
        line = [word]
        startNewTspan = false
      }

      if (startNewLine) {
        xOffsetWithinLine = 0
      }

      // update a tspan until it's too long or if a line break is requested
      tspan.text(this.removeHyphen(line.join('')))
      // @ts-ignore
      if (
        this.currentX + xOffsetWithinLine + (tspan.node()?.getComputedTextLength() ?? 0) > this.maxWidth ||
        startNewLine
      ) {
        line.pop()
        tspan.text(this.removeHyphen(line.join('')))
        line = [word]
        xOffsetWithinLine = 0
        tspan = textElement
          .append('tspan')
          .attr('x', this.x + this.currentX + xOffsetWithinLine)
          .attr('y', this.y + this.currentY)
          .attr('dy', ++lineNumber * lineHeight + 'em')
          .text(this.removeHyphen(word))
        startNewLine = false
      }
    }
  }

  private removeHyphen = (text: string) => {
    return text.replace(/\u00AD(?!$)/g, '').replace(/\u00AD$/, '-')
  }
}
