import * as d3 from 'd3'
import { DeviceAbstract } from '@/lib/DeviceHandler/DeviceAbstract'
import { appendForm, removeForm } from '@/formApp'
import { useContactFormStore } from '@/store'
import { getRouter } from '@/router'

export class ContactFormBubble {
  private readonly svg: d3.Selection<any, unknown, HTMLElement, undefined>
  private readonly width: number
  private readonly height: number
  private readonly deviceHandler: DeviceAbstract
  private unsubscribeFormStore: Function = () => {}

  private overlay: d3.Selection<SVGRectElement, unknown, HTMLElement, undefined> | undefined
  private gCircle: d3.Selection<SVGGElement, unknown, HTMLElement, undefined> | undefined
  private html: d3.Selection<any, unknown, HTMLElement, undefined> | undefined
  private isVisible: boolean

  constructor(
    svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, undefined>,
    deviceHandler: DeviceAbstract
  ) {
    this.svg = svg
    this.width = parseInt(svg.attr('width'))
    this.height = parseInt(svg.attr('height'))

    this.deviceHandler = deviceHandler
    this.isVisible = false
  }

  public run() {
    this.animateCircle()
  }

  public getIsVisible() {
    return this.isVisible
  }

  protected animateCircle() {
    const circleRadius = 500

    this.isVisible = true

    const deviceAdjustmentFactor = this.deviceHandler.getConfig().filteredCircleScaleDownFactor

    const g = this.svg.select('.graph-container')

    this.overlay = g
      .append('rect')
      .raise()
      .attr('id', 'contact-filter-overlay')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('fill', 'white')
      .style('opacity', 0)

    this.overlay.transition().duration(2000).style('opacity', 0.8)

    this.gCircle = g.append('g')

    this.gCircle
      .append('circle')
      .attr('cx', this.width / 2)
      .attr('cy', this.height / 2)
      .attr('r', 0)
      .transition()
      .duration(1000)
      .attr('r', circleRadius * deviceAdjustmentFactor)
    const formW = ((circleRadius * 2) / Math.sqrt(2)) * 0.9
    this.html = this.gCircle
      .append('svg')

      .attr('height', formW)
      .attr('width', formW)

    const formContainer = this.html
      .append('foreignObject')
      .attr('height', formW)
      .attr('width', formW)
      .attr('transform', `scale(${deviceAdjustmentFactor})`)

    this.html
      .attr('opacity', 0)
      .attr('x', this.width / 2 - circleRadius * deviceAdjustmentFactor * 0.65)
      .attr('y', this.height / 2 - circleRadius * deviceAdjustmentFactor * 0.65)

    formContainer.append('xhtml:div').attr('id', 'contact-form-wrapper').classed('h-full', true)

    // init form application
    // subscribe to app callbacks
    appendForm('contact-form-wrapper')

    this.unsubscribeFormStore = useContactFormStore().$onAction(
      ({
        name // name of the action
      }) => {
        if (name === 'closeForm') {
          this.collapseCircle()
          getRouter().push('/all')
        }
      }
    )

    // Transition
    this.html.transition().delay(1000).duration(1000).attr('opacity', 1)
  }

  public collapseCircle() {
    this?.unsubscribeFormStore()
    window.setTimeout(() => {
      removeForm()
    }, 1000)
    if (!this.gCircle || !this.overlay || !this.html) {
      return
    }
    this.overlay.transition().duration(1000).style('opacity', 0).remove()
    this.html.transition().duration(1000).attr('opacity', '0').remove()
    this.gCircle.selectAll('circle').transition().delay(1000).duration(1000).attr('r', 0).remove()
    this.isVisible = false
  }
}
