import * as d3 from 'd3'
import type { SerializableModel, DeviceConfig } from '../../../types'

/**
 * Handles all device type specific logic and data.
 */
export abstract class DeviceAbstract {
  public abstract getConfig(): DeviceConfig

  public abstract getInitialZoom(): number

  public abstract getPrerenderedData(): SerializableModel

  /**
   * Returns the aspect ratio to be used in Vertex generation.
   * @param width
   * @param height
   */
  public abstract getAspectRatioForVertexGeneration(width: number, height: number): number

  /**
   * Which ratio of the screen should be allowed to be panned outside of the SVG.
   */
  public abstract getOverflowRatio(): number

  /**
   * Returns the translate extent (area the user is allowed to pan) for the zoom handler.
   * @param width of viewport
   * @param height of viewport
   */
  public abstract getZoomHandlerTranslateExtent(
    width: number,
    height: number
  ): [[number, number], [number, number]]

  /**
   * Returns the transform for the zoom handler to zoom to all elements. (fully zoomed out)
   * @param width
   * @param height
   * @param scaleMultiplier
   */
  public abstract getTransformForZoomAll(
    width: number,
    height: number
  ): d3.ZoomTransform

  /**
   * Returns the transform for the zoom handler to zoom on open.
   * @param width
   * @param height
   * @param scaleMultiplier
   */
  public abstract getTransformForZoomOnOpen(
    width: number,
    height: number
  ): d3.ZoomTransform
}
