/*
Applies a weight to the edges to make it look like the example from Partner&Partner. The approach is to
start at one edge and then follow one edge at a time until we reach the end of the graph. The direction
should be maintained as much as possible.

1. find a point at the edge.
2. Then we define the direction.
3. Then we follwow that direction until we reach the end of the graph.
 */

import type { Edge, EdgeWeight, Point, Vertex } from 'types'
import type { MycelModel } from '@/lib/MycelModel'
import GraphTools from '@/lib/GraphTools'
import type { DeviceAbstract } from '@/lib/DeviceHandler/DeviceAbstract'

/**
 * This class is responsible for making some of the lines thicker for cosmetic purposes.
 */
export default class EdgeWeightAdjuster {
  private height: number
  private width: number
  private mycelModel: MycelModel
  private deviceHandler: DeviceAbstract

  constructor(
    mycelModel: MycelModel,
    height: number,
    width: number,
    deviceHandler: DeviceAbstract
  ) {
    this.mycelModel = mycelModel
    this.height = height
    this.width = width
    this.deviceHandler = deviceHandler
  }

  protected findRandomEdges(edges: Edge[], numberOfEdges: number): Edge[] {
    const randomEdges: Edge[] = []
    const numberOfEdgesToFind = Math.min(numberOfEdges, edges.length)
    for (let i = 0; i < numberOfEdgesToFind; i++) {
      const randomEdge = edges[Math.floor(GraphTools.random() * edges.length)]
      randomEdges.push(randomEdge)
    }
    return randomEdges
  }

  protected findEdgeWithPointAtEdge(edges: Edge[]): [Edge, Vertex] {
    const edgeWithPointAtEdge = edges.find((edge) => {
      const source = edge.source
      const target = edge.target
      return source.y < 10 || target.y < 10
    })
    if (edgeWithPointAtEdge === undefined) {
      throw new Error('Could not find edge with point at edge')
    }
    return [
      edgeWithPointAtEdge,
      edgeWithPointAtEdge.source.y === 0 ? edgeWithPointAtEdge.source : edgeWithPointAtEdge.target
    ]
  }

  /**
   * Finds a path of edges that follows the angle of the first edge.
   * @param edges
   * @param edge The edge to start with
   * @param start The start point of the edge
   */
  public findEdgePathFollowingAngle(edges: Edge[], edge: Edge, start: Vertex, margin = 0): Edge[] {
    // use the edge, then find the next edge by trying to make the edge as straight
    // as possible
    let currentVertex = start
    let pathIsCompleted = false
    const edgePath: Edge[] = []
    let currentEdge = edge
    let otherPoint = GraphTools.getOtherEndpoint(edge, start)
    let connectedEdges = GraphTools.findConnectedEdges(edges, otherPoint, true, edgePath)
    edgePath.push(currentEdge)
    let counter = 0
    const minimalPathLength = 1
    const maximumPathLength = 200

    while (!pathIsCompleted && connectedEdges.length > 0) {
      currentVertex = otherPoint

      // Find the edge which is most straight
      const candidateEdges = GraphTools.rateConnectedEdgeByLeastCurvature(edge, connectedEdges)
      currentEdge = candidateEdges.sort((a, b) => (a.rating > b.rating ? 1 : -1))[0].edge
      otherPoint = GraphTools.getOtherEndpoint(currentEdge, currentVertex)
      currentEdge.debugdata = counter.toString()
      counter++
      edgePath.push(currentEdge)


      connectedEdges = GraphTools.findConnectedEdges(edges, otherPoint, true, edgePath)
      edge = currentEdge

      pathIsCompleted = this.pointIsAtCanvasBorder(otherPoint, margin)

      if (edgePath.length < minimalPathLength) {
        pathIsCompleted = false
      }
      if (edgePath.length > maximumPathLength) {
        pathIsCompleted = true
      }
    }

    return edgePath
  }

  protected pointIsAtCanvasBorder(point: Point, margin: number): boolean {
    return (
      point.x < margin ||
      point.x > this.width - margin ||
      point.y < margin ||
      point.y > this.height - margin
    )
  }

  protected getEdgePoints(numberOfPoints: number) {
    const points: { x: number; y: number }[] = []
    for (let i = 0; i < numberOfPoints; i++) {
      points.push({ x: GraphTools.random() * this.width, y: GraphTools.random() * this.height })
    }
    return points
  }

  public run() {
    const edges = this.mycelModel.edges
    // find random edges and set the weight to heavy
    //const [anEdge, Vertex] = this.findEdgeWithPointAtEdge(edges);

    const edgesPerWeight: Record<EdgeWeight, number> = {
      light: 0,
      medium: 80,
      heavy: 20
    }

    Object.keys(edgesPerWeight).forEach((edgeWeight) => {
      const edgeWeightTyped = edgeWeight as EdgeWeight
      const numberOfPoints = edgesPerWeight[edgeWeightTyped]
      this.getEdgePoints(numberOfPoints).forEach((point) => {
        const anEdge = GraphTools.findClosestEdge(edges, point)
        if (anEdge) {
          const Vertex = anEdge.source

          const edgePathRight = this.findEdgePathFollowingAngle(edges, anEdge, anEdge.target, 500)
          const edgePathLeft = this.findEdgePathFollowingAngle(
            edges,
            GraphTools.reverse(anEdge),
            Vertex,
            500
          )

          edgePathLeft.concat(edgePathRight).forEach((edge) => {
            edge.weight = edgeWeightTyped
            edge.source.weight = edgeWeightTyped
            edge.target.weight = edgeWeightTyped
          })
        }
        if (!anEdge) {
          throw new Error('Could not find an edge')
        }
      })
    })
  }
}
