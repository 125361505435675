<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import type {
  ChatMessageHistoryItem,
  ChatMessageType,
  TransportChatMessage,
  InitialTransportChatMessage,
  TypingIndicatorMessage,
  UserPresenceMessage
} from '../../types'
import ChatMessage from '@/components/chat/ChatMessage.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import i18n from '@/i18n'
import PresenceIndicator from '@/components/chat/PresenceIndicator.vue'
import { logEvent } from 'firebase/analytics'
import { analytics } from '@/firebase/init'

let socket: WebSocket | null = null

const messages = ref<ChatMessageType[]>([])
const messageInput = ref<string>('')
const websocketWatcherEnabled = ref<boolean>(false)
const messageInputRef = ref<HTMLInputElement>()
const messagesRef = ref<HTMLInputElement>()
const userId = ref<string>('')
const staffIsOnline = ref<boolean>(false)
const isOnline = ref(navigator.onLine)
const typingIndicator = ref(false)
const lastMessageTimestamp = ref<string>('')
const firstMessageContent = ref<string>('')

// get current language

function handleException() {
  //
}

defineProps({
  isInContainer: {
    type: Boolean,
    default: false
  }
})

function addEventListeners(): void {
  if (socket) {
    socket.addEventListener('open', () => {
      console.log('Connected to the WebSocket chatserver')
      isOnline.value = true
      sendFirstMessage()
    })

    socket.addEventListener('message', (event: MessageEvent<string>) => {
      let currentMessage: ChatMessageType

      const data: ChatMessageHistoryItem[] | TypingIndicatorMessage | UserPresenceMessage =
        JSON.parse(event.data)

      // Handle non-message events
      if ('type' in data) {
        switch (data.type) {
          case 'typingIndicator':
            typingIndicator.value = data.enabled
            return
          case 'userPresence':
            staffIsOnline.value = data.isOnline
            return
        }
      }

      (data as ChatMessageHistoryItem[]).forEach((message: ChatMessageHistoryItem, ix: number) => {
        currentMessage = {
          text: message.content,
          userId: message.userId,
          isMine: message.role === 'user',
          isPending: false,
          user: message.user ?? null,
          timestamp: message.timestamp
        }

        // Avoids duplicate messages after reconnecting when only a single message is shown
        if (firstMessageContent.value !== '' && currentMessage.text==firstMessageContent.value) {
          return
        }
        firstMessageContent.value = currentMessage.text

        messages.value.push(currentMessage)
      })
      lastMessageTimestamp.value = new Date().toISOString()
      console.log(`Received message: ${event.data}`)
    })

    socket.addEventListener('close', () => {
      if (websocketWatcherEnabled.value) {
        console.log('Disconnected from the WebSocket chatserver')
        handleException()
        setTimeout(() => initWebSocket(), 1000)
      }
      isOnline.value = false
    })

    socket.addEventListener('error', (error) => {
      console.error(`WebSocket error: ${error}`)
      handleException()
      isOnline.value = false
    })
  }
}

// This first message is needed to initialize the connection and
// to trigger sending instructions to ChatGPT
function sendFirstMessage(): void {
  if (socket) {
    socket.send(
      JSON.stringify({
        userId: userId.value,
        locale: i18n.global.locale,
        lastMessageTimestamp: lastMessageTimestamp.value
      } as InitialTransportChatMessage)
    )
    messageInput.value = ''
  }
}

function sendMessage(): void {
  if (socket) {
    socket.send(
      JSON.stringify({
        userId: userId.value,
        message: messageInput.value
      } as TransportChatMessage)
    )

    // add 5 seconds to the current date and time and change to ISO string
    // @todo: Assign the timestamp from the server
    lastMessageTimestamp.value = new Date(Date.now() + 5000).toISOString()

    messages.value.push({
      userId: null,
      text: messageInput.value,
      isMine: true,
      isPending: false,
      timestamp: new Date().toISOString()
    })
    messageInput.value = ''
    if (messageInputRef.value) {
      messageInputRef.value.focus()
    }
    if (messagesRef.value) {
      messagesRef.value.scrollTop = 0
    }

    logEvent(analytics, 'chat_message', {
      index: messages.value.length
    })
  }
}

function initWebSocket(): void {
  socket = new WebSocket(import.meta.env.VITE_BACKEND_URL)
  websocketWatcherEnabled.value = true
  addEventListeners()
}

function getUserId() {
  const userId = localStorage.getItem('userId')
  if (userId) {
    return userId
  } else {
    const newUserId = Math.random().toString(36).substring(7)
    localStorage.setItem('userId', newUserId)
    return newUserId
  }
}

function isNewDay(message: ChatMessageType, index: number): boolean {
  if (index === messages.value.length) return true
  const prevMessage = messages.value.slice().reverse()[index + 1]
  const prevDate = prevMessage ? new Date(prevMessage.timestamp).toDateString() : ''
  const currentDate = message ? new Date(message.timestamp).toDateString() : ''
  return prevDate !== currentDate
}

function formatDate(date: string): string {
  return new Date(date).toLocaleDateString(i18n.global.locale, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

const updateOnlineStatus = () => {
  isOnline.value = navigator.onLine
}

onMounted(() => {
  userId.value = getUserId()

  initWebSocket()
  window.addEventListener('online', updateOnlineStatus)
  window.addEventListener('offline', updateOnlineStatus)

  logEvent(analytics, 'chat_open')
})

onUnmounted(() => {
  websocketWatcherEnabled.value = false
  if (socket) {
    socket.close()
  }
  window.removeEventListener('online', updateOnlineStatus)
  window.removeEventListener('offline', updateOnlineStatus)
})
</script>
<template>
  <div
    class="flex flex-col w-full justify-between safari-fix"
    :class="{ 'is-in-container': isInContainer }"
  >
    <div class="flex flex-col p-5" :class="{ 'h-dvh': !isInContainer }">
      <div id="chat-header" v-if="isInContainer">
        <div class="flex flex-row space-x-2">
          <div>
            <div>mühlemann+popp Team</div>
            <PresenceIndicator :staffIsOnline="staffIsOnline" />
          </div>
        </div>
      </div>
      <div id="chat-header" v-else>
        <PresenceIndicator :staffIsOnline="staffIsOnline" />
      </div>
      <div
        ref="messagesRef"
        class="flex-1 flex flex-col-reverse"
        style="overflow-y: auto"
        :style="{ 'max-height': isInContainer ? 'calc(90vh - 88px)' : 'auto' }"
      >
        <div v-if="!isOnline" class="text-center text-red-500 text-xs py-2">
          {{ $t('chat.offline') }}
        </div>
        <div v-if="typingIndicator">
          <!-- show the typing indicator -->
          <ChatMessage
            :message="{ text: '', isMine: false, isPending: true, userId: null, timestamp: '' }"
            :isTyping="true"
          />
        </div>
        <div v-for="(message, index) in messages.slice().reverse()" :key="message.text">
          <!-- Divider between days -->
          <div v-if="isNewDay(message, index)" class="text-center text-gray-500 text-xs m-4">
            {{ message.timestamp ? formatDate(message.timestamp) : '' }}
          </div>
          <ChatMessage :message="message" />
        </div>
        <div
          v-if="messages.length == 0"
          class="text-center text-gray-500 text-xs py-2 h-24 flex w-full justify-center items-center"
        >
          <div>
            <span class="loader"></span>
          </div>
        </div>
      </div>
      <div class="px-0 pt-4">
        <div class="flex">
          <BaseInput
            :disabled="!isOnline"
            ref="messageInputRef"
            :placeholder="$t('chat.placeholder')"
            v-model:value="messageInput"
            @keyup.enter="sendMessage"
            class="w-full mr-2 focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-3 bg-gray-200 rounded-lg py-1"
          />
          <div class="items-center">
            <button
              type="button"
              id="send"
              @click="sendMessage"
              class="h-12 w-12 rounded-full transition duration-500 ease-in-out text-white focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="h-8 w-8 transform rotate-90 m-auto"
              >
                <path
                  d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
@supports (-webkit-touch-callout: none) {
  .safari-fix {
    height: -webkit-fill-available;
  }
}

#chat-header {
  @apply h-8;
  margin-top: 70px;
}

.is-in-container #chat-header {
  @apply h-14 mt-0;
}

#send {
  background-color: var(--gold);
  border-color: var(--gold);
  color: black;
}

#send:hover {
  background-color: var(--darkgold);
  border-color: var(--darkgold);
  color: black;
}

@supports (-webkit-touch-callout: none) {
  .safari-fix {
    height: -webkit-fill-available;
  }
}

.message {
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid var(--gold);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
