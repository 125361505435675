import FreefloatRenderer from '@/lib/Rendering/BubbleRenderer/FreefloatRenderer'
import { type Selection } from 'd3'
import type { FreefloatContent, Vertex } from '../../../../types'

/**
 * Renders the bubbles in the center of the Freefloat elements which don't have a detailed view.
 * We don't create a sub-SVG to keep things simple.
 */
export default class FreefloatParentRenderer extends FreefloatRenderer {
  private readonly FONT_SIZE = 8

  render(g: Selection<SVGGElement, unknown, HTMLElement, undefined>, isZooming: boolean): void {
    if (!isZooming) {
      const vertices = this.mycelModel.getVerticesByClass('freefloat-parent')
      g.selectAll<SVGGElement, Vertex>('text.freefloat-parent')
        .data<Vertex>(vertices, (d: Vertex) => d.timelineEvent?.id ?? '')
        .join('text')
        .attr('class', 'freefloat-parent')
        .attr('x', (vertex: Vertex) => vertex.x)
        .attr('y', (vertex: Vertex) => vertex.y)
        .attr('text-anchor', 'middle')
        .attr('font-size', (d: Vertex) => this.getFontSize(d))
        .attr('dominant-baseline', 'middle')
        .text((d: Vertex) => (d.timelineEvent as FreefloatContent).title ?? '')
    }
  }

  public zoomHandler(
    g: Selection<SVGGElement, unknown, HTMLElement, undefined>,
    zoomTweenRangeAdjustment: number,
    logicZoomLevel: number
  ) {
    // Freeze the circle radius at zoom level 2
    const cappedZoomLevel = Math.min(logicZoomLevel, 2)
    g.selectAll<SVGGElement, Vertex>('circle.freefloat-parent').attr('r', (d) => {
      return this.circleRadii['freefloat-parent'] / cappedZoomLevel
    })
    g.selectAll<SVGGElement, Vertex>('text.freefloat-parent').attr('font-size', (d: Vertex) => {
      return this.getFontSize(d) / cappedZoomLevel
    })
  }

  /**
   * Returns the font size for the freefloat-parent bubble. Smaller if the text is longer
   * @param d
   * @private
   */
  private getFontSize(d: Vertex) {
    return ((d.timelineEvent as FreefloatContent).title.length > 10 ? 6 : 8)
  }
}
