<template>
  <ul>
    <li>&copy; {{ new Date().getFullYear() }} mühlemann+popp AG</li>
    <li><router-link to='/privacy'>{{ $t('navigation.privacy_policy') }}</router-link></li>
  </ul>
</template>
<script setup lang="ts"></script>
<style scoped>
ul {
  @apply flex flex-col;
  font-size: 10px;
  color: grey;
}

@media screen(sm) {
  ul {
    @apply flex flex-row;

  }
  li {
    ::before {
      content: '•';
      @apply m-2;
    }
    font-size: 15px;
  }
}
</style>
