import type { Vertex } from '../../types'
import GraphTools from '@/lib/GraphTools'
import type { DeviceAbstract } from '@/lib/DeviceHandler/DeviceAbstract'

export default class VertexGenerator {
  private cnt: number
  private width: number
  private height: number
  private variability: number
  private readonly deviceHandler: DeviceAbstract

  constructor(
    cnt: number,
    width: number,
    height: number,
    variability: number,
    deviceHandler: DeviceAbstract
  ) {
    this.cnt = cnt
    this.width = width
    this.height = height
    this.deviceHandler = deviceHandler
    this.variability = variability
  }

  eliminateRandomElements(Vertices: Vertex[], quota: number) {
    return Vertices.filter((_, i) => GraphTools.random() > quota)
  }

  eliminateVerticesTooClose(Vertices: Vertex[], minDistance: number) {
    const newVertices: Vertex[] = []
    for (const Vertex of Vertices) {
      let tooClose = false
      for (const newVertex of newVertices) {
        if (
          Math.sqrt(Math.pow(Vertex.x - newVertex.x, 2) + Math.pow(Vertex.y - newVertex.y, 2)) <
          minDistance
        ) {
          tooClose = true
          break
        }
      }
      if (!tooClose) {
        newVertices.push(Vertex)
      }
    }
    return newVertices
  }

  generate(strategy: string, decimateRatio: number, minDistance: number = 100): Vertex[] {
    const vertices: Vertex[] = []

    const aspectRatio = this.width / this.height
    const rows = Math.floor(Math.sqrt(this.cnt / aspectRatio))
    const cols = Math.floor(this.cnt / rows)

    switch (strategy) {
      case 'random':
        for (let i = 0; i < this.cnt; i++) {
          vertices.push({
            x: GraphTools.random() * this.width,
            y: GraphTools.random() * this.height,
            type: 'node'
          })
        }
        break
      case 'regular':
        for (let i = 0; i < rows; i++) {
          for (let j = 0; j < cols; j++) {
            vertices.push({ x: (j * this.width) / cols, y: (i * this.height) / rows, type: 'node' })
          }
        }
        break
      default:
        for (let i = 0; i < rows; i++) {
          for (let j = 0; j < cols; j++) {
            vertices.push({
              x: (j * this.width) / cols + GraphTools.random() * this.variability,
              y: (i * this.height) / rows + GraphTools.random() * this.variability,
              type: 'node'
            })
          }
        }
    }

    vertices.forEach((v, i) => {
      v.x = Math.floor(v.x)
      v.y = Math.floor(v.y)
    })

    return this.eliminateRandomElements(
      this.eliminateVerticesTooClose(vertices, minDistance),
      decimateRatio
    )
  }
}
