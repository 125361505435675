import type { Podcast, TimelineEventClasses, Vertex } from '../../../../types'
import type { BaseType, Selection } from 'd3'
import * as d3 from 'd3';
import { AbstractSvgRenderer } from '@/lib/Rendering/BubbleRenderer/AbstractSvgRenderer'
import appleLogo from '@/assets/apple.svg'
import spotifyLogo from '@/assets/spotify.svg'
import youtubeLogo from '@/assets/youtube.svg'
import RenderUtilities from '../RenderUtilities';

export class PodcastRenderer extends AbstractSvgRenderer {
  private holderG: d3.Selection<SVGGElement, Vertex, SVGGElement, undefined> | undefined

  public config = {
    hasHtml: true,
    hasSubtitle: false,
    hasClassTitle: true,
    filterCircleRadius: 140,
    filterCircleMaxViewportFill: 0.5,
    noArrowsOnSafari: true
  }

  getClassName(): TimelineEventClasses {
    return 'podcast'
  }

  getCssClassName(d: Vertex) {
    return 'podcast'
  }

  protected getClassTitleLabel(d: Vertex): string {
    return 'classTitleLabel.podcast'
  }

  getSubTitle(event: any): string {
    return 'Subtitle'
  }

  getHtmlWidthFactor(event: any): number {
    return 1
  }

  public getHTML(d: Vertex) {
    const podcast = d.timelineEvent as Podcast

    return `<h1 class='title'>${podcast.title}</h1>`
  }

  private getPodcastImageUrl(podcast: Podcast) {
    if (podcast.published) {
      return podcast.image?.filename + '/m/500x500/smart' ?? ''
    } else {
      return podcast.image?.filename + '/m/500x500/smart/filters:grayscale()' ?? ''
    }
  }

  protected createPhotoSvg(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    vertices: Vertex[]
  ) {
    this.holderG = g
      .selectAll<SVGGElement | BaseType, Vertex>('svg.podcast')
      .append('g')
      .attr('class', (vertex: Vertex) => {
        const p = vertex.timelineEvent as Podcast
        if (p.published) {
          return 'podcast-photo published'
        } else {
          return 'podcast-photo unpublished'
        }
      })
      .attr('clip-path', 'url(#podcastClip)')
      .on('click', (evnet: any, d) => {
        this.dispatchEvent(new CustomEvent('circleClick', { detail: { destination: d } }))
      })

    this.holderG
      .append('image')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.SVGWIDTH)
      .attr('height', this.SVGWIDTH)
      .attr('href', (vertex: Vertex) => {
        const p = vertex.timelineEvent as Podcast
        return this.getPodcastImageUrl(p)
      })
      .attr('clip-path', 'url(#teamEventClip)')
  }

  protected createCropMask(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    this.circleRadii['event']
    g.append('defs')
      .append('clipPath')
      .attr('id', 'podcastClip')
      .append('circle')
      .attr('cx', this.SVGWIDTH / 2)
      .attr('cy', this.SVGWIDTH / 2)
      .attr('r', this.SVGWIDTH / 2)
  }

  protected createTextSvg(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    const podcastText = g.selectAll<SVGGElement | BaseType, Vertex>('svg.podcast')
      .append('g')
      .attr('class', 'podcast-text')
      
    podcastText 
      .append('text')
      .attr('class', (d: Vertex) => {
        return 'podcast-title' + ((d.timelineEvent as Podcast).published ? ' published' : ' unpublished')
      })
      .attr('x', 70)
      .attr('y', 299)
      .attr('text-anchor', 'left')
      .text((d: Vertex) => {
        return (d.timelineEvent as Podcast).title ?? ''
      })

    const that = this

    podcastText
      .append('text')
      .attr('class', 'podcast-subtitle')
      .attr('x', 70)
      .attr('y', 254)
      .attr('text-anchor', 'left')
      .each(function (d: Vertex) {
        console.log((d.timelineEvent as Podcast).people)
        const text = that.textWrap((d.timelineEvent as Podcast).people ?? 'unknown', this, 72, 250)
        // align to bottom
        const bbox = text!.node()?.getBBox()
        const height = bbox?.height ?? 0
        text!.attr('transform', `translate(0, ${(38 - height)})`)
      })

    podcastText
      .append('text')
      .attr('class', 'podcast-description')
      .attr('x', 70)
      .attr('y', 324)
      .attr('text-anchor', 'left')
      .each(function (d: Vertex) {
        const text = that.textWrap((d.timelineEvent as Podcast).description, this, 72, 500)        
      })
  }

  protected createLinks(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    g.selectAll<SVGGElement | BaseType, Vertex>('svg.podcast')
    .each(function(d: Vertex) {
      const podcast = d.timelineEvent as Podcast
      const className = podcast.published ? 'podcast-links published' : 'podcast-links unpublished'
      const links = [ { type: 'apple', url: podcast.link_apple }, { type: 'spotify', url: podcast.link_spotify }, { type: 'youtube', url: podcast.link_youtube }]
      const linkG = d3.select(this as SVGGElement).append('g')
      .attr('class', className)
      
      links.forEach((link, i) => {
        const x = 70 + 45 * i
        const y = 305
        linkG
          .append('image')
          .attr('x', x)
          .attr('y', y)
          .attr('width', 40)
          .attr('height', 40)
          .attr('href', () => {
            switch (link.type) {
              case 'apple':
                return appleLogo
              case 'spotify':
                return spotifyLogo
              case 'youtube':
                return youtubeLogo
              default:
                return ''
            }
          })
          .on('click', () => {
            if (!podcast.published) return
            window.open(link.url, '_blank')
          })
      })
    })
  }

  protected createGradient(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    const gradient = g
      .append('defs')
      .append('linearGradient')
      .attr('id', 'podcastGradient')
      .attr('x1', '0%')
      .attr('y1', '100%')
      .attr('x2', '0%')
      .attr('y2', '0%')

    // Define the start of the gradient (0% opacity)
    gradient
      .append('stop')
      .attr('offset', '0%')
      .attr('style', 'stop-color:rgb(0,0,0);stop-opacity:0.5')

    gradient
      .append('stop')
      .attr('offset', '60%')
      .attr('style', 'stop-color:rgb(0,0,0);stop-opacity:0.5')

    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('style', 'stop-color:rgb(0,0,0);stop-opacity:0')

    g.selectAll<SVGGElement | BaseType, Vertex>('svg.podcast')
      .append('rect')
      .attr('class', 'podcast-gradient')
      .attr('x', 0)
      .attr('y', this.SVGWIDTH / 2 - 50)
      .attr('width', this.SVGWIDTH)
      .attr('height', this.SVGWIDTH / 2 + 50)
      .style('fill', 'url(#podcastGradient)')
      .attr('clip-path', 'url(#teamEventClip)')
  }

  render(
    g: Selection<SVGGElement, unknown, HTMLElement, undefined>,
    isZooming: boolean,
    isFiltering: boolean = false,
    useTransition = false
  ): void {
    const vertices = this.mycelModel.getVerticesByClass(this.getClassName())

    if (!isZooming) {
      this.createCropMask(g)
      this.createGroupSvg(g, vertices)
      this.createPhotoSvg(g, vertices)
      this.createGradient(g)
      this.createLinks(g)      
      this.createTextSvg(g)
    }
  }

  public zoomHandler(
    g: Selection<SVGGElement, unknown, HTMLElement, undefined>,
    zoomTweenRangeAdjustment: number,
    logicZoomLevel: number,
    isFiltering: boolean
  ) {
    this.zoomedOutTitleZoomHandler(g, zoomTweenRangeAdjustment, logicZoomLevel, isFiltering)

    const startLevel = isFiltering ? 3 : 30
    const endLevel = isFiltering ? 4 : 45

    
    RenderUtilities.zoomTween(
      g.selectAll('.podcast-text'),
      'transform',
      'translate(0,0) scale(1)',
      'translate(35,90) scale(0.5)',
      startLevel * zoomTweenRangeAdjustment,
      endLevel * zoomTweenRangeAdjustment,
      logicZoomLevel
    )

  
    RenderUtilities.zoomTween(
      g.selectAll('g.podcast-links, .podcast-description'),
      'opacity',
      '0',
      '1',
      startLevel * zoomTweenRangeAdjustment,
      endLevel * zoomTweenRangeAdjustment,
      logicZoomLevel
    )
    this.render(g, true, isFiltering)
  }
}
