import type {
  Project,
  TimelineEvent,
  TimelineEventClasses,
  Vertex
} from '../../../../types'
import * as d3 from 'd3'
import type { BaseType } from 'd3'
import type { Selection } from 'd3'
import RenderUtilities from '@/lib/Rendering/RenderUtilities'
import { AbstractSvgRenderer } from '@/lib/Rendering/BubbleRenderer/AbstractSvgRenderer'

export default class ProjectRenderer extends AbstractSvgRenderer {
  public config = {
    hasHtml: true,
    hasSubtitle: false,
    hasClassTitle: true,
    filterCircleRadius: 80,
    filterCircleMaxViewportFill: 0.5,
    noArrowsOnSafari: true,
  }

  public getClassName(): TimelineEventClasses {
    return 'project'
  }

  public getClassTitle(d: Project) {
    return 'Project'
  }

  public getSubTitle(d: Project) {
    return ''
  }

  public getHtmlWidthFactor(d: Project) {
    return 1
  }

  public getHTML(d: Vertex) {
    // parent element: div.event-body
    return `${d.timelineEvent?.description ?? ''}
                ${d.timelineEvent ? this.getGalleryHTML(d.timelineEvent) : ''}`
  }

  private getGalleryHTML(event: TimelineEvent) {
    const pictures = event?.pictures || []
    if (!pictures.length) {
      return ''
    }

    const galleryW = 400

    return `${pictures
                      .map(
                        (p: { filename: string; title: string }) => `
                            <img style='margin-top: 10px;'
                              src='${p.filename}/m/${galleryW * 4}x0' 
                              ></img>`
                      )
                      .join('')}`
  }

  public render(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    isZooming: boolean = false,
    isFiltering: boolean = false,
    useTransition = false
  ) {
    const logoPaddingFactor = 0.8 // 1 == 100% of the circle

    const vertices = this.mycelModel.getVerticesByClass('project')

    if (!isZooming) {
      this.createGroupSvg(g, vertices)
      this.createClassTitleSvg()
      this.createSubtitleSvg()
      this.createLineSvg()
      this.createHtmlSvg()
      this.createButtons()
      vertices.map((v) => v?.timelineEvent?.id)
    }

    const scalingDueToFiltering = 10 // Check why we have a factor 10 here.

    // function for the start point. Returns the position of the logo with respect to the origin
    const zoomedOutFn = (d: Vertex) => {
      return {
        x: (this.SVGWIDTH - 160 * 2) / 2,
        y: (this.SVGWIDTH - 160 * 2) / 2,
        scale: 2
      }
    }

    // function for when we have zoomed in. Note: Logo is square and centered vertically and horizontally
    const zoomedInFn = (d: Vertex) => {
      return {
        x: this.MARGIN,
        y: 38,
        scale: 1
      }
    }

    // interpolates between the result of zoomedOutFn and zoomedInFn
    const interpolationFunction = (d: Vertex, progress: number) => {
      const zoomedOutValue = zoomedOutFn(d)
      const zoomedInValue = zoomedInFn(d)
      const x = zoomedOutValue.x * (1 - progress) + zoomedInValue.x * progress
      const y = zoomedOutValue.y * (1 - progress) + zoomedInValue.y * progress
      const scale = zoomedOutValue.scale * (1 - progress) + zoomedInValue.scale * progress
      return `translate(${x},${y}) scale(${scale * scalingDueToFiltering})`
    }

    if (!isZooming) {
      const gProject = g
        .selectAll<SVGGElement | BaseType, Vertex>('svg.project')
        .append('g')
        .attr('class', (d, ix) => `project project-logo project-${d.timelineEvent!.id}`)
    }
    if (isFiltering) {
      g.selectAll<SVGGElement | BaseType, Vertex>('g.project-logo').raise()
    }

    const gProject = g.selectAll<SVGGElement | BaseType, Vertex>('g.project-logo')

    let foreignObjectVisible: boolean = false

    if (this.currentCurrentZoomLevel) {
      if (isFiltering) {
        RenderUtilities.zoomTweenCustom(
          gProject,
          'transform',
          interpolationFunction,
          4,
          5,
          this.currentCurrentZoomLevel,
          useTransition
        )
        foreignObjectVisible = this.currentCurrentZoomLevel >= 0.5

      } else {
        RenderUtilities.zoomTweenCustom(
          gProject,
          'transform',
          interpolationFunction,
          1,
          10,
          this.currentCurrentZoomLevel,
          useTransition
        )
        foreignObjectVisible = this.currentCurrentZoomLevel >= 2
      }
    }

    // Necessary for Safari on Web which always show the foreignObject in the front
    g.selectAll('svg.project foreignObject').classed('faded-in', foreignObjectVisible)

    // project logo. Will be created only once. So we don't run this during zooming.
    if (!isZooming) {
      gProject
        .append('image')
        .attr('xlink:href', (d: Vertex) => {
          return (d.timelineEvent as Project).logo_url as string
        })
        .attr('width', this.circleRadii['event'] * 2 * logoPaddingFactor)
        .attr('height', this.circleRadii['event'] * 2 * logoPaddingFactor)
        .attr('aria-label', (d: Vertex) => {
          return (d.timelineEvent as Project).title as string
        })
    }
  }

  public resetPosition() {
    this.svgTimelineEvent!.attr('transform', (d: Vertex) => `translate(${d.x}, ${d.y})`)
  }

  protected getClassTitleLabel(d: Vertex): string {
    return 'classTitleLabel.project'
  }

  public resetFilter(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    super.resetFilter(g)
    this.render(g, true, false, true)
  }


  protected updateElementsOnFiltering(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
  ) {
    this.render(g, true, true, true)
  }

  zoomHandler(
    g: Selection<SVGGElement, unknown, HTMLElement, undefined>,
    zoomTweenRangeAdjustment: number,
    logicZoomLevel: number,
    isFiltering: boolean
  ) {
    const startLevel = isFiltering ? 4 : 30
    const endLevel = isFiltering ? 5 : 45

    RenderUtilities.zoomTween(
      g.selectAll(
        '.project text.event-title, .project text.event-class, .project foreignObject,' +
          '.project .event-buttons, .project .event-line'
      ),
      'opacity',
      '0',
      '1',
      startLevel * zoomTweenRangeAdjustment,
      endLevel * zoomTweenRangeAdjustment,
      logicZoomLevel
    )
    this.render(g, true, isFiltering)
  }
}
