import { DeviceAbstract } from '@/lib/DeviceHandler/DeviceAbstract'
import * as d3 from 'd3'
// @ts-ignore
import data from '@/assets/prerendered-desktop.json'
import type { DeviceConfig, SerializableModel } from '../../../types'

export class DeviceDesktop extends DeviceAbstract {
  public getConfig(): DeviceConfig {
    return {
      timelineYearsEasingFactor: 3,
      minZoom: 1.1,
      initialCenter: '100,200',
      offsetVerticalRelative: 0.2,
      offsetHorizontalRelative: 0.2,
      filteredCircleScaleDownFactor: 1,
      maxZoomLevel: 100,
      maxZoomLevelForFocusingByTimelineEventClass: {
        'person-onboarding': 50,
        project: 100,
        offering: 100,
        freefloat: 100,
        'freefloat-parent': 100,
        'team-event': 50,
        'blogarticle': 100,
        podcast: 50
      },
      maxZoomLevelFiltering: 20,
      zoomTweenRangeAdjustment: 1,
      peopleRenderer: {
        pictureMoveToBottom: 1.5,
        pictureMoveToRight: 1.5
      },
      safariForeignObjectFixFactor: 1,
      buttonScaleFactor: 1,
      zoomedOutStateZoomLevel: {
        all: 40,
        projects: 5,
        people: 10,
        offerings: 5,
        blog: 5,
        podcast: 5
      }
    }
  }

  public getPrerenderedData(): SerializableModel {
    return window.structuredClone(data) as SerializableModel
  }

  getInitialZoom(): number {
    return 1.1
  }

  getAspectRatioForVertexGeneration(width: number, height: number): number {
    return width / height
  }

  getOverflowRatio(): number {
    return 1
  }

  getZoomHandlerTranslateExtent(
    width: number,
    height: number
  ): [[number, number], [number, number]] {
    return [
      [((this.getInitialZoom() - 1) * width) / 2, ((this.getInitialZoom() - 1) * height) / 2],
      // [(1 - (1 / this.initialZoom)) * this.width * this.scaleMultiplier,
      //     (1 - (1 / this.initialZoom)) * this.height * this.scaleMultiplier]
      //[this.width * this.scaleMultiplier * (1 / this.initialZoom), this.height * this.scaleMultiplier * (1 / this.initialZoom)],
      // todo: that is not enough constraint. My approach did not work so far.
      [width * 0.95, height * 0.95]
    ]
  }

  getTransformForZoomAll(width: number, height: number): d3.ZoomTransform {
    const scale = this.getInitialZoom()

    // this positions the pan in the center of the SVG
    return d3.zoomIdentity
      .scale(scale)
      .translate(
        (width / 2) * (1 - this.getInitialZoom()),
        (height / 2) * (1 - this.getInitialZoom())
      )
  }

  getTransformForZoomOnOpen(width: number, height: number): d3.ZoomTransform {
    const scale = 2.4

    // this positions the pan in the center of the SVG
    return d3.zoomIdentity.scale(scale).translate(-width / 1.9, -height / 10)
  }
}
