/* eslint-disable */
import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'

import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import Slider from 'primevue/slider'
import Panel from 'primevue/panel'
import Knob from 'primevue/knob'
import Card from 'primevue/card'
import Checkbox from 'primevue/checkbox'
import Dropdown from 'primevue/dropdown'
import Tooltip from 'primevue/tooltip'
//theme
import 'primevue/resources/themes/lara-light-indigo/theme.css'
// i18n
import i18n from '@/i18n'

//core
import 'primevue/resources/primevue.min.css'
import InputText from 'primevue/inputtext'
import { getRouter } from '@/router'
import * as Sentry from '@sentry/vue'

const app = createApp(App)
const pinia = createPinia()
app.use(PrimeVue)
app.use(i18n)
app.use(pinia)
app.use(getRouter())
app.component('Button', Button)
app.component('Slider', Slider)
app.component('Panel', Panel)
app.component('Knob', Knob)
app.component('Card', Card)
app.component('Checkbox', Checkbox)
app.component('Dropdown', Dropdown)
app.component('InputText', InputText)
app.directive('tooltip', Tooltip)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(getRouter()),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

window.addEventListener("load", (event) => {
  app.mount('#app')
});

