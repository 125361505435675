import type { TeamEvent, TimelineEventClasses, Vertex } from '../../../../types'
import * as d3 from 'd3'
import { AbstractSvgRenderer } from '@/lib/Rendering/BubbleRenderer/AbstractSvgRenderer'
import { type BaseType } from 'd3'
import { arrowRight, arrowLeft } from '@/assets/buttons'

export default class TeamEventRenderer extends AbstractSvgRenderer {
  // Holds three slides: The previous, next and current image
  private holderG: d3.Selection<SVGGElement, Vertex, SVGGElement, undefined> | undefined
  private g: d3.Selection<SVGGElement, Vertex, SVGGElement, undefined> | undefined
  private svg: d3.Selection<SVGSVGElement, Vertex, SVGGElement, undefined> | undefined

  private imageLoadPromises: Promise<void>[] = []

  public config = {
    hasHtml: false,
    hasSubtitle: false,
    hasClassTitle: false,
    filterCircleRadius: 1000,
    noArrowsOnSafari: false,
  }

  public getClassTitle(d: TeamEvent) {
    return d.title
  }

  public getSubTitle(d: TeamEvent) {
    return ''
  }

  public getHtmlWidthFactor(d: TeamEvent) {
    return 1
  }

  public getHTML(d: TeamEvent) {
    return ''
  }

  getClassName(): TimelineEventClasses {
    return 'team-event'
  }

  protected getClassTitleLabel(d: Vertex): string {
    return ''
  }

  protected createPhotoSvg(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
    vertices: Vertex[]
  ) {
    this.holderG = g
      .selectAll<SVGGElement | BaseType, Vertex>('svg.team-event')
      .append('g')
      .attr('class', 'team-event-photo')
      .attr('clip-path', 'url(#teamEventClip)')

    this.holderG
      .append('image')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.SVGWIDTH)
      .attr('height', this.SVGWIDTH)
      .attr('href', (vertex: Vertex) => {
        const p = vertex.timelineEvent as TeamEvent
        return p.photos[0]?.filename + '/m/500x500/smart/filters:grayscale()' ?? ''
      })
      .attr('clip-path', 'url(#teamEventClip)')
  }

  private setImageHref() {
    this.holderG!.selectAll<SVGGElement, Vertex>('.left-image').attr('href', (d: Vertex) => {
      const p = d.timelineEvent as TeamEvent
      const pos = p.position ?? 0
      return (
        p.photos[(p.position ?? 0) > 0 ? pos - 1 : (p.photos.length ?? 0) - 1].filename +
          '/m/800x800/smart' ?? ''
      )
    })
    this.holderG!.selectAll<SVGGElement, Vertex>('.center-image').attr('href', (d: Vertex) => {
      const p = d.timelineEvent as TeamEvent
      const pos = p.position ?? 0
      return p.photos[p.position ?? 0].filename + '/m/800x800/smart' ?? ''
    })
    this.holderG!.selectAll<SVGGElement, Vertex>('.right-image').attr('href', (d: Vertex) => {
      const p = d.timelineEvent as TeamEvent
      const pos = p.position ?? 0
      return p.photos[pos < p.photos.length - 1 ? pos + 1 : 0].filename + '/m/800x800/smart' ?? ''
    })
  }

  private createSlideshowButtons() {
    const buttonRight = this.g!.append('g')
      .attr('class', 'event-buttons right')
      .attr(
        'transform',
        this.deviceHandler.getConfig().buttonScaleFactor == 2
          ? 'translate(300, 180) scale(2)'
          : 'translate(340, 190)'
      )
      .on('click', (event: any, d: Vertex) => {
        this.rotateImages(event, d, false)
      })

    this.addButtonFromString(buttonRight, arrowRight)

    const buttonLeft = this.g!.append('g')
      .attr('class', 'event-buttons left')
      .attr(
        'transform',
        this.deviceHandler.getConfig().buttonScaleFactor == 2
          ? 'translate(60, 180) scale(2)'
          : 'translate(40, 190)'
      )
      .on('click', (event: any, d: Vertex) => {
        this.rotateImages(event, d, true)
      })

    this.addButtonFromString(buttonLeft, arrowLeft)
  }

  private initImages() {
    this.g = this.svgTimelineEvent!
      .append('g')
      .attr('clip-path', 'url(#teamEventClip)')
      .attr('class', 'teamEventClipper')

    this.holderG = this.g!.append('g').attr('class', 'slideshowHolder')
    this.holderG!.append('image')
      .attr('class', 'left-image')
      .attr('width', this.SVGWIDTH)
      .attr('x', -this.SVGWIDTH)
    this.holderG!.append('image')
      .attr('class', 'center-image color')
      .attr('width', this.SVGWIDTH)
      .attr('x', 0)
      .on('click', (event: any, d: Vertex) => {
        this.dispatchEvent(new CustomEvent('circleClick', { detail: { destination: d } }))
      })
    this.holderG!.append('image')
      .attr('class', 'right-image')
      .attr('width', this.SVGWIDTH)
      .attr('x', this.SVGWIDTH)

    this.setImageHref()
  }

  /**
   *  Rotate the images in the slideshow when the button is clicked
   */ 
  private rotateImages(event: any, d: Vertex, isReverse: boolean = false) {
    const t = d3.transition().ease(d3.easeCubicOut)
    // We want to move only the slideshowHolder of the event we click on.
    d3.select(event.target.closest('.teamEventClipper'))
      .select('.slideshowHolder')
      .transition(t)
      .duration(500)
      .attr('transform', `translate(${-this.SVGWIDTH * (isReverse ? -1 : 1)},0)`)
      .on('end', function (event: any) {
        const p = d.timelineEvent as TeamEvent
        const pos = p.position ?? 0
        p.position = (pos + p.photos.length + 1 * (isReverse ? -1 : 1)) % p.photos.length
        const h = d3.select(this)

        if (isReverse) {
          // Add an image to the left
          h.append('image')
            .attr('class', 'new-image')
            .attr('width', 400)
            .attr('x', -800)
            .attr(
              'href',
              p.photos[(p.position ?? 0) > 0 ? p.position - 1 : (p.photos.length ?? 0) - 1].filename +
          '/m/800x800/smart' ?? ''
            )

          // Remove the image on the right
          h.select('.right-image').remove()

          // Remove the transform and fix x coordinates
          h.attr('transform', `translate(0,0)`)
          h.select('.center-image').attr('class', 'right-image').attr('x', 400)
          h.select('.left-image').attr('class', 'center-image').attr('x', 0)
          h.select('.new-image').attr('class', 'left-image').attr('x', -400)
        } else {
          // Add an image to the right
          h.append('image')
            .attr('class', 'new-image')
            .attr('width', 400)
            .attr('x', 800)
            .attr(
              'href',
              p.photos[p.position < p.photos.length - 1 ? p.position + 1 : 0].filename +
                '/m/800x800/smart' ?? ''
            )

          // Remove the image on the left
          h.select('.left-image').remove()

          // Remove the transform and fix x coordinates
          h.attr('transform', `translate(0,0)`)
          h.select('.center-image').attr('class', 'left-image').attr('x', -400)
          h.select('.right-image').attr('class', 'center-image').attr('x', 0)
          h.select('.new-image').attr('class', 'right-image').attr('x', 400)
        }
      })
  }

  protected createCropMask(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>) {
    this.circleRadii['event']
    g.append('defs')
      .append('clipPath')
      .attr('id', 'teamEventClip')
      .append('circle')
      .attr('cx', this.SVGWIDTH / 2)
      .attr('cy', this.SVGWIDTH / 2)
      .attr('r', this.SVGWIDTH / 2)
  }

  public render(g: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>, isZooming: boolean) {
    if (isZooming) return
    const vertices = this.mycelModel.getVerticesByClass('team-event')

    this.createCropMask(g)
    this.createGroupSvg(g, vertices)
    this.createPhotoSvg(g, vertices)
    this.initImages()
    this.createTitleSvg()
    this.createSlideshowButtons()
    this.createButtons(true)
  }
}
