import type { Edge, EdgeRating, EdgeType, Point, RawEdge, Vertex, VertexType } from '../../types'

/**
 * A collection of static methods to help with graph operations
 */
let seed = 1
export default class GraphTools {
  static random() {
    const x = Math.sin(seed++) * 10000
    return x - Math.floor(x)
  }

  // Helper function to get distance between two points
  static distanceToPoint(a: Point, b: Point) {
    return Math.sqrt((a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y))
  }

  static distanceToEdge(p: Point, edge: Edge) {
    const a = edge.source
    const b = edge.target

    // Vector from a to b
    const ab = { x: b.x - a.x, y: b.y - a.y }

    // Vector from a to p
    const ap = { x: p.x - a.x, y: p.y - a.y }

    const dot = GraphTools.dotProduct(ab, ap)

    // Length squared of ab
    const len_sq = ab.x * ab.x + ab.y * ab.y

    // Parameter to check if projection is on the segment
    const param = dot / len_sq

    // Closest point on the line to p
    let closest
    if (param < 0) {
      closest = a
    } else if (param > 1) {
      closest = b
    } else {
      closest = { x: a.x + param * ab.x, y: a.y + param * ab.y }
    }

    return GraphTools.distanceToPoint(p, closest)
  }

  static findClosestEdge(edges: Edge[], p: Point): Edge | null {
    let closestEdge: Edge | null = null
    let closestDistance = Infinity
    edges.forEach((edge) => {
      const distance = GraphTools.distanceToEdge(p, edge)
      if (distance < closestDistance) {
        closestDistance = distance
        closestEdge = edge
      }
    })
    return closestEdge
  }

  static findClosestVertex(vertices: Vertex[], p: Point): Vertex | null {
    let closestVertex: Vertex | null = null
    let closestDistance = Infinity
    vertices.forEach((vertex) => {
      const distance = GraphTools.distanceToPoint(p, vertex)
      if (distance < closestDistance) {
        closestDistance = distance
        closestVertex = vertex
      }
    })
    return closestVertex
  }

  static isTheSamePoint(p1: Point, p2: Point) {
    return p1.x === p2.x && p1.y === p2.y
  }

  static reverse(edge: Edge) {
    const source = edge.source
    edge.source = edge.target
    edge.target = source
    return edge
  }

  static isTheSameEdge(e1: Edge, e2: Edge) {
    return (
      GraphTools.isTheSamePoint(e1.source, e2.source) &&
      GraphTools.isTheSamePoint(e1.target, e2.target)
    )
  }

  static getOtherEndpoint(edge: Edge, vertex: Vertex) {
    if (!edge || !edge.source || !edge.target) {
      throw new Error('Edge has no source or target')
    }
    if (edge.source.x === vertex.x && edge.source.y === vertex.y) {
      return edge.target
    }
    return edge.source
  }

  /**
   * Find the connected edges to a point
   * @param edges
   * @param p Vertex which the edges should be connected to
   * @param ignoreType
   * @param excludeEdges Edges to exclude from the search
   * @param requireEdgeTypes
   * @param requireNodeTypes
   */
  static findConnectedEdges(
    edges: Edge[],
    p: Vertex,
    ignoreType = false,
    excludeEdges: Edge[] = [],
    requireEdgeTypes: EdgeType[] = ['neutral'],
    requireNodeTypes: VertexType[] = ['node']
  ) {
    return edges
      .filter((edge) => !GraphTools.isInPath(excludeEdges, edge))
      .filter((edge) => {
        const otherPoint = GraphTools.getOtherEndpoint(edge, p)
        return (
          (GraphTools.isTheSamePoint(edge.source, p) ||
            GraphTools.isTheSamePoint(edge.target, p)) &&
          ((requireEdgeTypes.includes(edge.type) && requireNodeTypes.includes(otherPoint.type)) ||
            ignoreType)
        )
      })
  }

  /**
   * Get the edge between two points. If there is no edge, return null
   * @param edges
   * @param p
   * @param q
   */
  static getConnectedEdge(edges: Edge[], p: Vertex, q: Vertex) : Edge | null {
    const candEdges = GraphTools.findConnectedEdges(edges, p, true, [] ).filter(
      (edge) => GraphTools.isTheSamePoint(GraphTools.getOtherEndpoint(edge, p), q)
      || GraphTools.isTheSamePoint(p, q)
    )
    return candEdges[0] || null
  }

  public static computeVector(edge: RawEdge): Point {
    return {
      x: edge.target.x - edge.source.x,
      y: edge.target.y - edge.source.y
    }
  }

  public static dotProduct(v1: Point, v2: Point): number {
    return v1.x * v2.x + v1.y * v2.y
  }

  public static magnitude(v: Point): number {
    return Math.sqrt(v.x * v.x + v.y * v.y)
  }

  public static isInPath(path: Edge[], edge: Edge): boolean {
    return path.some((pathEdge) => GraphTools.isTheSameEdge(pathEdge, edge))
  }

  public static pointToString(point: Point): string {
    return `${point.x},${point.y}`
  }

  public static getBoundingBox(vertices: Vertex[], relativePadding = 0): { x: number, y: number, width: number, height: number } {
    let x = Math.min(...vertices.map(v => v.x))
    let y = Math.min(...vertices.map(v => v.y))
    let width = Math.max(...vertices.map(v => v.x)) - x
    let height = Math.max(...vertices.map(v => v.y)) - y

    x = x - width * relativePadding
    y = y - height * relativePadding
    width = width * (1 + relativePadding * 2)
    height = height * (1 + relativePadding * 2)

    return { x , y, width, height }
  }

  /**
   * Rate the connected edges by the least curvature
   * @param currentEdge
   * @param connectedEdges
   * @return {EdgeRating[]} Array of edges with their rating, the lower the rating the better
   */
  public static rateConnectedEdgeByLeastCurvature(
    currentEdge: Edge,
    connectedEdges: Edge[]
  ): EdgeRating[] {
    if (connectedEdges.length === 0) {
      throw new Error('No connected edges')
    }

    // remove the currentEdge from the connected edges
    connectedEdges = connectedEdges.filter((edge) => !GraphTools.isTheSameEdge(edge, currentEdge))

    // find the connection point (point which is present in both edges)
    const connectionPointCandidatesCurrentEdge = [currentEdge.source, currentEdge.target]
    const connectionPointCandidatesConnectedEdges = [
      connectedEdges[0].source,
      connectedEdges[0].target
    ]
    const commonItems = connectionPointCandidatesCurrentEdge.filter((item) =>
      connectionPointCandidatesConnectedEdges
        .map(GraphTools.pointToString)
        .includes(GraphTools.pointToString(item))
    )

    if (commonItems.length !== 1) {
      throw new Error('No connection point found')
    }
    const connectionPoint = commonItems[0]

    let initialEdge
    // Fix orientation of edges
    if (!GraphTools.isTheSamePoint(currentEdge.target, connectionPoint)) {
      initialEdge = {
        source: currentEdge.target,
        target: currentEdge.source
      }
    } else {
      initialEdge = currentEdge
    }

    const currentVector = GraphTools.computeVector(initialEdge)

    // Filter out the current edge from the connected edges
    connectedEdges = connectedEdges.filter((edge) => !GraphTools.isTheSameEdge(edge, currentEdge))

    return connectedEdges
      .map((edge) => {
        let connectedEdge
        if (!GraphTools.isTheSamePoint(edge.source, connectionPoint)) {
          connectedEdge = {
            source: edge.target,
            target: edge.source
          }
        } else {
          connectedEdge = edge
        }
        const connectedVector = GraphTools.computeVector(connectedEdge)

        const cosTheta =
          GraphTools.dotProduct(currentVector, connectedVector) /
          (GraphTools.magnitude(currentVector) * GraphTools.magnitude(connectedVector))
        const angle = Math.acos(cosTheta) * (180 / Math.PI)
        let rating: number

        rating = angle

        return {
          edge,
          rating
        }
      })
      .sort((a, b) => a.rating - b.rating)
  }
}
