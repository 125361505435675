import * as d3 from 'd3'
import { Delaunay } from 'd3-delaunay'
import type { Vertex, DelaunayPointIndex, Edge } from 'types'
import { EdgeHighlighterCurves } from './EdgeHighlighterCurves'
import type { MycelModel } from '@/lib/MycelModel'
import type { Point } from 'types'
import GraphTools from '@/lib/GraphTools'
import type { DeviceAbstract } from '@/lib/DeviceHandler/DeviceAbstract'

/**
 * This class is responsible for finding the timeline in the graph.
 */
export default class EdgeHighlighter {
  private height
  private width
  private mycelModel
  private strategy: EdgeHighlighterCurves
  private deviceHandler: DeviceAbstract

  constructor(
    mycelModel: MycelModel,
    width: number,
    height: number,
    strategy: EdgeHighlighterCurves,
    deviceHandler: DeviceAbstract
  ) {
    this.mycelModel = mycelModel
    this.width = width
    this.height = height
    this.strategy = strategy
    this.deviceHandler = deviceHandler
  }

  /**
   * The Delaunay function stores the points in a flat array. This function returns the point from the array
   * as Vertex object.
   * @param index
   */
  public getPointFromDelaunayIndex(index: DelaunayPointIndex) {
    return {
      x: this.mycelModel.delaunay!.points[index * 2],
      y: this.mycelModel.delaunay!.points[index * 2 + 1]
    } as Vertex
  }

  /**
   * Uses the submitted strategy (curve) to mark the timeline in the graph.
   * Later, years will be assigned to the timeline. @see MycelModel.assignYearsToTimeline
   */
  public findTimeline() {
    // Initialize the set of visited points
    const visited = new Set<DelaunayPointIndex>()

    const startPoint = this.strategy.getFirstPoint()

    let currentPointIx: DelaunayPointIndex = this.mycelModel.delaunay!.find(
      startPoint.x,
      startPoint.y
    )

    // eslint-disable-next-line no-constant-condition
    while (true) {
      visited.add(currentPointIx)
      const neighbors: IterableIterator<DelaunayPointIndex> =
        this.mycelModel.delaunay!.neighbors(currentPointIx)

      const unvisitedNeighborsIndices: DelaunayPointIndex[] = []
      for (const neighbor of neighbors) {
        const allowedDistanceNorth = 200
        if (
          this.getPointFromDelaunayIndex(currentPointIx).y + allowedDistanceNorth <=
          this.getPointFromDelaunayIndex(neighbor).y
        ) {
          // The line should always go down
          continue
        }

        if (this.pointIsAtBorder(this.getPointFromDelaunayIndex(neighbor))) {
          // We don't want to go outside the graph
          continue
        }

        if (!visited.has(neighbor)) {
          unvisitedNeighborsIndices.push(neighbor)
        }
      }

      // If no unvisited neighbors, break the loop
      if (
        unvisitedNeighborsIndices.length === 0 ||
        (this.pointIsAtBorder(this.getPointFromDelaunayIndex(currentPointIx)) && visited.size > 5)
      ) {
        break
      }

      const nextPointIx = unvisitedNeighborsIndices.reduce(
        (closestPointIx: DelaunayPointIndex, currentPointIx: DelaunayPointIndex) => {
          const currentPoint = this.getPointFromDelaunayIndex(currentPointIx)
          const closestPoint = this.getPointFromDelaunayIndex(closestPointIx)
          const closestDistance = this.strategy.distanceFromCurve(closestPoint)
          const currentDistance = this.strategy.distanceFromCurve(currentPoint)
          return currentDistance < closestDistance || isNaN(closestDistance)
            ? currentPointIx
            : closestPointIx
        }
      )

      if (!nextPointIx) {
        break
      }

      const currentPoint = this.getPointFromDelaunayIndex(currentPointIx)
      const nextPoint = this.getPointFromDelaunayIndex(nextPointIx)

      this.mycelModel.markAsTimeline(currentPoint, nextPoint, 'timeline', 'timeline-connector')
      currentPointIx = nextPointIx
    }
  }

  /**
   * Returns true if the point is at the border of the graph
   * @param {Point} point
   * @param {number} margin
   * @protected
   */
  protected pointIsAtBorder(point: Point): boolean {
    const marginVertical = this.deviceHandler.getConfig().offsetVerticalRelative * this.height
    const marginHorizontal = this.deviceHandler.getConfig().offsetHorizontalRelative * this.width
    return (
      point.x < marginHorizontal ||
      point.x > this.width - marginHorizontal ||
      point.y < marginVertical ||
      point.y > this.height - marginVertical
    )
  }
}
